/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { BackButton, Container, Main } from './styles';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

function RyderWidget() {
	const navigate = useNavigate();
	const location = useLocation();
	console.log('location: ', location);
  return (
		<>
			{location.pathname !== '/ryder-widget' && <BackButton onClick={() => navigate(-1)}>Back</BackButton>}
	    <Main>
		    <Container>
			    <Outlet />
		    </Container>
	    </Main>
		</>
  );
}

export default RyderWidget;
