import styled from "styled-components";
import { ListItem, ToggleButtonGroup, toggleButtonGroupClasses } from '@mui/material';
interface InputProps {
	error?: boolean;
}
export const Main = styled.main`
    width: 300px;
    max-height: 800px;
    min-height: 400px;
		height: auto;
    margin: auto;
    padding: 50px;
    font-family: "Roboto Condensed",Arial,sans-serif;
`;
export const Container = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background: rgb(245, 246, 246);
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    z-index: 1;
    min-height: calc(100% - 100px);
		overflow: auto;
`;
export const Header = styled.div`
    //background: #ffffff;
		display: flex;
		flex-direction: column;
		text-align: center;
		color: #76777b;
		gap: 10px;
		padding: 20px 0;
`;
export const InputField = styled.input<InputProps>`
    font-size: .875rem;
    width: 100%;
    min-width: 100%;
    padding: .625rem;
    border: ${(props) => `.0625rem solid ${props.error ? '#c4001a' : '#919296'}`};
    background: ${(props) => props.error ? '#fff7f8' : '#FFF'};
    box-sizing: border-box;
		cursor: pointer;
    font-family: "Roboto Condensed",Arial,sans-serif;
		&:focus {
        padding: .5625rem .625rem;
        background-color: #fafcff;
        border: .125rem solid #ce1126;
        outline: none;
		}
`;

export const ErrorMessage = styled.p`
    font-size: .875rem;
    color: #c4001a;
		margin: .2rem 0;
`;
export const BackButton = styled.button`
    border: none;
		background: none;
		cursor: pointer;
    font-size: 1rem;
		padding: 20px 0 20px 20px;
    font-weight: 700;
    font-family: "Roboto Condensed",Arial,sans-serif;
		&:hover {
        color: #c4001a;
		}
`;
export const SubmitButton = styled.button`
    border: .125rem solid #ce1126;
    background-color: #ce1126;
    color: #ffffff;
    min-height: 2.5rem;
		cursor: pointer;
    min-width: 2.5rem;
    padding: 0 1.4375rem;
    font-size: .875rem;
    font-weight: 700;
		margin-top: 1rem;
    border-radius: 8px;
    font-family: "Roboto Condensed",Arial,sans-serif;
		width: 100%;
		&:hover {
        background: #808285;
        border: .0625rem solid #808285;
        opacity: 1;
		}
`;
export const Label = styled.label`
    font-size: .875rem;
		margin-bottom: .2rem;
    display:inline-block;
`;

export const Wrapper = styled.div`
    padding: 10px
`;
export const Loading = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(128, 130, 133, 0.44);
		display: flex;
    align-items: center;
    justify-content: center;
`;


export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({

	[`& .${toggleButtonGroupClasses.grouped}`]: {
		'& h6': {
			fontSize: "12px",
			textTransform: 'capitalize',
			color: '#ce1126',
		},
		'&:hover': {
			'& h6': {
				color:  '#FFFFFF',
			},
			backgroundColor:  '#ce1126',
		},
		'&.Mui-selected': {
			'& h6': {
				color:  '#FFFFFF',
			},
			backgroundColor:  '#ce1126',
			border: `1px solid ${ '#ce1126'}`,
		},
		padding: '7px 10px',
		borderColor:  '#ce1126',
	},
}));

export const ListElement = styled(ListItem)(() => ({
	fontFamily: 'inherit',
	display: 'flex!important',
	padding: '10px 0px!important',
	justifyContent: 'space-between!important'
}))

export const ListLeftText = styled.div`
    font-size: 12px;
    line-height: 1;
    font-weight: 700;
`;
export const ListRightText = styled.div`
    font-size: 12px;
    line-height: 1;
    font-weight: 700;
    text-align: "right";
`;
export const AdditionalSmallItemIcon = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: flex;
		background-color: #ce1126;
    margin-right: 8px;
    padding: 5px ;
`;
