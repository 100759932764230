import styled from 'styled-components';

export const LoaderWrap = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(2px);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledIcon = styled.div`
  width: 90px;
  height: 90px;
  @keyframes spinning {
    25%   { transform: rotateZ(90deg); }
    50%   { transform: rotateZ(180deg); }
    75%   { transform: rotateZ(270deg); }
    100%  { transform: rotateZ(360deg); }
  }
  
  animation: spinning 1s infinite linear;
`;
export const Paragraph = styled.p`
  text-align: center;
  font-weight: 700;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #337289;
`;

