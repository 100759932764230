import React, { useContext } from 'react';

import { Group, Label, Select } from '../styles';
import { ConfigurationContext } from '../';

function RadioGroupControlConfig() {
  const {
    configuration,
    setConfiguration,
  }: any = useContext(ConfigurationContext);

  return (
    <>
      <h3>RadioGroupControl Config</h3>
      
      <Group>
        <Label>Values Orientation</Label>
        <Select
          name="orientation"
          id="orientation"
          value={configuration?.RadioGroupControl?.orientation}
          onChange={(e) => setConfiguration({...configuration, RadioGroupControl: { orientation: e.target.value}})}
        >
          <option value="column">column</option>
          <option value="row">row</option>
        </Select>
      </Group>
    </>
  );
}

export default RadioGroupControlConfig;
