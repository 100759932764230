import React, { useContext, useState } from 'react';

import { Group, Label, Input, Checkbox, SaveButton } from '../styles';
import { ConfigurationContext } from '../';

function ProgressBarConfig() {
  const {
    configuration,
    setConfiguration,
  }: any = useContext(ConfigurationContext);

  const [stepName, setStepName] = useState('');
  const [steps, setSteps] = useState('');

  const handleAddStep = () => {
    const barSteps = configuration?.progressBar?.barSteps || [];
    setConfiguration({
      ...configuration,
      progressBar: {
        ...configuration?.progressBar,
        barSteps: [
          ...barSteps,
          {
            title: stepName,
            steps: steps.split(', '),
          }
        ]
      }
    });
    setStepName('');
    setSteps('');
  };

  const handleDelete = (barStep: any) => {
    const barSteps = configuration?.progressBar?.barSteps;
    const result = barSteps.filter((step: any) => step.title !== barStep.title);
    setConfiguration({
      ...configuration,
      progressBar: {
        ...configuration?.progressBar,
        barSteps: result
      }
    });
  };

  return (
    <>
      <h3>Progress Bar</h3>
      <Group>
        <Checkbox>
          <Label htmlFor="progressBar">
            Visible
            <Input
              id='progressBar'
              name='progressBar'
              type="checkbox"
              checked={configuration?.progressBar?.visible}
              onChange={(e) => setConfiguration({...configuration, progressBar: {...configuration?.progressBar, visible: !configuration?.progressBar?.visible}})} />
          </Label>
        </Checkbox>
      </Group>
      
      <h4>Progress Bar Steps</h4>
      <Group>
        <Label>Progress Bar Step Name</Label>
        <Input
          id="progressBar_stepName"
          name="progressBar_stepName"
          value={stepName}
          onChange={(e) => setStepName(e.target.value)} />
      </Group>
      <Group>
        <Label>Widget stepCategory Name</Label>
        <Input
          id="progressBar_steps"
          name="progressBar_steps"
          value={steps}
          onChange={(e) => setSteps(e.target.value)} />
      </Group>
      <SaveButton onClick={handleAddStep}>Add Step</SaveButton>

      {configuration?.progressBar?.barSteps?.length ? configuration?.progressBar?.barSteps.map((item: any) => {
        return <p key={item.title}>{item.title}: {item.steps.join(', ')} <button onClick={() => handleDelete(item)}>delete</button></p>
      }) : null}
    </>
  );
}

export default ProgressBarConfig;
