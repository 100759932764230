import styled from "styled-components";

export const Picker = styled.div`
  padding: 5px;
  background: rgb(255, 255, 255);
  border-radius: 1px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px;
  display: inline-block;
  cursor: pointer;
`;

export const Swatch = styled.div<{background: string}>`
  width: 36px;
  height: 14px;
  border-radius: 2px;
  background: ${props => props.background};
`;

export const Popover = styled.div`
  position: absolute;
  z-index: 9999,
`;

export const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;
