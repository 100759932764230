import styled from "styled-components";

export const Wrapper = styled.div`
  background: rgba(0,0,0,0.7);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
export const Modal = styled.div`
  position: absolute;
  transform: translateX(-50%);
  width: 900px;
  max-width: 100%;
  top: 50px;
  left: 50%;
  background: #ffffff;
  padding: 20px;
  border-radius: 4px;
  max-height: calc(100% - 100px);
  overflow: scroll;
  box-sizing: border-box;
  h2 {
    margin: 0 0 20px;
  }
`;
export const ErrorText = styled.p`
  color: red;
`;
