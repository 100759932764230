import React, { useState } from 'react'
import {
	AdditionalSmallItemIcon,
	Header,
	ListElement,
	ListLeftText,
	ListRightText, Loading,
	StyledToggleButtonGroup, SubmitButton,
	Wrapper
} from './styles';
import CheckIcon from '@mui/icons-material/Check';
import { Box, CircularProgress, Grid, List, ToggleButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/ryder/logo-ryder.svg';

const plans = {
	'annual': {
		'coverageStartDate': '08/01/2024',
		'todayCost': '$10257',
		'liabilityAmount': '$100,000',
		'belongings': '$30,000',
		'placementCost': 'yes',
		'waterSewer': 'yes',
		'deductible': '$250',
	},
	'4pay': {
		'coverageStartDate': '08/01/2024',
		'todayCost': '$1257',
		'liabilityAmount': '$100,000',
		'belongings': '$24,000',
		'placementCost': 'yes',
		'waterSewer': 'yes',
		'deductible': '$250',
	},
	'monthly': {
		'coverageStartDate': '08/01/2024',
		'todayCost': '$257',
		'liabilityAmount': '$100,000',
		'belongings': '$12,000',
		'placementCost': 'yes',
		'waterSewer': 'no',
		'deductible': '$250',
	}
}
const SecondStep = () => {
	const navigate = useNavigate()
	const [valuePlans, setValuePlans] = useState<keyof typeof plans>('annual');
	const [isLoading, setIsLoading] = useState(false);
	const onSubmit = () => {
		setIsLoading(true);
		setTimeout(() => {
			setIsLoading(false);
			navigate('/ryder-widget/step3')
		}, 2500)
	};
	return (
		<div style={{ position: 'relative', height: '100%' }}>
			{isLoading && <Loading><CircularProgress sx={{color: '#ce1126'}}/></Loading>}
			<Header>
				<div><img src={logo} alt="Ryder Logo" width={150}/></div>
				Your Commercial Auto coverage
			</Header>
			<Wrapper>
				<h4 style={{ margin: '10px 0' }}>Summary</h4>
				<Grid container>
					<Grid item md={12} sm={12} xs={12}>
						<List>
							<ListElement>
								<ListLeftText>Coverage Start Date</ListLeftText>
								<ListRightText>
									{plans[valuePlans].coverageStartDate}
								</ListRightText>
							</ListElement>
							<ListElement>
								<Box display="flex" flexDirection="column">
									<ListLeftText>Today's cost</ListLeftText>
									<ListLeftText style={{ fontStyle: "italic", fontWeight: 400 }}>Downpayment</ListLeftText>
								</Box>
								<ListRightText>
									{plans[valuePlans].todayCost}
								</ListRightText>
							</ListElement>
							<ListElement>
								<ListLeftText>Liability Amount</ListLeftText>
								<ListRightText>
									{plans[valuePlans].liabilityAmount}
								</ListRightText>
							</ListElement>
							<ListElement>
								<ListLeftText>Your Belongings</ListLeftText>
								<ListRightText>
									{plans[valuePlans].belongings}
								</ListRightText>
							</ListElement>
							<ListElement>
								<Box>
									<ListLeftText>Placement Cost</ListLeftText>
								</Box>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<AdditionalSmallItemIcon>
										<CheckIcon sx={{ width: '10px', height: '10px', color: '#FFFFFF' }}/>
									</AdditionalSmallItemIcon>
									<ListRightText>Included</ListRightText>
								</div>
							</ListElement>
							<ListElement>
								<ListLeftText>Water Sewer Backup</ListLeftText>
								<ListRightText>
									{plans[valuePlans].waterSewer === 'yes' ? 'Added' : 'Not Added'}
								</ListRightText>
							</ListElement>
							<ListElement>
								<ListLeftText>Deductible</ListLeftText>
								<ListRightText>
									{plans[valuePlans].deductible}
								</ListRightText>
							</ListElement>
						</List>
					</Grid>
					<Grid item md={12} sm={12} xs={12} mt={2} mb={2}>
						<Box display="flex" justifyContent="space-between" alignItems="center">
							<Box sx={{ flex: '0 0 65%' }}>
								<StyledToggleButtonGroup
									value={valuePlans}
									size="small"
									aria-label="text alignment"
								>
									<ToggleButton
										sx={{ color: '#fff' }}
										value={'annual'}
										aria-label='annual'
										onClick={() => setValuePlans('annual')}
									>
										<Typography variant={"subtitle1"} fontFamily="Roboto Condensed">Annual</Typography>
									</ToggleButton>
									<ToggleButton
										sx={{ color: '#fff' }}
										value={'4pay'}
										aria-label='4pay'
										onClick={() => setValuePlans('4pay')}
									>
										<Typography variant={"subtitle1"} fontFamily="Roboto Condensed">4-pay</Typography>
									</ToggleButton>
									<ToggleButton
										sx={{ color: '#fff' }}
										value={'monthly'}
										aria-label='monthly'
										onClick={() => setValuePlans('monthly')}
									>
										<Typography variant={"subtitle1"} fontFamily="Roboto Condensed">Monthly</Typography>
									</ToggleButton>
								</StyledToggleButtonGroup>
							</Box>
							<Box sx={{
								flex: '0 0 35%',
								textAlign: 'center'
							}}>
								<Typography variant="h4" color={"secondary.main"} sx={{
									fontFamily:"Roboto Condensed",
									color: "inherit",
									fontSize: '12px',
									lineHeight: '12px',
									fontWeight: 'normal',
									textTransform: 'capitalize',
									mb: 1,
								}} textTransform="uppercase">Today's cost</Typography>
								<Typography color={"secondary.main"} variant={"h4"} sx={{
									fontFamily:"Roboto Condensed",
									textAlign: 'center',
									color: "inherit",
									fontSize: '18px',
									lineHeight: '18px',
									textTransform: 'uppercase',
									mb: 0,
								}}>{plans[valuePlans].todayCost}</Typography>
							</Box>
						</Box>
					</Grid>
					<Grid item md={12} sm={12} xs={12} mb={2}>
						<SubmitButton type="button" onClick={onSubmit}>Continue</SubmitButton>
					</Grid>
				</Grid>
			</Wrapper>
		</div>
	)
}

export default SecondStep
