export const weatherClientData = {
	"productId": "3f7db58f-3b8a-429a-97c9-f636a1f952b5",
	"resultRuleNames": {
		"UserQualified": "Qualified",
		"UserNotQualified": "Not Qualified",
		"NoMatchingData": "No Matching Data"
	},
	"FirstStage": {
		"stepCategories": {
			"step1": [
				"QuoteInfo",
				"Person",
				"ContactInformation",
				"Underwriting"
			]
		}
	},
	"ConfirmationStage": {
		"stepCategories": {
			"step2": [
				"Underwriting"
			]
		}
	},
	"isMicroSite": false,
	"fullSize": false,
	"heroImage": {
		"position": "contain",
		"src": "https://devixfileupload.blob.core.windows.net/ixplatform-public/Websites/images/assurant_brick_wall_flower.png"
	},
	"emailField": true,
	"styles": {
		"components": {
			"input": {
				"fontSize": "",
				"borderRadius": "5px"
			},
			"progressBar": {
				"bar": {
					"background": "#08AEEE"
				}
			},
			"headings": {
				"color": "#00b034"
			},
			"button": {
				"borderRadius": "",
				"background": "#009BFF"
			}
		},
		"colors": {
			"primary": "#bbc9cd",
			"secondary": "#5e6b61"
		}
	},
	"layout": {
		"columns": 2
	},
	"ResultStage": {
		"styles": {
			"components": {}
		}
	},
	"customControls": [
		{
			"attributeName": "PropertyType",
			"renderer": "RadioIconsControl"
		},
		{
			"attributeName": "OwnAnimalInThePropertyHarmedSomeone",
			"renderer": "RadioGroupControl"
		},
		{
			"attributeName": "PriorLossExcludingNaturalDisaster",
			"renderer": "RadioGroupControl"
		},
		{
			"attributeName": "IsRentersInsuranceRequired",
			"renderer": "RadioGroupControl"
		},
		{
			"attributeName": "ManufacturedOrMobileHome",
			"renderer": "RadioGroupControl"
		},
		{
			"attributeName": "MasonryOrWood",
			"renderer": "RadioGroupControl"
		},
		{
			"attributeName": "FullAddress",
			"renderer": "AutocompleteControl"
		},
		{
			"attributeName": "ArsonConviction",
			"renderer": "BooleanControl"
		},
		{
			"attributeName": "PriorLossExcludingNaturalDisasterMI",
			"renderer": "RadioGroupControl"
		},
		{
			"attributeName": "HaveYouHadAnyLossesFromTheftOrBurglaryOrVandalismOrFireOrCausedDamageToAPropertyInThePastThreeYears",
			"renderer": "RadioGroupControl"
		},
		{
			"attributeName": "ExcludingStormsFloodsOrOtherNaturalDisastersHaveYouHadMoreThanThreeNonweatherLossesPastThreeYears",
			"renderer": "RadioGroupControl"
		}
	],
	"RadioGroupControl": {
		"orientation": "row"
	},
	"step1": {
		"labelPlacement": "inside",
		"uiSchema": {
			"type": "VerticalLayout",
			"elements": [
				{
					"type": "HorizontalLayout",
					"elements": [
						{
							"type": "Control",
							"scope": "#/properties/QuoteInfo/properties/AssetAddress/properties/FullAddress",
							"options": {
								"placeholder": "Address"
							},
							"labelPosition": "inside"
						}
					]
				},
				{
					"type": "HorizontalLayout",
					"elements": [
						{
							"type": "Control",
							"scope": "#/properties/Person/properties/FullName",
							"options": {
								"placeholder": "Full Name"
							},
							"labelPosition": "inside"
						},
						{
							"type": "Control",
							"scope": "#/properties/Person/properties/DateOfBirth",
							"options": {
								"placeholder": "Date Of Birth"
							},
							"labelPosition": "inside"
						}
					]
				},
				{
					"type": "HorizontalLayout",
					"elements": [
						{
							"type": "Control",
							"scope": "#/properties/ContactInformation/properties/Email",
							"options": {
								"placeholder": "Email"
							},
							"labelPosition": "inside"
						},
						{
							"type": "Control",
							"scope": "#/properties/QuoteInfo/properties/CoverageStartDate",
							"options": {
								"placeholder": "Coverage Start Date"
							},
							"labelPosition": "inside"
						}
					]
				},
				{
					"type": "HorizontalLayout",
					"elements": [
						{
							"type": "Control",
							"scope": "#/properties/Underwriting/properties/PropertyUnderwriting/properties/PropertyType",
							"options": {
								"placeholder": "Property Type"
							},
							"labelPosition": "inside"
						}
					]
				}
			]
		},
		"logo": {
			"src": "https://www.assurantrenters.com/assets/images/assurant-logo.png",
			"width": "auto",
			"height": "auto",
			"visible": true,
			"position": "center"
		},
		"title": {
			"content": "Let's get to know you better",
			"visible": true
		},
		"button": {
			"borderRadius": "",
			"content": "Continue"
		},
		"heroImage": {
			"position": "contain",
			"src": "https://devixfileupload.blob.core.windows.net/ixplatform-public/Websites/images/assurant_brick_wall_flower.png"
		}
	},
	"RadioIconsControl": {
		"valuesProps": {
			"Apartment/Condo": {
				"icon": "https://nice-sand-0458dee0f.2.azurestaticapps.net/AssurantIcons/CondoBox.svg",
				"activeIcon": "https://nice-sand-0458dee0f.2.azurestaticapps.net/AssurantIcons/CondoBoxActive.svg"
			},
			"Single-Family Home done": {
				"icon": "https://nice-sand-0458dee0f.2.azurestaticapps.net/AssurantIcons/HomeBox.svg",
				"activeIcon": "https://nice-sand-0458dee0f.2.azurestaticapps.net/AssurantIcons/HomeBoxActive.svg"
			},
			"Townhouse": {
				"icon": "https://nice-sand-0458dee0f.2.azurestaticapps.net/AssurantIcons/TownhouseBox.svg",
				"activeIcon": "https://nice-sand-0458dee0f.2.azurestaticapps.net/AssurantIcons/TownhouseBoxActive.svg"
			},
			"Student Housing": {
				"icon": "https://nice-sand-0458dee0f.2.azurestaticapps.net/AssurantIcons/StudentBox.svg",
				"activeIcon": "https://nice-sand-0458dee0f.2.azurestaticapps.net/AssurantIcons/StudentBoxActive.svg"
			}
		}
	},
	"BooleanIconControl": {
		"valuesProps": {}
	},
	"CtaStage": {
		"styles": {
			"components": {},
			"colors": {}
		},
		"title": {
			"content": "Let's get to know you better",
			"visible": true
		},
		"description": {
			"top": {
				"styles": {
					"textAlign": "center"
				},
				"content": "    "
			}
		}
	},
	"CTA": false,
	"modal": false,
	"allowSetDataIfUnableToVerify": false,
	"step2": {
		"title": {
			"content": "Let's get to know you better",
			"visible": true,
			"position": "left",
			"backButton": {
				"src": "",
				"width": "",
				"height": "",
				"visible": false
			}
		},
		"button": {
			"url": "https://calm-tree-0f67cc10f.4.azurestaticapps.net/",
			"redirectUrl": "https://calm-tree-0f67cc10f.4.azurestaticapps.net/"
		},
		"logo": {
			"src": "https://www.assurantrenters.com/assets/images/assurant-logo.png",
			"width": "auto",
			"height": "auto",
			"visible": false,
			"position": "center",
			"backButton": {
				"src": "https://devixfileupload.blob.core.windows.net/ixplatform-public/Websites/images/back_button.png",
				"width": "35px",
				"height": ""
			}
		},
		"heroImage": {
			"src": "https://devixfileupload.blob.core.windows.net/ixplatform-public/Websites/images/assurant_brick_wall_flower.png",
			"position": "contain"
		},
		"styles": {
			"components": {
				"input": {
					"background": "#BBC9CD",
					"color": "#432828"
				}
			}
		}
	},
	"progressBar": {
		"visible": true,
		"barSteps": [
			{
				"title": "Questions",
				"steps": [
					"step1",
					"step2"
				]
			},
			{
				"title": "Quote",
				"steps": [
					""
				]
			},
			{
				"title": "Checkout",
				"steps": [
					""
				]
			}
		]
	},
	"logo": {
		"src": "https://www.assurantrenters.com/assets/images/assurant-logo.png",
		"visible": true,
		"width": "auto",
		"height": "auto",
		"position": "center"
	},
	"smallSize": false,
	"width": "",
	"height": "",
	"micrositeRedirect": true,
	"clientId": "1e03d35f-4e7f-4fd8-8faf-d07bbe727f88"
}
