import React, { useContext } from 'react';
import { CopyBlock, dracula } from "react-code-blocks";

import { ConfigurationContext } from '..';
import { WIDGET_PATH } from "../../../config";
import EditModal from "./EditModal";
import { EditButton } from "./styles";

function CodeSnippet() {
  const { configuration }: any = useContext(ConfigurationContext);

  const handleCopyBlockObject = (obj: any) => {
    return JSON.stringify(obj, null, 6);
  }

  const [open, setOpen] = React.useState(false);

  return (
    <div>
      <h2>Widget Code Snippet</h2>

      <h3>Widget library link</h3>
      <p>{'To insert this widget on your site, place the <script> tag for referencing IX-Widget JavaScript bundle.'}</p>
      <CopyBlock
        text={` <script type="text/javascript" src="${WIDGET_PATH}"></script>
        `}
        language={'javascript'}
        theme={dracula}
      />

      <h3>Widget placement</h3>
      <p>{'Decide where you want to place the widget, and then put there <div> with unique id, like this:'}</p>
      <CopyBlock
        text={` <div id="ix-platform-widget"></div>
        `}
        language={'javascript'}
        theme={dracula}
      />

      <h3>Widget configuration</h3>
      <p>{'Place a <script> tag with a widget initialization before the closing </body>.'}</p>
      <EditButton onClick={() => setOpen(true)}>Edit</EditButton>
      <CopyBlock
        text={`<script type="text/javascript">
  !function(){
    if (window.IXWidget) {
      window.IXWidget('ix-platform-widget', ${handleCopyBlockObject(configuration)});
    }
  }();
</script>
`}
        language={'javascript'}
        theme={dracula}
      />
      {open && <EditModal config_data={configuration} handleClose={() => setOpen(false)} />}
    </div>
  );
}

export default CodeSnippet;
