import styled from "styled-components";

export const Wrapper = styled.div`
    background: rgba(20, 20, 20, 0.25);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    font-family: 'Arial';
`;
export const Container = styled.div`
    background: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid rgb(218, 218, 227);
    box-shadow: rgb(56 56 116 / 25%) 0px 0px 10px;
    border-radius: 12px;
    width: 740px;
    min-height: 370px;
    padding: 16px;
    box-sizing: border-box;
`;
export const SubmitButton = styled.button`
    background: rgb(6, 116, 140);
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 1px;
    color: rgb(255, 255, 255);
    padding: 12px 20px;
    border: none;
    cursor: pointer;
    transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    margin: auto;
    border-radius: 6px;
    display: flex;
    height: 48px;
    padding: 0 20px;
    align-items: center;
    img {
        margin-left: 10px;
    }
    &:hover {
        opacity: 0.7;
    }
`;
export const EditButton = styled.button`
    padding: 12px 20px;
    border-radius: 0px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 1px;
    order: 0;
    border: none;
    cursor: pointer;
    transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background: transparent;
    color: rgb(6, 116, 140);
    &:hover {
        opacity: 0.7;
    }
`;
export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    position: relative;
`;
export const Steps = styled.span`
    font-weight: 700;
    letter-spacing: 1px;
    color: rgb(6, 116, 140);
    font-size: 12px;
    line-height: 16px;
    width: 60px;
    text-align: right;
`;
export const Title = styled.h2`
    color: rgb(52, 55, 65);
    font-style: normal;
    font-weight: 700;
    margin: 0px;
    text-align: left;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin 16px 0;
`;
export const Block = styled.div`
    padding: 16px;
    border: 1px solid rgb(218, 218, 227);
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: end;
    &>div {
        width: 100%;
    }
`;
export const Logo = styled.img`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
`;
export const Car = styled.p`
    font-size: 15px;
    line-height: 24px;
    color: rgb(43, 44, 65);
    display: flex;
    margin: 0;
    img {
        margin-right: 16px;
    }
`;
export const BackButton = styled.button`
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;
`;
export const Table = styled.div`
    border: 1px solid rgb(218, 218, 227);
    border-radius: 10px;
    margin-bottom: 26px;
`;
export const Thead = styled.div`
    border-bottom: 1px solid rgb(218, 218, 227);
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
`;
export const Tbody = styled.div`
    padding: 0 20px;
`;
export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgb(218, 218, 227);
    padding: 10px 0;
    &:last-child {
        border: none;
    }
`;
export const Price = styled.p`
    font-size: 12px;
    line-height: 16px;
    color: rgb(150, 150, 169);
    font-style: normal;
    font-weight: 700;
    margin: 0px;
`;
export const Quote = styled.p`
    color: rgb(6, 116, 140);
    font-style: normal;
    font-weight: 700;
    margin: 0px;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: center;
    span {
        font-size: 14px;
        line-height: 20px;
        color: rgb(56, 56, 116);
        font-style: normal;
        font-weight: 400;
    }
`;
export const Paragraph = styled.p`
    color: rgb(52, 55, 65);
    font-style: normal;
    font-weight: 400;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
`;
export const Col = styled.p`
    font-size: 12px;
    line-height: 16px;
    color: rgb(43, 44, 65);
    font-weight: 400;
    margin: 0px;
`;
export const Input = styled.input`
    background: rgb(255, 255, 255);
    border-radius: 0px;
    color: rgb(52, 55, 65);
    min-height: 42px;
    border: 1px solid rgb(185, 185, 201);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    padding: 0px 14px;
    box-sizing: border-box;
    outline: none;
    appearance: none;
`;
export const DeclineButton = styled.button`
    padding: 12px 20px;
    background: #F3F3F3;
    border-radius: 6px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    color: rgb(6, 116, 140);
    border: none;
    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
`;
export const ButtonsBlock = styled.div`
    display: flex;
    justify-content: center;
    button {
        margin: 0 10px;
    }
`;
export const Label = styled.label`
    color: rgb(1, 1, 1);
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    display: block;
    margin-bottom: 5px;
`;
