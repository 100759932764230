import React, { useContext } from 'react';

import { Group, Label, Input, Select, Checkbox } from '../styles';
import { ConfigurationContext } from '../';
import ProgressBarConfig from './ProgressBarConfig';
import RadioIconsControlConfig from './RadioIconsControlConfig';
import BooleanIconControlConfig from './BooleanIconControlConfig';
import RadioGroupControlConfig from './RadioGroupControlConfig';
import ErrorsConfig from './ErrorsFieldsConfig/ErrorsConfig';

function ComponentsConfiguration() {
  const {
    configuration,
    setConfiguration,
  }: any = useContext(ConfigurationContext);

  return (
    <>
      <Group>
        <Label>Widget width</Label>
        <Input
          id='width'
          name='width'
          type="text"
          value={configuration?.width || ''}
          onChange={(e) => setConfiguration({...configuration, width: e.target.value})} />
      </Group>
      <Group>
        <Label>Widget height</Label>
        <Input
          id='height'
          name='height'
          type="text"
          value={configuration?.height || ''}
          onChange={(e) => setConfiguration({...configuration, height: e.target.value})} />
      </Group>
      <Group>
        <Label>Widget Theme</Label>
        <Select
          name="theme"
          id="theme"
          value={configuration?.theme}
          onChange={(e) => setConfiguration({...configuration, theme: e.target.value})}
        >
          <option value="default">default</option>
          <option value="straight">straight</option>
          <option value="material">material</option>
        </Select>
      </Group>
      <Group>
        <Label>Label Placement</Label>
        <Select
          name="labelPlacement"
          id="labelPlacement"
          value={configuration?.labelPlacement}
          onChange={(e) => setConfiguration({...configuration, labelPlacement: e.target.value})}
        >
          <option value="top">top</option>
          <option value="inside">inside</option>
        </Select>
      </Group>
      <Group>
        <Label>Layout</Label>
        <Input
          id='layout'
          name='layout'
          type="number"
          max="3"
          min="1"
          value={configuration?.layout?.columns}
          onChange={(e) => setConfiguration({...configuration, layout: {...configuration?.layout, columns: e.target.value}})} />
      </Group>
      <Group>
        <Checkbox>
          <Label htmlFor="cta">
            CTA
            <Input
              id='cta'
              name='cta'
              type="checkbox"
              checked={configuration?.CTA}
              onChange={(e) => setConfiguration({...configuration, CTA: !configuration?.CTA})} />
          </Label>
        </Checkbox>
      </Group>
      <Group>
        <Checkbox>
          <Label htmlFor="modal">
            Modal
            <Input
              id='modal'
              name='modal'
              type="checkbox"
              checked={configuration?.modal}
              onChange={(e) => setConfiguration({...configuration, modal: !configuration?.modal})} />
          </Label>
        </Checkbox>
      </Group>
      <Group>
        <Checkbox>
          <Label htmlFor="isMicroSite">
            Microsite
            <Input
              id='isMicroSite'
              name='isMicroSite'
              type="checkbox"
              checked={configuration?.isMicroSite}
              onChange={(e) => setConfiguration({...configuration, isMicroSite: !configuration?.isMicroSite})} />
          </Label>
        </Checkbox>
      </Group>
      <Group>
        <Checkbox>
          <Label htmlFor="fullSize">
            FullSize
            <Input
              id='fullSize'
              name='fullSize'
              type="checkbox"
              checked={configuration?.fullSize}
              onChange={(e) => setConfiguration({...configuration, fullSize: !configuration?.fullSize})} />
          </Label>
        </Checkbox>
      </Group>
      <Group>
        <Checkbox>
          <Label htmlFor="smallSize">
            Small Size
            <Input
              id='smallSize'
              name='smallSize'
              type="checkbox"
              checked={configuration?.smallSize}
              onChange={(e) => setConfiguration({...configuration, smallSize: !configuration?.smallSize})} />
          </Label>
        </Checkbox>
      </Group>
      <Group>
        <Checkbox>
          <Label htmlFor="micrositeRedirect">
          micrositeRedirect
            <Input
              id='micrositeRedirect'
              name='micrositeRedirect'
              type="checkbox"
              checked={configuration?.micrositeRedirect}
              onChange={(e) => setConfiguration({...configuration, micrositeRedirect: !configuration?.micrositeRedirect})} />
          </Label>
        </Checkbox>
      </Group>
      <h3>Logo</h3>
      <Group>
        <Label>Logo src</Label>
        <Input
          name="logo"
          id="logo"
          type="text"
          value={configuration?.logo?.src || ''}
          onChange={(e) => setConfiguration({...configuration, logo: {...configuration?.logo, src: e.target.value}})} />

          <Checkbox>
            <Label htmlFor="logo_visible">
              <Input
                id="logo_visible"
                name="logo_visible"
                type="checkbox"
                checked={configuration?.logo?.visible}
                onChange={(e) => setConfiguration({...configuration, logo: {...configuration?.logo, visible: !configuration?.logo?.visible}})} />
              Logo Visible
            </Label>
          </Checkbox>
      </Group>
      <Group>
        <Label>Logo width</Label>
        <Input
          name="logo_width"
          id="logo_width"
          value={configuration?.logo?.width || ''}
          onChange={(e) => setConfiguration({...configuration, logo: {...configuration?.logo, width: e.target.value}})} />
      </Group>
      <Group>
        <Label>Logo height</Label>
        <Input
          name="logo_height"
          id="logo_height"
          type="text"
          value={configuration?.logo?.height || ''}
          onChange={(e) => setConfiguration({...configuration, logo: {...configuration?.logo, height: e.target.value}})} />
      </Group>
      <Group>
        <Label>Position</Label>
        <Select
          name="logo_position"
          id="logo_position"
          value={configuration?.logo?.position}
          onChange={(e) => setConfiguration({...configuration, logo: {...configuration?.logo, position: e.target.value}})}
        >
          <option value="">None</option>
          <option value="left">left</option>
          <option value="right">right</option>
          <option value="center">center</option>
        </Select>
      </Group>

      <h3>Hero Image</h3>
      <Group>
        <Label>Position</Label>
        <Select
          name="heroImage_position"
          id="heroImage_position"
          value={configuration?.heroImage?.position}
          onChange={(e) => setConfiguration({...configuration, heroImage: {...configuration?.heroImage, position: e.target.value}})}
        >
          <option value="">None</option>
          <option value="top">top</option>
          <option value="bottom">bottom</option>
          <option value="left">left</option>
          <option value="right">right</option>
          <option value="contain">contain</option>
        </Select>
      </Group>
      <Group>
        <Label>heroImage src</Label>
        <Input
          name="heroImage_src"
          id="heroImage_src"
          type="text"
          value={configuration?.heroImage?.src || ''}
          onChange={(e) => setConfiguration({...configuration, heroImage: {...configuration?.heroImage, src: e.target.value}})} />
      </Group>
      <Group>
        <Label>Width (the position of the heroImage is left or right)</Label>
        <Input
          name="heroImage_width"
          id="heroImage_width"
          value={configuration?.heroImage?.width || ''}
          onChange={(e) => setConfiguration({...configuration, heroImage: {...configuration?.heroImage, width: e.target.value}})} />
      </Group>
      <Group>
        <Label>Height (the position of the heroImage is top or bottom)</Label>
        <Input
          name="heroImage_height"
          id="heroImage_height"
          type="text"
          value={configuration?.heroImage?.height || ''}
          onChange={(e) => setConfiguration({...configuration, heroImage: {...configuration?.heroImage, height: e.target.value}})} />
      </Group>

      <h3>Error Page</h3>
      <Group>
        <Label>Icon src</Label>
        <Input
          name="errorPage_icon"
          id="errorPage_icon"
          type="text"
          value={configuration?.ErrorPage?.icon || ''}
          onChange={(e) => setConfiguration({...configuration, ErrorPage: {...configuration?.ErrorPage, icon: e.target.value}})} />
      </Group>
      <Group>
        <Label>Title</Label>
        <Input
          name="errorPage_title"
          id="errorPage_title"
          type="text"
          value={configuration?.ErrorPage?.title || ''}
          onChange={(e) => setConfiguration({...configuration, ErrorPage: {...configuration?.ErrorPage, title: e.target.value}})} />
      </Group>
      <Group>
        <Label>Description</Label>
        <Input
          name="errorPage_description"
          id="errorPage_description"
          type="text"
          value={configuration?.ErrorPage?.description || ''}
          onChange={(e) => setConfiguration({...configuration, ErrorPage: {...configuration?.ErrorPage, description: e.target.value}})} />
      </Group>
      <Group>
        <Label>Button Text</Label>
        <Input
          name="errorPage_buttonText"
          id="errorPage_buttonText"
          type="text"
          value={configuration?.ErrorPage?.buttonText || ''}
          onChange={(e) => setConfiguration({...configuration, ErrorPage: {...configuration?.ErrorPage, buttonText: e.target.value}})} />
      </Group>

      <h3>Success Page</h3>
      <Group>
        <Label>Title content</Label>
        <Input
          name="successPage_title_content"
          id="successPage_title_content"
          type="text"
          value={configuration?.SuccessPage?.title?.content || ''}
          onChange={(e) => setConfiguration({
            ...configuration, 
            SuccessPage: {...configuration?.SuccessPage, title: {...configuration?.SuccessPage?.title, content: e.target.value}}
          })} />
      </Group>
      <Group>
        <Label>Title Position</Label>
        <Select
          name="successPage_title_position"
          id="successPage_title_position"
          value={configuration?.SuccessPage?.title?.position}
          onChange={(e) => setConfiguration({
            ...configuration,
            SuccessPage: {
              ...configuration?.SuccessPage, title: {...configuration?.SuccessPage?.title, position: e.target.value}
            }
          })}
        >
          <option value="">None</option>
          <option value="left">left</option>
          <option value="right">right</option>
          <option value="center">center</option>
        </Select>
      </Group>
      <Group>
        <Label>Description</Label>
        <Input
          name="successPage_description"
          id="successPage_description"
          type="text"
          value={configuration?.SuccessPage?.description || ''}
          onChange={(e) => setConfiguration({...configuration, SuccessPage: {...configuration?.SuccessPage, description: e.target.value}})} />
      </Group>

      <h3>Loader</h3>
      <Group>
        <Checkbox>
          <Label htmlFor="loader_fullscreen">
            Fullscreen
            <Input
              id='loader_fullscreen'
              name='loader_fullscreen'
              type="checkbox"
              checked={configuration?.loader?.fullscreen}
              onChange={(e) => setConfiguration({...configuration, loader: {...configuration?.loader, fullscreen: !configuration?.loader?.fullscreen}})} />
          </Label>
        </Checkbox>
      </Group>
      <Group>
        <Checkbox>
          <Label htmlFor="loader_blurred">
            Blurred
            <Input
              id='loader_blurred'
              name='loader_blurred'
              type="checkbox"
              checked={configuration?.loader?.blurred}
              onChange={(e) => setConfiguration({...configuration, loader: {...configuration?.loader, blurred: !configuration?.loader?.blurred}})} />
          </Label>
        </Checkbox>
      </Group>
      <Group>
        <Label>Color</Label>
        <Input
          name="loader_color"
          id="loader_color"
          type="text"
          value={configuration?.loader?.color || ''}
          onChange={(e) => setConfiguration({...configuration, loader: {...configuration?.loader, color: e.target.value}})} />
      </Group>
      <h3>Steps</h3>
      <Group>
        <Checkbox>
          <Label htmlFor="steps">
            Visible
            <Input
              id='steps'
              name='steps'
              type="checkbox"
              checked={configuration?.steps?.visible}
              onChange={(e) => setConfiguration({...configuration, steps: {visible: !configuration?.steps?.visible}})} />
          </Label>
        </Checkbox>
      </Group>
      <ProgressBarConfig />
      <RadioIconsControlConfig />
      <BooleanIconControlConfig />
      <RadioGroupControlConfig />
      <ErrorsConfig />
    </>
  );
}

export default ComponentsConfiguration;
