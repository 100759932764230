import React from 'react'
import {Divider} from "./styles";
import {
  renderBoolean,
  renderButtonConfig,
  renderHeroImg,
  renderLogo,
  renderStyles,
  renderTemplate,
  renderTemplateAttributes,
  renderTextConfig,
  renderFooterConfig,
  RenderStepCategories,
  renderDescription,
  renderAttributes,
} from './renderUtils';
import ResultStages from "./ResultStages";

const StagesConfiguration = ({item}: any) => {
  return (
    <div>
      <ResultStages item={item}/>
      {item.props.map((component: string) => {
        let el;
        switch (component) {
          case 'button':
            el = renderButtonConfig(item.value, item.handleChange, item.id, component);
            break;
          case 'styles':
            el = renderStyles(item.value.styles?.colors, item.value.styles?.components, item.setColors, item.setComponents, item.id)
            break;
          case 'logo':
            el = renderLogo(item.value, item.handleChange, item.id, component);
            break;
          case 'heroImage':
            el = renderHeroImg(item.value, item.handleChange, item.id, component);
            break;
          case 'showIcon':
          case 'border':
            el = renderBoolean(item.value, item.handleChange, item.id, component);
            break;
          case 'template':
            el = renderTemplate(item.value, item.handleChange, item.id, component);
            break;
          case 'templateAttributes':
            el = renderTemplateAttributes(item.value, item.handleChange, item.id, component);
            break;
          case 'footer':
            el = renderFooterConfig(item.value, item.handleChange, item.id, component);
            break;
          case 'stepCategories':
            el = RenderStepCategories(item.value, item.handleChange, item.id, component);
            break;
          case 'description':
            el = renderDescription(item.value, item.handleChange, item.id, component);
            break;
          case 'attributes':
            el = renderAttributes(item.value, item.handleChange, item.id, component);
            break;
          default:
            el = renderTextConfig(item.value, item.handleChange, item.id, component);
            break;
        }
        return el;
      })}
      <Divider/>
    </div>
  )
}

export default StagesConfiguration
