/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react';
import { Container } from './styles';
import { CLIENT_ID, PRODUCT_ID_CONFIANZA } from '../../config';

function SmallSizeWidget() {
  useEffect(() => {
    const clientId = CLIENT_ID;
    const productId = PRODUCT_ID_CONFIANZA;

    window.IXWidget('ix-smallSize-widget', {
      clientId,
      productId,
      labelPlacement: 'inside',
      heroImage: {
        position: 'contain',
      },
      firstStage: {
        title: {
          content: 'Lock in your quote in a second'
        },
        description: {
          visible: false
        },
      },
      smallSize: true,
    });
  }, []);

  return (
    <Container>
      <div id="ix-smallSize-widget"></div>
    </Container>
  );
}

export default SmallSizeWidget;
