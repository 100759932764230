import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
`;

export const SubTitle = styled.div`
  padding: 10px;
  background: rgba(237, 29, 100, 0.22);
  border-radius: 4px;
  margin: 10px auto 0 auto;
  font-size: 20px;
`;

export const Divider = styled.hr`
  border: 4px solid #2196f338;
  margin: 30px 0 50px 0;
`;
export const Title = styled.h4`
  background: linear-gradient(to right, #2196f338 0%,transparent 100%);
  padding: 5px;
  line-height: 1;
`;

export const MainTitle = styled.h4`
  background: linear-gradient(to right, rgba(243, 33, 120, 0.22) 0%, transparent 100%);
  padding: 5px;
  line-height: 1;
  font-size: 18px;
`;
