import React, {useState} from 'react'
import {Group, Input, Label, RemoveButton, SaveButton, Select} from "../styles";
import StylesConfiguration from "../StylesConfiguration";

const RESULT_STAGES_COLORS: Record<string, string> = {
  UserQualified: 'rgba(46,255,105,0.15)',
  UserNotQualified: 'rgba(255,160,28,0.15)',
  NoMatchingData: 'rgba(255,30,41,0.15)'
}
const RESULT_STAGES = ['UserQualified', 'UserNotQualified', 'NoMatchingData']
const ResultStages = ({item}: any) => {
  const [stageResult, setStageResult] = useState(RESULT_STAGES[0]);
  const removeStage = (stage: string) => {
    item.handleChange((prev: any) => {
      return Object.keys(prev).reduce<Record<string, any>>((accum, item) => {
        if (item !== stage) accum = {...accum, [item]: {...prev[item]}}
        return accum
      }, {})
    })
  }
  const setContent = (e: React.ChangeEvent<HTMLInputElement>) => {
    item.handleChange((prev: any) => ({
      ...prev,
      [stageResult]: {
        button: {...prev[stageResult].button, [e.target.name]: e.target.value},
        styles: {components: {}, colors: {}}
      }
    }))
  }
  const addResultStage = () => {
    item.handleChange((prev: any) => ({
      ...prev,
      [stageResult]: {button: {content: '', placement: ''}, styles: {components: {}, colors: {}}}
    }))
  }
  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setStageResult(e.target.value);
  }
  const setColors = (result: any) => {
    return (data: any) => {
      item.handleChange((prev: any) => ({
        ...prev,
        [result]: {...prev[result], styles: {...prev[result].styles, colors: {...data}}}
      }))
    }
  }
  const setComponents = (result: any) => {
    return (data: any) => {
      item.handleChange((prev: any) => ({
        ...prev,
        [result]: {...prev[result], styles: {...prev[result].styles, components: {...data}}}
      }))
    }
  }
  return (
    <div>
      {item.id === 'resultsStage' && (
        <>
          <div style={{
            display: 'flex', gap: '20px',
            alignItems: 'baseline'
          }}>
            <Select onChange={handleSelect} value={stageResult}>
              {RESULT_STAGES.map((result) => (<option value={result} key={result}>{result}</option>))}
            </Select>
            <SaveButton onClick={addResultStage} style={{flex: '0 1 30%'}}>Add Stage</SaveButton>
          </div>
          {
            RESULT_STAGES.map((resultItem) => {
              return (
                <div style={{marginTop: '20px'}} key={resultItem}>
                  {item.value[resultItem] &&
										<div style={{background: `${RESULT_STAGES_COLORS[resultItem]}`, padding: '10px 20px'}}>
											<div style={{display: 'flex', gap: '20px',
                        alignItems: 'center', justifyContent: 'space-between'}}><h1>{resultItem}</h1><RemoveButton
												onClick={() => removeStage(resultItem)}>Remove</RemoveButton></div>
                      {item.value[resultItem]?.button && (
                        <>
                          <Group>
                            <Label>Content</Label>
                            <Input value={item.value[resultItem]?.button.content} name={'content'}
                                   onChange={setContent}/>
                          </Group>
                          <Group>
                            <Label>Placement</Label>
                            <Input value={item.value[resultItem]?.button.placement} name={'placement'}
                                   onChange={setContent}/>
                          </Group>
                        </>
                      )}
                      {item.value[resultItem]?.styles && (
                        <StylesConfiguration setColors={setColors(resultItem)} setComponents={setComponents(resultItem)}
                                             components={item.value[resultItem]?.styles.components}
                                             colors={item.value[resultItem]?.styles.colors} id="resultStage" />
                      )}
										</div>
                  }</div>
              )
            })
          }
        </>

      )}
    </div>
  )
}

export default ResultStages
