import React, { useContext, useState } from 'react';

import { Group, Label, Input, SaveButton } from '../styles';
import { ConfigurationContext } from '../';

function BooleanIconControlConfig() {
  const {
    configuration,
    setConfiguration,
  }: any = useContext(ConfigurationContext);

  const [controlValue, setControlValue] = useState('');
  const [icon, setIcon] = useState('');
  const [activeIcon, setActiveIcon] = useState('');

  const handleAddValue = () => {
    setConfiguration({
      ...configuration,
      BooleanIconControl: {
        ...configuration?.BooleanIconControl,
        valuesProps: {
          ...configuration?.BooleanIconControl?.valuesProps,
          [controlValue]: {
            icon,
            activeIcon
          }
        }
      }
    });
    setControlValue('');
    setIcon('');
    setActiveIcon('');
  };

  const handleDelete = (value: string) => {
    const valuesProps = {...configuration?.BooleanIconControl?.valuesProps};
    delete valuesProps[value];
    setConfiguration({
      ...configuration,
      BooleanIconControl: {
        valuesProps
      }
    });
  };

  return (
    <>
      <h3>BooleanIconControl Config</h3>
      
      <Group>
        <Label>Value</Label>
        <Input
          id="booleanIconsControl_value"
          name="booleanIconsControl_value"
          value={controlValue}
          onChange={(e) => setControlValue(e.target.value)} />
      </Group>
      <Group>
        <Label>Icon</Label>
        <Input
          id="booleanIconsControl_icon"
          name="booleanIconsControl_icon"
          value={icon}
          onChange={(e) => setIcon(e.target.value)} />
      </Group>
      <Group>
        <Label>Active Icon</Label>
        <Input
          id="booleanIconsControl_activeIcon"
          name="booleanIconsControl_activeIcon"
          value={activeIcon}
          onChange={(e) => setActiveIcon(e.target.value)} />
      </Group>
      <SaveButton onClick={handleAddValue}>Add Value</SaveButton>

      {configuration?.BooleanIconControl?.valuesProps && Object.keys(configuration?.BooleanIconControl?.valuesProps)?.map((item: string) => {
        return <div key={item}>
            <p>{item}: <button onClick={() => handleDelete(item)}>delete</button></p>
            <p>Icon: {configuration?.BooleanIconControl?.valuesProps[item].icon}</p>
            <p>ActiveIcon: {configuration?.BooleanIconControl?.valuesProps[item].activeIcon}</p>
        </div>
      })}
    </>
  );
}

export default BooleanIconControlConfig;
