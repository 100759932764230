import React from 'react';
import { LoaderWrap, StyledIcon, Paragraph } from './styles';
import loader from '../../../../assets/verticalSoftware/loader.svg';

const Loader = () => {
  return (
    <LoaderWrap>
      <StyledIcon>
        <img src={loader} alt="" />
      </StyledIcon>
      <Paragraph>
        We are processing your request...
      </Paragraph>
    </LoaderWrap>
  );
};

export default Loader;