import React, { useContext, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

import StagesConfiguration from '../StagesConfiguration';
import { SaveButton, Group, Input, Label } from '../styles';

import { ConfigurationContext } from '../';

function StepsComponent() {
  const {
    steps,
    setSteps,
  }: any = useContext(ConfigurationContext);

  const [stepName, setStepName] = useState('');

  const handleAddStep = () => {
    setSteps({
        ...steps,
        [stepName]: {},
    });
  };
  const handleChange = (item: string, value: any) => {
    setSteps({
      ...steps,
      [item]: value
    });
  }

  return (
    <>
      <h2>Steps Configuration</h2>
      <Group>
        <Label>Step Name</Label>
        <Input
          id="stepName"
          name="stepName"
          value={stepName}
          onChange={(e) => setStepName(e.target.value)} />
          <SaveButton onClick={handleAddStep}>Add Step</SaveButton>
      </Group>

      <Accordion allowZeroExpanded={true}>
        {steps && Object.keys(steps).map(item => {
          return <AccordionItem key={item} className="accordionPaddingZero">
              <AccordionItemHeading>
                <AccordionItemButton>
                  {item}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className="accordionContent">
                <StagesConfiguration item={{
                  value: steps[item],
                  handleChange: (value: any) => handleChange(item, value),
                  id: item,
                  props: ['title', 'button', 'logo', 'heroImage', 'footer', 'template', 'templateAttributes', 'description']
                }} />
              </AccordionItemPanel>
          </AccordionItem>
        })}
      </Accordion>
    </>
  );
}

export default StepsComponent;
