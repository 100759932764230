import React from 'react'
import {Input, Label, RemoveButton, SaveButton} from "../styles";
import {ButtonBlock, FieldWrapper, FormFieldBlock} from "./styles";

interface Metadata {
	key: string;
	id: string
}

interface Props {
	metadata: Metadata[],
	setMetadata: React.Dispatch<React.SetStateAction<{key: string, id: string}[]>>
}
const MetadataFields = ({metadata, setMetadata}: Props) => {
	const handleChange = (i: number, e: React.ChangeEvent<HTMLInputElement>) => {
		const key = e.target.name as keyof Metadata;
		let newFormValues: Metadata[] = [...metadata];
		newFormValues[i][key] = e.target.value;
		setMetadata(newFormValues);
	}
	const addFormFields = () => {
		setMetadata([...metadata, { key: "", id: "" }])
	}

	const removeFormField = (i: number) => {
		const newFormValues = [...metadata]
		newFormValues.splice(i, 1)
		setMetadata(newFormValues)
	}
	return (
		<>
			{metadata.map((item, index) => {
				return <FormFieldBlock key={item.id}>
					<FieldWrapper>
					<Label>Key</Label>
					<Input
						name="key"
						type="text"
						value={item.key}
						onChange={(e) => handleChange(index, e)} />
					</FieldWrapper>
					<FieldWrapper>
					<Label>Id</Label>
					<Input
						name="id"
						type="text"
						value={item.id}
						onChange={(e) => handleChange(index, e)} />
					</FieldWrapper>
					<ButtonBlock>
						{index !== 0 && <RemoveButton onClick={() => removeFormField(index)}>Remove</RemoveButton> }
					</ButtonBlock>
				</FormFieldBlock>
			})}
			<SaveButton onClick={addFormFields}>Add Field</SaveButton>
		</>
	)
}

export default MetadataFields
