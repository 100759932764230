import styled from "styled-components";
import imagery from '../../assets/imagery.png';

export const Main = styled.main`
position: relative;
width: 100%;
min-height: 100vh;
display: 'flex';
flex-direction: column;
justify-content: space-between;
z-index: 1;
`;

export const Section = styled.section<{isFirstPage: boolean}>`
${props => props.isFirstPage ? `

//------------------------------ 1st PAGE (Section) -------------------------------------
z-index: 2;
position: relative;
min-height: calc(100vh - 92px);
overflow: hidden;
padding-top: 100px;
box-sizing: border-box;
&:before {
  content: '';
  position: absolute;
  bottom: 25px;
  left: 0;
  background: #383874;
  border-radius: 0px 0px 100px 0px;
  width: 70%;
  height: 100%;
}
&:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  background: url(${imagery}) no-repeat;
  height: 100%;
  width: 100%;
  background-position: right center;
  background-size: auto 100%;
}
//------------------------------ MEDIA QUERIES for 1st page
@media (min-width: 1025px) {
  &:before {
    width: 80%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  &:before {
    width: 85%;
  }
  &:after {
    background-position: 0 center;
  }
}
@media (max-width: 767px) {
  padding: 30px 0;
  &:before {
    width: 100%;
  }
  &:after {
    display: none;
  }
}
//------------------------------

` : `

//------------------------------ PAGES except 1st (Section) -------------------------------------
z-index: 2;
position: relative;
box-sizing: border-box;
width: 100%;
height: calc(100vh - 92px);
display: flex;
flex-direction: column;
align-items: center;
background: #f2f2fc;
//------------------------------ MEDIA QUERIES for pages except 1st
@media (max-width: 1024px) {
  height: calc(100vh - 142px);
  padding: 0 20px;
}
//------------------------------

`}
`;

export const Container = styled.div<{isFirstPage: boolean}>`
${props => props.isFirstPage ? `

//------------------------------ 1st PAGE (Container) -------------------------------------
z-index: 3;
box-sizing: border-box;
position: relative;
width: 70%;
//------------------------------ MEDIA QUERIES for 1st page
@media (max-width: 1919px) {
  max-width: 1177px;
}
@media (max-width: 1024px) {
  width: 80%;
}
@media (max-width: 767px) {
  width: 100%;
}
@media (max-width: 480px) {
}
//------------------------------

` : `

//------------------------------ PAGES except 1st (Container) -------------------------------------
z-index: 3;
position: relative;
box-sizing: border-box;
display: flex;
flex-direction: column;
align-items: center;
padding: 0px;
width: 1006px;
max-width: 100%;
border-radius: 20px;
overflow: hidden;
background: white;
margin-top: 24px;
//------------------------------ MEDIA QUERIES for pages except 1st
@media (min-width: 1921px) {
  width: 1800px;
}
//------------------------------

`}
`;

export const WidgetDiv = styled.div<{isFirstPage: boolean}>`
margin: 100px auto;

@media (max-width: 1919px) {
  .ix-logo {
    max-width: 210px;
    max-height: 70px;
  }
}

${(props) => props.isFirstPage ? `

//------------------------------ 1st PAGE (WidgetDiv) -------------------------------------
max-width: 745px;

.ix-submit-button-div div {
  justify-content: flex-start;
}
.ix-logo-div {
  justify-content: flex-start;
}
.ix-title, .ix-form-div label, .ix-footer p, .ix-footer a {
  color: #ffffff;
}

//------------------------------ MEDIA QUERIES for 1st page
@media (min-width: 1921px) {
  max-width: 1400px;
}
@media (max-width: 1919px) {
  .ix-submit-button-div button {
    font-size: 20px;
    height: 56px;
    padding: 16px 38px;
  }
  .ix-title {
    font-size: 52px;
    line-height: 64px;
  }
  .control {
    label{
      font-size: 20px;
    }
    .input, .select{
      height: 56px;
    }
  }
}
@media (min-width: 1281px) {
  .ix-title-div {
    margin-top: 100px;
  }
  .ix-form-div {
    margin-top: 40px;
  }
  .ix-submit-button-div {
    margin-top: 40px;
  }
}
@media (max-width: 1280px) {
  .ix-title-div {
    margin-top: 50px;
  }
  .ix-form-div {
    margin-top: 24px;
  }
  .ix-submit-button-div {
    margin-top: 24px;
  }
  .ix-title {
    font-size: 42px;
    line-height: initial;
  }
}
@media (max-width: 1024px) {
}
@media (max-width: 767px) {
  .ix-content {
    padding: 0 20px 20px 20px;
  }
}
@media (max-width: 480px) {
}
//------------------------------

` : `

//------------------------------ PAGES except 1st (WidgetDiv) -------------------------------------
width: 100%;
.ix-title {
  text-align: center;
}

div.ix-container {
  padding: 0 !important;
}
.header {
  padding: 10px 32px;
  background: #383874;
  box-sizing: border-box;

  span {
    color: #ffffff;
  }
  button > svg {
    fill: #ffffff;
  }
}
.ix-imageHeader {
  margin: 0;
  &:before {
    opacity: 1;
  }
}
.ix-content {
  box-sizing: border-box;
  padding: 0 128px 48px 128px;
}
//------------------------------ MEDIA QUERIES for pages except 1st
@media (min-width: 768px) && (max-width: 1919px) {
  .ix-title-div {
    margin-top: 48px;
  }
  .ix-form-div {
    margin-top: 24px;
  }
  .ix-submit-button-div {
    margin-top: 24px;
  }
  .header {
    height: 80px;
  }
  .ix-title {
    inline-size: 450px;
  }
}
@media (max-width: 767px) {
  div.ix-container {
    .ix-content {
      padding: 0 20px 20px 20px;
    }
  }
}
@media (max-width: 480px) {
}
//------------------------------

`};
`;
