import React, {useState, useEffect} from 'react';
import { 
  Wrapper, 
  Container, 
  SubmitButton, 
  EditButton, 
  Steps, 
  Header, 
  Title, 
  Block, 
  Logo, 
  Car,
  BackButton,
  Table,
  Row,
  Col,
  Thead,
  Tbody,
  Price,
  Quote,
  Paragraph,
  Input,
  DeclineButton,
  ButtonsBlock,
  Label,
} from './styles';
import logo from '../../../assets/LibertyMutual/logo.svg';
import car from '../../../assets/verticalSoftware/car-icon.svg';
import iconBack from '../../../assets/verticalSoftware/icon-arrow-back.svg';
import iconNext from '../../../assets/verticalSoftware/icon-arrow-next.svg';

import Loader from './Loader';

interface ModalProps {
  handleClose: (price?: string) => void;
};

function VerticalSoftwareModal({handleClose}: Readonly<ModalProps>) {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  const handleConfirm = () => {
    setLoading(true);
    setTimeout(() => {
      setStep(2)
      setLoading(false);
    }, 5000);
  }

  const handleAccept = () => {
    setLoading(true);
    setTimeout(() => {
      handleClose('$182.00/mo');
    }, 5000);
  }

  const handleBack = () => {
    if (step === 2) {
      setStep(1);
    } else {
      handleClose();
    }
  }

  return (
    <Wrapper>
      <Container>
        <Header>
          {step === 1 ? 
            <BackButton onClick={handleBack}>
              <img src={iconBack} alt="" />
            </BackButton> : 
            <div></div>
          }
          <Logo src={logo} />
          <Steps>STEP 1/2</Steps>
        </Header>
        {loading ? <Loader /> : <>
        {step === 1 ? 
          <div>
            <Title>Confirm your vehicle(s)</Title>
            <Block>
              <div>
                <Car><img src={car} alt="" />2022 Infiniti QX 80 Deluxe</Car>
              </div>
              <EditButton disabled>Edit</EditButton>
            </Block>
            <Block>
              <div>
                <Car><img src={car} alt="" />2023 Tomberlin SE-L</Car>
              </div>
              <EditButton disabled>Edit</EditButton>
            </Block>
            <SubmitButton onClick={handleConfirm}>Confirm <img src={iconNext} alt="" /></SubmitButton>
          </div> : 
          <div>
            <Title>Here is your quote</Title>
            <Table>
              <Thead>
                <Quote>6 month policy</Quote>
                <Quote>$182<span>/mo</span></Quote>
              </Thead>
              <Tbody>
                <Row>
                  <Col>Bodily Injury Liability (Per Person/Per Accident)</Col>
                  <Price>$100k/$300k</Price>
                </Row>
                <Row>
                  <Col>Property Damage Liability (Per Accident)</Col>
                  <Price>$100k</Price>
                </Row>
                <Row>
                  <Col>Medical Payments (Per Person)</Col>
                  <Price>$10k</Price>
                </Row>
                <Row>
                  <Col>Uninsured Motorist Bodily Injury (Per Person/Per Accident)</Col>
                  <Price>$100k/$300k</Price>
                </Row>
                <Row>
                  <Col>Collision Deductible</Col>
                  <Price>$500</Price>
                </Row>
                <Row>
                  <Col>Comprehensive Deductible</Col>
                  <Price>$500</Price>
                </Row>
                <Row>
                  <Col>Rental Reimbursement (Per Day)</Col>
                  <Price>$30</Price>
                </Row>
              </Tbody>
            </Table>
            <div>
              <Label>Email*</Label>
              <Input type="email" placeholder='Email' />
              <Paragraph>A brand representative will contact you within an hour to understand your insurance needs.</Paragraph>
              <ButtonsBlock>
                <DeclineButton onClick={(e) => handleClose()}>Decline Benefit</DeclineButton>
                <SubmitButton onClick={handleAccept}>ACCEPT INSURANCE OFFER <img src={iconNext} alt="" /></SubmitButton>
              </ButtonsBlock>
            </div>
          </div>
        }
        </>}
      </Container>
    </Wrapper>
  );
}

export default VerticalSoftwareModal;
