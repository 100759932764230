import styled from "styled-components";

export const Main = styled.main`
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    align-items: center;
    background: #f2f2fc;
`;
export const Section = styled.div`
    margin-left: 72px;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 64px;
    box-sizing: border-box;
    background: #ffffff;

    a {
        text-decoration: none;
        cursor: pointer;
    }

    @media (max-width: 767px) {
        margin-left: 0;
    }
    @media (min-width: 1265px) {
        margin-left: 224px;
    }
`;
export const Header = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    height: 47px;
    z-index: 100;
    background: #ffffff;
    width: 100%;

    @media (min-width: 767px) {
        display: none;
    }
`;
export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    padding: 5px 20px;
`;
export const HeaderIconsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
`;
export const Container = styled.div`
    display: flex;
    justify-content: center;
    width: 630px;
    height: 100vh;
`;
export const PostsBlock = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    max-width: 480px;
`;
export const Post = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #ffffff;
`;
export const PostHeader = styled.div`
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    height: 75px;

    @media (min-width: 737px) {
        padding: 15px 0;
    }
`;
export const AvatarWithName = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    gap: 10px;
`;
export const Avatar = styled.div`
    height: 45px;
    width: 45px;
    border-radius: 100px;
    overflow: hidden;

    img {
        height: 100%;
        width: auto;
    }
`;
export const Name = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: 10px;

    span {
        font-family: 'Inter';
        font-style: normal;
        font-size: 11px;
        line-height: 13px;
        font-weight: 500;
        color: #000000;
    }
`;
export const PostContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;

    img {
        width: 100%;
    }
`;
export const PostTitle = styled.div<{liberty?: boolean}>`
    width: 100%;
    height: 140px;
    background: ${props => props.liberty ? '#F8D247' : '#383874'};
    display: flex;
    align-items: center;

    span {
        font-family: 'Inter';
        margin-inline: auto;
        inline-size: 300px;
        font-size: 30px;
        line-height: 36px;
        font-weight: 600;
        text-align: center;
        color: ${props => props.liberty ? '#010101' : '#ffffff'};
    }
    span:first-line {
        color: ${props => props.liberty ? '#337289' : '#FCA22B'};
    }
`;
export const PostLink = styled.div<{liberty?: boolean}>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    gap: 10px;
    background: ${props => props.liberty ? '#337289' : '#6266EA'};

    span {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 20px;
        color: #ffffff;
    }
`;
export const CommentsSection = styled.div`
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 15px;
    gap: 10px;

    @media (min-width: 737px) {
        padding: 10px 0;
    }
`;
export const PostIcons = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
`;
export const IconsLeft = styled.div`
    display: flex;
    align-items: center;
    padding: 0px;
    gap: 11px;
`;
export const Likes = styled.p`
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-size: 12px;
    line-height: 14.52px;
    font-weight: 600;
    color: #000000;
`;
export const Caption = styled.p`
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    color: #000000;

    span {
        font-weight: 600;
    }
`;
export const ViewAllComments = styled.p`
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-size: 12px;
    line-height: 14.52px;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.44);
`;
export const PublicationTime = styled.p`
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-size: 12px;
    line-height: 14.52px;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.44);
`;
export const Footer = styled.footer`
    box-sizing: border-box;
    border-top: 1px solid #E3E3E3;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 48px;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 30px;
    gap: 20px;
    background: #ffffff;

    @media (min-width: 767px) {
        display: none;
    }
`;
export const Sidebar = styled.footer`
    box-sizing: border-box;
    width: 72px;
    height: 100vh;
    border-right: 1px solid #E3E3E3;
    position: fixed;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 46px 24px;
    gap: 32px;
    top: 0;
    left: 0;
    background: #ffffff;

    a:nth-child(1) {
        margin-bottom: 10px;
    }

    a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
        
        span {
            font-family: 'Inter';
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            margin-bottom: -6px;
        }
    }

    a:nth-child(2) {
        span {
            font-weight: 700;
        }
    }

    @media (max-width: 767px) {
        display: none;
    }
    @media (max-width: 1264px) {
        span {
            display: none;
        }
    }
    @media (min-width: 1265px) {
        align-items: flex-start;
        width: 244px;

        a:nth-child(1) {
            img {
                display: none;
            }
            span img {
                display: inline;
            }
        }
    }
`;
export const RightPanel = styled.div`
    margin-top: 30px;
    padding-top: 16px;
    box-sizing: border-box;
    position: relative;
    width: 320px;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    @media (max-width: 1160px) {
        display: none;
    }
`;
export const ProfileCard = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
export const ProfileAvatarWithName = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`;
export const ProfileAvatar = styled.div`
    width: 56px;
    height: 56px;

    img {
        width: 100%;
    }
`;
export const ProfileName = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'ui-sans-serif', 'system-ui';
    font-size: 15px;
    line-height: 18px;

    span:nth-child(1) {
        font-weight: 600;
    }
    span:nth-child(2) {
        color: rgb(142, 142, 142);
    }
`;
export const SwitchLink = styled.span`
    color: rgb(59, 130, 246);
    font-family: 'ui-sans-serif', 'system-ui';
    font-size: 15px;
    line-height: 16px;
    font-weight: 600;
`;
export const Suggestions = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'ui-sans-serif', 'system-ui';
    font-weight: 600;

    span {
        font-size: 15px;
        line-height: 18px;
        color: rgb(142, 142, 142);
    }

    a span {
        font-size: 15px;
        line-height: 16px;
        color: rgb(38, 38, 38);
    }
`;
export const SuggestedProfilesList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const SuggestedProfileAvatar = styled.div`
    width: 32px;
    height: 32px;

    img {
        width: 100%;
    }
`;
