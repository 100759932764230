import {Title} from "./styles";
import {Checkbox, Group, Input, Label, Select, SaveButton} from "../styles";
import ColorPicker from "../components/ColorPicker/ColorPicker";
import StylesConfiguration from "../StylesConfiguration";
import React, {useState} from "react";

export const renderButtonConfig = (value: any, handleChange: any, id: string, component: string) => {
  return (
    <div key={`${id}_${component}`}>
      <Title>Button</Title>
      <Group>
        <Label>Content</Label>
        <Input
          id={`${id}_button_content`}
          name={`${id}_button_content`}
          type="text"
          value={value?.button?.content || ''}
          onChange={(e) => handleChange({
            ...value,
            button: {...value?.button, content: e.target.value}
          })}/>
      </Group>
      <Group>
        <Label>Border Radius</Label>
        <Input
          id={`${id}_button_borderRadius`}
          name={`${id}_button_borderRadius`}
          type="text"
          value={value?.button?.borderRadius || ''}
          onChange={(e) => handleChange({
            ...value,
            button: {...value?.button, borderRadius: e.target.value}
          })}/>
      </Group>
      <Group>
        <Label>Placement</Label>
        <Select
          id={`${id}_button_placement`}
          name={`${id}_button_placement`}
          value={value?.button?.placement}
          onChange={(e) => handleChange({
            ...value,
            button: {...value?.button, placement: e.target.value}
          })}
        >
          <option value="center">center</option>
          <option value="right">right</option>
          <option value="left">left</option>
          <option value="fullwidth">fullwidth</option>
        </Select>
      </Group>
      {id !== "contactInformation" && <Group>
        <Label>Button Redirect Url</Label>
        <Input
          id={`${id}_button_redirectUrl`}
          name={`${id}_button_redirectUrl`}
          type="text"
          value={value?.button?.redirectUrl || ''}
          onChange={(e) => handleChange({
            ...value,
            button: {...value?.button, redirectUrl: e.target.value}
          })}/>
      </Group>}
      
    </div>
  );
};

export const renderStyles = (colors: any, components: any, setColors: () => void, setComponents: () => void, id: string) => {
  return (<StylesConfiguration key={id} setColors={setColors} setComponents={setComponents}
                               colors={colors} components={components} id={id}/>)
}
export const renderFooterConfig = (value: any, handleChange: any, id: string, component: string) => {
  return (
    <div key={`${id}_${component}`}>
      <Title>{component}</Title>
      <Group>
        <Label>Content</Label>
        <Input
          id={`${id}_${component}_content`}
          name={`${id}_${component}_content`}
          type="text"
          value={value?.[component]?.content || ''}
          onChange={(e) => handleChange({
            ...value,
            [component]: {...value[component], content: e.target.value}
          })}/>
          <Checkbox>
            <Label htmlFor={`${id}_${component}_visible`}>
              <Input
                id={`${id}_${component}_visible`}
                name={`${id}_${component}_visible`}
                type="checkbox"
                checked={value?.[component]?.visible}
                onChange={(e) => handleChange({
                  ...value,
                  [component]: {...value[component], visible: !value[component]?.visible}
                })}/>
              {component} Visible
            </Label>
          </Checkbox>
      </Group>
    </div>
  );
};
export const renderTextConfig = (value: any, handleChange: any, id: string, component: string) => {
  return (
    <div key={`${id}_${component}`}>
      <Title>{component}</Title>
      <Group>
        <Label>Content</Label>
        <Input
          id={`${id}_${component}_content`}
          name={`${id}_${component}_content`}
          type="text"
          value={value?.[component]?.content || ''}
          onChange={(e) => handleChange({
            ...value,
            [component]: {...value[component], content: e.target.value}
          })}/>
        <Checkbox>
          <Label htmlFor={`${id}_${component}_visible`}>
            <Input
              id={`${id}_${component}_visible`}
              name={`${id}_${component}_visible`}
              type="checkbox"
              checked={value?.[component]?.visible}
              onChange={(e) => handleChange({
                ...value,
                [component]: {...value[component], visible: !value[component]?.visible}
              })}/>
            {component} Visible
          </Label>
        </Checkbox>
      </Group>
      <Group>
        <Label>Position</Label>
        <Select
          id={`${id}_${component}_position`}
          name={`${id}_${component}_position`}
          value={value?.[component]?.position}
          onChange={(e) => handleChange({
            ...value,
            [component]: {...value[component], position: e.target.value}
          })}
        >
          <option value="">None</option>
          <option value="left">left</option>
          <option value="right">right</option>
          <option value="center">center</option>
        </Select>
      </Group>
      <Group>
        <Checkbox>
          <Label htmlFor={`${id}_${component}_backButton_visible`}>
            <Input
              id={`${id}_${component}_backButton_visible`}
              name={`${id}_${component}_backButton_visible`}
              type="checkbox"
              checked={value?.[component]?.backButton?.visible}
              onChange={(e) => handleChange({
                ...value,
                [component]: {...value[component], backButton: {...value[component]?.backButton, visible: !value[component]?.backButton?.visible}}
              })}/>
            Back Button Visible
          </Label>
        </Checkbox>
      </Group>
      <Group>
        <Label>BackButton Src</Label>
        <Input
          id={`${id}_${component}_backButton_src`}
          name={`${id}_${component}_backButton_src`}
          type="text"
          value={value?.[component]?.backButton?.src || ''}
          onChange={(e) => handleChange({
            ...value,
            [component]: {...value[component], backButton: {...value[component]?.backButton, src: e.target.value}}
          })}/>
      </Group>
      <Group>
        <Label>BackButton Image Width</Label>
        <Input
          id={`${id}_${component}_backButton_width`}
          name={`${id}_${component}_backButton_width`}
          type="text"
          value={value?.[component]?.backButton?.width || ''}
          onChange={(e) => handleChange({
            ...value,
            [component]: {...value[component], backButton: {...value[component]?.backButton, width: e.target.value}}
          })}/>
      </Group>
      <Group>
        <Label>BackButton Image Heigth</Label>
        <Input
          id={`${id}_${component}_backButton_height`}
          name={`${id}_${component}_backButton_height`}
          type="text"
          value={value?.[component]?.backButton?.height || ''}
          onChange={(e) => handleChange({
            ...value,
            [component]: {...value[component], backButton: {...value[component]?.backButton, height: e.target.value}}
          })}/>
      </Group>
    </div>
  );
};
export const renderLogo = (value: any, handleChange: any, id: string, component: string) => {
  return (
    <div key={`${id}_${component}`}>
      <Title>{component}</Title>
      <Group>
        <Label>Logo src</Label>
        <Input
          id={`${id}_${component}_src`}
          name={`${id}_${component}_rsc`}
          type="text"
          value={value?.[component]?.src || ''}
          onChange={(e) => handleChange({
            ...value,
            [component]: {...value[component], src: e.target.value}
          })}/>
      </Group>
      <Group>
        <Label>Logo width</Label>
        <Input
          id={`${id}_${component}_width`}
          name={`${id}_${component}_width`}
          type="text"
          value={value?.[component]?.width || ''}
          onChange={(e) => handleChange({
            ...value,
            [component]: {...value[component], width: e.target.value}
          })}/>
      </Group>
      <Group>
        <Label>Logo height</Label>
        <Input
          id={`${id}_${component}_height`}
          name={`${id}_${component}_height`}
          type="text"
          value={value?.[component]?.height || ''}
          onChange={(e) => handleChange({
            ...value,
            [component]: {...value[component], height: e.target.value}
          })}/>
      </Group>
      <Group>
          <Checkbox>
            <Label htmlFor={`${id}_${component}_visible`}>
            <Input
              id={`${id}_${component}_visible`}
              name={`${id}_${component}_visible`}
              type="checkbox"
              checked={value?.[component]?.visible}
              onChange={(e) => handleChange({
                ...value,
                [component]: {...value[component], visible: !value?.[component]?.visible}
              })}/>
              Logo Visible
            </Label>
          </Checkbox>
      </Group>
      <Group>
        <Label>Position</Label>
        <Select
          id={`${id}_${component}_position`}
          name={`${id}_${component}_position`}
          value={value?.[component]?.position}
          onChange={(e) => handleChange({
            ...value,
            [component]: {...value[component], position: e.target.value}
          })}
        >
          <option value="">None</option>
          <option value="left">left</option>
          <option value="right">right</option>
          <option value="center">center</option>
        </Select>
      </Group>
      <Group>
        <Label>BackButton Src</Label>
        <Input
          id={`${id}_${component}_backButton_src`}
          name={`${id}_${component}_backButton_src`}
          type="text"
          value={value?.[component]?.backButton?.src || ''}
          onChange={(e) => handleChange({
            ...value,
            [component]: {...value[component], backButton: {...value[component]?.backButton, src: e.target.value}}
          })}/>
      </Group>
      <Group>
        <Label>BackButton Image Width</Label>
        <Input
          id={`${id}_${component}_backButton_width`}
          name={`${id}_${component}_backButton_width`}
          type="text"
          value={value?.[component]?.backButton?.width || ''}
          onChange={(e) => handleChange({
            ...value,
            [component]: {...value[component], backButton: {...value[component]?.backButton, width: e.target.value}}
          })}/>
      </Group>
      <Group>
        <Label>BackButton Image Height</Label>
        <Input
          id={`${id}_${component}_backButton_height`}
          name={`${id}_${component}_backButton_height`}
          type="text"
          value={value?.[component]?.backButton?.height || ''}
          onChange={(e) => handleChange({
            ...value,
            [component]: {...value[component], backButton: {...value[component]?.backButton, height: e.target.value}}
          })}/>
      </Group>
    </div>
  );
};
export const renderHeroImg = (value: any, handleChange: any, id: string, component: string) => {
  return (
    <div key={`${id}_${component}`}>
      <Title>{component}</Title>
      <Group>
        <Label>Position</Label>
        <Select
          id={`${id}_${component}_position`}
          name={`${id}_${component}_position`}
          value={value?.[component]?.position}
          onChange={(e) => handleChange({
            ...value,
            [component]: {...value[component], position: e.target.value}
          })}
        >
          <option value="">None</option>
          <option value="top">top</option>
          <option value="bottom">bottom</option>
          <option value="left">left</option>
          <option value="right">right</option>
          <option value="contain">contain</option>
        </Select>
      </Group>
      <Group>
        <Label>heroImage src</Label>
        <Input
          id={`${id}_${component}_src`}
          name={`${id}_${component}_src`}
          type="text"
          value={value?.[component]?.src || ''}
          onChange={(e) => handleChange({
            ...value,
            [component]: {...value[component], src: e.target.value}
          })}/>
      </Group>
      <Group>
        <Label>Width (the position of the heroImage is left or right)</Label>
        <Input
          id={`${id}_${component}_width`}
          name={`${id}_${component}_width`}
          type="text"
          value={value?.[component]?.width || ''}
          onChange={(e) => handleChange({
            ...value,
            [component]: {...value[component], width: e.target.value}
          })}/>
      </Group>
      <Group>
        <Label>Height (the position of the heroImage is top or bottom)</Label>
        <Input
          id={`${id}_${component}_height`}
          name={`${id}_${component}_height`}
          type="text"
          value={value?.[component]?.height || ''}
          onChange={(e) => handleChange({
            ...value,
            [component]: {...value[component], height: e.target.value}
          })}/>
      </Group>
    </div>
  );
};
export const renderTitleConfig = (value: any, handleChange: any, id: string, component: string) => {
  return (
    <div key={`${id}_${component}`}>
      <Title>{component}</Title>
      <Group>
        <Label>Content</Label>
        <Input
          id={`${id}_${component}_content`}
          name={`${id}_${component}_content`}
          type="text"
          value={value?.[component]?.content || ''}
          onChange={(e) => handleChange({
            ...value,
            [component]: { content: e.target.value}
          })}
        />
      </Group>
    </div>
  );
};
export const renderBoolean = (value: any, handleChange: any, id: string, component: string) => {
  return (
    <div key={`${id}_${component}`}>
      <Title>{component}</Title>
      <Group>
        <Label>
          <Checkbox>
            <Label htmlFor={`${id}_${component}`}>
              <Input
                id={`${id}_${component}`}
                name={`${id}_${component}`}
                type="checkbox"
                checked={value[component]}
                onChange={(e) => handleChange({
                  ...value,
                  [component]: !value[component]
                })}/>
              Yes
            </Label>
          </Checkbox>
        </Label>
      </Group>
    </div>
  );
};
export const renderTemplate = (value: any, handleChange: any, id: string, component: string) => {
  return (
    <div key={`${id}_${component}`}>
      <Title>Template</Title>
      <Group>
        <Select
          id={`${id}`}
          name={`${id}`}
          value={value?.template}
          onChange={(e) => handleChange({
            ...value,
            template: e.target.value
          })}
        >
          {id === 'resultsStage' ?
            <>
              <option value="">default</option>
              <option value="coverage">coverage</option>
            </> : <>
              <option value="default">default</option>
              <option value="vehicle">vehicle</option>
              <option value="vehicles">vehicles</option>
              <option value="assets">assets</option>
              <option value="coverage">coverage</option>
              <option value="coverages">coverages</option>
            </>
          }
        </Select>
      </Group>
    </div>
  );
};
export const renderTemplateAttributes = (value: any, handleChange: any, id: string, component: string) => {
  return (
    <div key={`${id}_${component}`}>
      <Title>Template Attributes</Title>

      {id === 'confirmationStage' &&
				<Group>
					<Label>CoverageName</Label>
					<Input
						id={`${id}_CoverageName`}
						name={`${id}_CoverageName`}
						type="text"
						value={value?.templateAttributes?.CoverageName}
						onChange={(e) => handleChange({
              ...value,
              templateAttributes: {...value?.templateAttributes, CoverageName: e.target.value}
            })}/>
				</Group>
      }
      <Group>
        <Label>CoveragePrice</Label>
        <Input
          id={`${id}_CoveragePrice`}
          name={`${id}_CoveragePrice`}
          type="text"
          value={value?.templateAttributes?.CoveragePrice || ''}
          onChange={(e) => handleChange({
            ...value,
            templateAttributes: {...value?.templateAttributes, CoveragePrice: e.target.value}
          })}/>
      </Group>

      {id === 'resultsStage' &&
				<>
					<Group>
						<Label>CoverageDuration</Label>
						<Input
							id={`${id}_CoverageDuration`}
							name={`${id}_CoverageDuration`}
							type="text"
							value={value?.templateAttributes?.CoverageDuration}
							onChange={(e) => handleChange({
                ...value,
                templateAttributes: {...value?.templateAttributes, CoverageDuration: e.target.value}
              })}/>
					</Group>
					<Group>
						<Label>CoverageType</Label>
						<Input
							id={`${id}_CoverageType`}
							name={`${id}_CoverageType`}
							type="text"
							value={value?.templateAttributes?.CoverageType || ''}
							onChange={(e) => handleChange({
                ...value,
                templateAttributes: {...value?.templateAttributes, CoverageType: e.target.value}
              })}/>
					</Group>
				</>
      }
    </div>
  );
};
export const RenderStepCategories = (value: any, handleChange: any, id: string, component: string) => {
  const [stepName, setStepName] = useState('');
  const [attributes, setAttributes] = useState('');

  const handleAddStep = () => {
    handleChange({
      stepCategories: {
        ...value.stepCategories,
        [stepName]: attributes.split(', '),
      },
    });
  };

  const handleDelete = (key: string) => {
    const prevValue = {...value.stepCategories};
    delete prevValue[key];
    handleChange({
      stepCategories: prevValue,
    });
  };

  return (
    <div key={`${id}_${component}`}>
      <Title>Step Categories</Title>

      <Group>
        <Label>Step Name</Label>
        <Input
          id="stepName"
          name="stepName"
          value={stepName}
          onChange={(e) => setStepName(e.target.value)} />
      </Group>
      <Group>
        <Label>Step Attributes</Label>
        <Input
          id="step_attributes"
          name="step_attributes"
          value={attributes}
          onChange={(e) => setAttributes(e.target.value)} />
      </Group>
      <SaveButton onClick={handleAddStep}>Add Step</SaveButton>

      {value?.stepCategories && Object.keys(value.stepCategories).map(item => {
        const categoryList = value.stepCategories[item]?.join(', ');
        return <p key={item}>{item}: {categoryList} <button onClick={() => handleDelete(item)}>delete</button></p>
      })}

    </div>
  );
};

export interface StepsConfigurationData {
  'top'?: StepConfig,
  'bottom'?: StepConfig,
}
export interface StepConfig  {
  content?: string,
  styles?: Styles
}
type Styles = Partial<Record<string, string>>

export const renderDescription = (value: any, handleChange: any, id: string, component: string) => {
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>, position: 'top' | 'bottom', styles?: boolean) => {
    const {name} = event.target;
    let newObj: StepsConfigurationData = {};
    if(!styles) {
      newObj = {
        ...value,
        description: {
          ...value?.description,
          [position]: {
            ...(value?.description && {...value?.description[position]}),
            [name]: event.target.value
          }
        }
      }
      if(!event.target.value) delete newObj[position]?.content
    } else {
      newObj = {
        ...value,
        description: {
          ...value?.description,
          [position]: {
            ...(value?.description && {...value?.description[position]}),
            styles:  {
              ...(value?.description?.[position] && {...value?.description[position].styles}),
              [name]: event.target.value
            },
          }
        }
      }
      if(!event.target.value) delete newObj[position]?.styles?.[name]
    }
    handleChange(newObj);
  }

  const changeColorHandler = (name: string, data: string, position: 'top' | 'bottom' ) => {
    handleChange({
      ...value,
      description: {
        ...value?.description,
        [position]: {
          ...value?.description[position],
          styles:  {
            ...value?.description[position]?.styles,
            [name]: data
          },
        }
      }
    });
  }

  const top = value?.description?.top;
  const bottom = value?.description?.bottom;

  return (
    <div key={`${id}_${component}`}>
      <Title>{component}</Title>
        <Title>Top</Title>
        <Group>
          <Label>Content</Label>
          <Input
            id="content"
            name="content"
            value={top?.content || ''}
            onChange={(e) => changeHandler(e, 'top')}
          />
          <h3>Styles</h3>
          <Group>
            <Label>Font Size</Label>
            <Input
              id="fontSize"
              name="fontSize"
              value={top?.styles?.fontSize || ''}
              onChange={(e) => changeHandler(e, 'top', true)}
            />
            <Label>Font Family</Label>
            <Input
              id="fontFamily"
              name="fontFamily"
              value={top?.styles?.fontFamily || ''}
              onChange={(e) => changeHandler(e, 'top', true)}
            />
            <Label>Font Weight</Label>
            <Input
              id="fontWeight"
              name="fontWeight"
              value={top?.styles?.fontWeight || ''}
              onChange={(e) => changeHandler(e, 'top', true)}
            />
            <Label>Text Align</Label>
            <Input
              id="textAlign"
              name="textAlign"
              value={top?.styles?.textAlign || ''}
              onChange={(e) => changeHandler(e, 'top', true)}
            />
            <Label>Padding</Label>
            <Input
              id="padding"
              name="padding"
              value={top?.styles?.padding || ''}
              onChange={(e) => changeHandler(e, 'top', true)}
            />
            <Label>Color</Label>
            <ColorPicker color={top?.styles?.color || 'black'}
                         onChange={(e) => changeColorHandler('color', e.hex, 'top')}/>
            <Label>Background Color</Label>
            <ColorPicker color={top?.styles?.backgroundColor || '#ffffff'}
                         onChange={(e) => changeColorHandler('backgroundColor', e.hex, 'top')}/>
          </Group>
        </Group>
        {id !== 'ctaStage' && <>
        <Title>Bottom</Title>
        <Group>
          <Label>Content</Label>
          <Input
            id="content"
            name="content"
            value={bottom?.content || ''}
            onChange={(e) => changeHandler(e, 'bottom')}
          />
          <h3>Styles</h3>
          <Group>
            <Label>Font Size</Label>
            <Input
              id="fontSize"
              name="fontSize"
              value={bottom?.styles?.fontSize || ''}
              onChange={(e) => changeHandler(e, 'bottom', true)}
            />
            <Label>Font Family</Label>
            <Input
              id="fontFamily"
              name="fontFamily"
              value={bottom?.styles?.fontFamily || ''}
              onChange={(e) => changeHandler(e, 'bottom', true)}
            />
            <Label>Font Weight</Label>
            <Input
              id="fontWeight"
              name="fontWeight"
              value={bottom?.styles?.fontWeight || ''}
              onChange={(e) => changeHandler(e, 'bottom', true)}
            />
            <Label>Text Align</Label>
            <Input
              id="textAlign"
              name="textAlign"
              value={bottom?.styles?.textAlign || ''}
              onChange={(e) => changeHandler(e, 'bottom', true)}
            />
            <Label>Padding</Label>
            <Input
              id="padding"
              name="padding"
              value={bottom?.styles?.padding || ''}
              onChange={(e) => changeHandler(e, 'bottom', true)}
            />
            <Label>Color</Label>
            <ColorPicker color={bottom?.styles?.color || 'black'}
                         onChange={(e) => changeColorHandler('color', e.hex, 'bottom')}/>
            <Label>Background Color</Label>
            <ColorPicker color={bottom?.styles?.backgroundColor || '#ffffff'}
                         onChange={(e) => changeColorHandler('backgroundColor', e.hex, 'bottom')}/>
          </Group>
        </Group>
        </>}
    </div>
  );
};

export const renderAttributes = (value: any, handleChange: any, id: string, component: string) => {
  return (
    <div key={`${id}_${component}`}>
      <Title>Template Attributes</Title>

      <Group>
        <Label>DisplayName</Label>
        <Checkbox>
          <Label htmlFor={`${id}_${component}_DisplayName`}>
            <Input
              id={`${id}_${component}_DisplayName`}
              name={`${id}_${component}_vDisplayName`}
              type="checkbox"
              checked={value?.[component]?.DisplayName?.bold}
              onChange={(e) => handleChange({
                ...value,
                [component]: {...value[component], DisplayName: {
                  bold: !value[component]?.DisplayName?.bold
                }}
              })}/>
            {component} Bold
          </Label>
        </Checkbox>
      </Group>
      <Group>
        <Label>Value</Label>
        <Checkbox>
          <Label htmlFor={`${id}_${component}_value`}>
            <Input
              id={`${id}_${component}_value`}
              name={`${id}_${component}_value`}
              type="checkbox"
              checked={value?.[component]?.Value?.bold}
              onChange={(e) => handleChange({
                ...value,
                [component]: {...value[component], Value: {
                  bold: !value[component]?.Value?.bold
                }}
              })}/>
            {component} Bold
          </Label>
        </Checkbox>
      </Group>
    </div>
  );
};
