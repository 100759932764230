import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 100vh;
  padding: 50px;
  box-sizing: border-box;
`;
export const Row = styled.div`
  display: flex;
  border-bottom: 1px solid #DADAE3;
`;
export const Col = styled.div`
  width: 50%;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  &.dark {
    background: #383874;
    padding: 16px 24px;
  }
  &.primary {
    background: #6266EA;
    padding: 16px 24px;
  }
  &.light {
    background: #F2F2FC;
    p {
      font-size: 13px;
      line-height: 24px;
    }
  }

  &.row {
    display: flex;
    padding: 0;
  }

  &.col-4 {
    width: 40%;
  }
  &.col-8 {
    width: 60%;
  }
  & > div:nth-child(3) {
    text-align: right;
  }

  p {
    font-family: 'Arial';
    font-style: normal;
    color: #000000;
    margin: 0;
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    &.title {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 8px;
    }
    &.price {
      font-size: 20px;
      line-height: 32px;
      text-align: right;
      margin-bottom: 10px;
    }
  }
`;
export const Table = styled.div`
  width: 100%;
`;
export const Body = styled.div`
`;
export const Head = styled.div`
  display: flex;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  p {
    font-size: 32px;
    line-height: 48px;
    margin: 0;
    color: #FFFFFF;
    span {
      font-size: 18px;
      line-height: 24px;
      display: block;
    }
  }
`;
export const Link = styled.a`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 1px;
  color: #6266EA;
  cursor: pointer;
  text-align: right;
`;
