import React from 'react';
import Footer from './Footer';
import Dashboard from './Dashboard';
import {Main} from './styles';

function HomeComponent() {
  return (
    <Main>
      <Dashboard />
      <Footer />
    </Main>
  );
}

export default HomeComponent;
