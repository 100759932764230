import React, { useEffect, useState } from "react";
import {
  Main,
  Section,
  Container,
  WidgetDiv,
  List,
  Title,
  Description,
  DescriptionDiv
} from "./styles";
import Footer from "./Footer";
import instant from '../../assets/Instant.svg';
import score from '../../assets/score.svg';
import fees from '../../assets/fees.svg';
import logo from "../../assets/Logo-horizontal.svg";
import { CLIENT_ID, PRODUCT_ID_CONFIANZA } from '../../config';

declare global {
  interface Window {
    IXWidget: any;
  }
}

function ThirdTemplate() {
  const [isFirstPage, setIsFirstPage] = useState<boolean>(true);
  const next = () => {
    setIsFirstPage(false);
  };
  const prev = () => {
    setIsFirstPage(true);
  };

  useEffect(() => {
    window.addEventListener("movedToFirstScreen", prev);
    window.addEventListener("onFirstScreenSubmit", next);
    return () => {
      window.removeEventListener("movedToFirstScreen", prev);
      window.removeEventListener("onFirstScreenSubmit", next);
    };
  }, []);

  useEffect(() => {
    window.IXWidget("ix-widget", {
      clientId: CLIENT_ID,
      productId: PRODUCT_ID_CONFIANZA,
      isMicroSite: true,
      theme: "default",
      labelPlacement: 'top',
      logo: {
        src: logo,
      },
      FirstStage: {
        description: {
          visible: false,
        },
        title: {
          content: 'Get coverage in minutes'
        },
        button: {
          content: "Start my quote",
        },
      },
      loader: {
        fullscreen: true,
        blurred: true,
        color: '#ffffff',
      },
      styles: {
        components: {
          container: {
            background: "#ffffff",
          },
          paragraphs: {
            fontSize: '16px',
          },
          input: {
            fontSize: '20px',
          }
        },
      },
    });
  }, []);

  return (
    <Main>
      <Section>
        {isFirstPage && <Title>Your perfect insurance match in minutes</Title>}
        <Container>
          {isFirstPage && <DescriptionDiv>
            <Title>Your perfect insurance match in minutes</Title>
            <Description>
              Boom—You’re on the fast track. Just enter a few details, and we’ll run the numbers. Oh, and no hard credit pull needed, so it won’t impact your credit score. Sweet, right?
            </Description>

            <List>
              <li>
                <img src={instant} alt="" />
                <span>Instant & personalised</span>
              </li>
              <li>
                <img src={score} alt="" />
                <span>No impact on credit score</span>
              </li>
              <li>
                <img src={fees} alt="" />
                <span>No hidden fees</span>
              </li>
            </List>
          </DescriptionDiv>}

          <WidgetDiv isFirstPage={isFirstPage}>
            <div id="ix-widget" />
          </WidgetDiv>
        </Container>
      </Section>
      <Footer />
    </Main>
  );
}

export default ThirdTemplate;
