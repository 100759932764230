import React from 'react';
import {Footer, Copyright, List, ListsContainer, Link} from './styles';
import facebook from '../../../assets/facebook.svg';
import twitter from '../../../assets/twitter.svg';

function FooterComponent() {
  return (
    <Footer>
      <div>
        <Copyright>Copyright © 2023 IXPlatform, Full Location string line  ID #1124061 All rights reserved. 
All loans are subject to approval, to include the underwriting of credit and collateral.</Copyright>
      </div>
      <ListsContainer>
        <List className="agreements">
          <li>
            <Link href="#">PRIVACY POLICY</Link>
          </li>
          <li>
            <Link href="#">NOTICE TERMS OF USE</Link>
          </li>
        </List>
        <List className="social-links">
          <li>
            <Link href="#">
              <img src={facebook} alt="facebook icon" />
            </Link>
          </li>
          <li>
            <Link href="#">
              <img src={twitter} alt="twitter icon" />
            </Link>
          </li>
        </List>
      </ListsContainer>
    </Footer>
  );
}

export default FooterComponent;
