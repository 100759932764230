import React, { useContext } from 'react';
import CodeEditor from '@uiw/react-textarea-code-editor';

import { ConfigurationContext } from '../../';
import { Modal, Wrapper, ErrorText } from "./styles";

interface EditModalProps {
  config_data: any;
  handleClose: () => void;
}

function EditModal({ config_data, handleClose }: Readonly<EditModalProps>) {
  const {
    firstStage,
    setFirstStage,
    ctaStage,
    setCtaStage,
    confirmationStage,
    setConfirmationStage,
    resultsStage,
    setResultsStage,
    colors,
    setColors,
    components,
    setComponents,
    userQualified,
    setUserQualified,
    userNotQualified,
    setUserNotQualified,
    noMatchingData,
    setNoMatchingData,
    setConfiguration,
    setSteps,
    setCustomControls,
  }: any = useContext(ConfigurationContext);

  const handleCopyBlockObject = (obj: any) => {
    return JSON.stringify(obj, null, 6);
  }

  const [code, setCode] = React.useState(handleCopyBlockObject(config_data));
  const [error, setError] = React.useState(false);

  const safelyParseJSON = (json: string) => {
    let parsed

    try {
      parsed = JSON.parse(json)
    } catch (e) {
      console.log(e);
      setError(true);
    }

    return parsed;
  }

  const shallowEqual = (object1: any, object2: any) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
  
    if (keys1.length !== keys2.length) {
      return false;
    }
  
    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }
  
    return true;
  }

  const handleObjectValues = (object1: any, object2: any, action: any) => {
    if ((!object1 && object2) || (object1 && object2 && !shallowEqual(object1, object2)) || !object2) action(object2);
  };

  const handleSave= () => {
    const obj = safelyParseJSON(code);
    if (obj) {
      handleObjectValues(ctaStage, obj.CtaStage, setCtaStage);
      handleObjectValues(firstStage, obj.FirstStage, setFirstStage);
      handleObjectValues(confirmationStage, obj.ConfirmationStage, setConfirmationStage);
      handleObjectValues(resultsStage, obj.ResultsStage, setResultsStage);
      handleObjectValues(colors, obj.styles?.colors, setColors);
      handleObjectValues(components, obj.styles?.components, setComponents);
      handleObjectValues(userQualified, obj.UserQualified, setUserQualified);
      handleObjectValues(userNotQualified, obj.UserNotQualified, setUserNotQualified);
      handleObjectValues(noMatchingData, obj.NoMatchingData, setNoMatchingData);
      setCustomControls(obj.customControls);

      const FirstStageKeys = obj?.FirstStage?.stepCategories ? Object.keys(obj.FirstStage.stepCategories) : [];
      const ConfirmationStageKeys = obj?.ConfirmationStage?.stepCategories ? Object.keys(obj.ConfirmationStage.stepCategories) : [];
      const stageKeys = [...FirstStageKeys, ...ConfirmationStageKeys];
      let new_steps: any = {};
      stageKeys.forEach((item: string) => {
        new_steps[item] = obj[item];
      })

      setSteps(new_steps);
    
      setConfiguration(obj);
      handleClose();
    } else {
      setError(true);
    }
  };

  return (
    <Wrapper>
      <Modal>
        <h2>Widget Code Snippet</h2>
        <CodeEditor
          value={code}
          language="json"
          placeholder="Please enter JS code."
          onChange={(evn: any) => setCode(evn.target.value)}
          padding={15}
        />
        {error && <ErrorText>Can't parse JSON</ErrorText>}
        <button onClick={handleSave}>Save</button>
        <button onClick={handleClose}>Close</button>
      </Modal>
    </Wrapper>
  );
}

export default EditModal;
