/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import hero_image from '../../assets/instagram/hero_image.png';
import liberty_hero_image from '../../assets/instagram/liberty_hero_image.webp';

import instagram_logo from '../../assets/instagram/instagram_logo.svg';

import ix_avatar from '../../assets/instagram/ix_avatar.png';

import chat_icon from '../../assets/instagram/chat_icon.svg';
import post_icon from '../../assets/instagram/post_icon.svg';
import blue_tick_icon from '../../assets/instagram/blue_tick_icon.svg';
import menu_icon from '../../assets/instagram/menu_icon.svg';
import arrow_right_icon from '../../assets/instagram/arrow_right_icon.svg';
import like_icon from '../../assets/instagram/like_icon.svg';
import comment_icon from '../../assets/instagram/comment_icon.svg';
import share_icon from '../../assets/instagram/share_icon.svg';
import bookmark_icon from '../../assets/instagram/bookmark_icon.svg';
import home_icon from '../../assets/instagram/home_icon.svg';
import search_icon from '../../assets/instagram/search_icon.svg';
import instagram_icon from '../../assets/instagram/instagram_icon.svg';

import avatar from '../../assets/instagram/avatar.png';
import big_avatar from '../../assets/instagram/big_avatar.png';

import { 
    Main, 
    Header,
    HeaderContainer,
    HeaderIconsContainer,
    Container,
    PostsBlock,
    Post,
    PostHeader,
    AvatarWithName,
    Avatar,
    Name,
    PostContent,
    PostTitle,
    PostLink,
    CommentsSection,
    IconsLeft,
    PostIcons,
    Likes,
    Caption,
    ViewAllComments,
    PublicationTime,
    Footer,
    Section,
    Sidebar,
    RightPanel,
    ProfileCard,
    ProfileAvatarWithName,
    ProfileName,
    SwitchLink,
    ProfileAvatar,
    Suggestions,
    SuggestedProfilesList,
    SuggestedProfileAvatar,
 } from './styles';

interface InstagramProps {
  liberty?: boolean
}
function Instagram({ liberty }: Readonly<InstagramProps>) {
  return (
    <Main>
      <Sidebar>
        <a>
            <img src={instagram_icon} alt="" />
            <span><img src={instagram_logo} alt="" /></span>
        </a>
        <a>
            <img src={home_icon} alt="" />
            <span>Home</span>
        </a>
        <a>
            <img src={search_icon} alt="" />
            <span>Search query</span>
        </a>
        <a>
            <img src={post_icon} alt="" />
            <span>Create</span>
        </a>
        <a>
            <img src={like_icon} alt="" />
            <span>Notifications</span>
        </a>
        <a>
            <img src={avatar} alt="" />
            <span>Profile</span>
        </a>
      </Sidebar>
      <Section>
        <Header>
            <HeaderContainer>
                <a>
                    <img src={instagram_logo} alt="" />
                </a>
                <HeaderIconsContainer>
                    <a>
                        <img src={chat_icon} alt="" />
                    </a>
                    <a>
                        <img src={post_icon} alt="" />
                    </a>
                </HeaderIconsContainer>
            </HeaderContainer>
        </Header>
        <Container>
          <PostsBlock>
            {[1,2,3].map(i => (
              <Post key={i}>
                <PostHeader>
                  <a>
                    <AvatarWithName>
                          <Avatar>
                            <img src={ix_avatar} alt="" />
                          </Avatar>
                          <Name>
                              <span>IXPlatform</span>
                              <img src={blue_tick_icon} alt="" />
                          </Name>
                      </AvatarWithName>
                    </a>
                    <a>
                        <img src={menu_icon} alt="" />
                    </a>
                </PostHeader>
                <PostContent>
                  <img src={liberty ? liberty_hero_image : hero_image} alt="" />
                  <PostTitle liberty={liberty}>
                    <span>Lock in your quote in the blink of an eye</span>
                  </PostTitle>
                </PostContent>
                <a href="https://nice-sand-0458dee0f.2.azurestaticapps.net/template2">
                  <PostLink liberty={liberty} >
                    <span>Get a quote in 2 clicks </span>
                    <img src={arrow_right_icon} alt="" />
                  </PostLink>
                </a>
                <CommentsSection>
                  <PostIcons>
                    <IconsLeft>
                      <a>
                          <img src={like_icon} alt="" />
                      </a>
                      <a>
                          <img src={comment_icon} alt="" />
                      </a>
                      <a>
                          <img src={share_icon} alt="" />
                      </a>
                    </IconsLeft>
                    <a>
                        <img src={bookmark_icon} alt="" />
                    </a>
                  </PostIcons>
                  <Likes>2,504,803 likes</Likes>
                  <Caption><span>IXPlatform</span> You’re on the right track. Just enter a few details, and we’ll give you your insurance quote in just 2 clicks!</Caption>
                  <ViewAllComments><a>View all 40K comments</a></ViewAllComments>
                  <PublicationTime>12 hours ago</PublicationTime>
                </CommentsSection>
              </Post>
            ))}
          </PostsBlock>
        </Container>
        <RightPanel>
          <ProfileCard>
            <ProfileAvatarWithName>
              <a>
                <ProfileAvatar>
                  <img src={big_avatar} alt="" />
                </ProfileAvatar>
              </a>
              <a>
                <ProfileName>
                  <span>jane_doe</span>
                  <span>Jane Doe</span>
                </ProfileName>
              </a>
            </ProfileAvatarWithName>
            <a>
              <SwitchLink>Switch</SwitchLink>
            </a>
          </ProfileCard>
          <Suggestions>
            <span>Suggestions For You</span>
            <a><span>See All</span></a>
          </Suggestions>
          <SuggestedProfilesList>
            {[1,2,3].map((i) => (
              <ProfileCard key={i}>
                <ProfileAvatarWithName>
                    <a>
                      <SuggestedProfileAvatar>
                        <img src={ix_avatar} alt="" />
                      </SuggestedProfileAvatar>
                    </a>
                    <a>
                      <ProfileName>
                        <span>jhon_doe</span>
                        <span>Jhon Doe</span>
                      </ProfileName>
                    </a>
                </ProfileAvatarWithName>
                <a>
                  <SwitchLink>Switch</SwitchLink>
                </a>
              </ProfileCard>
            ))}
          </SuggestedProfilesList>
        </RightPanel>
        <Footer>
          <a>
              <img src={home_icon} alt="" />
          </a>
          <a>
              <img src={search_icon} alt="" />
          </a>
          <a>
              <img src={post_icon} alt="" />
          </a>
          <a>
              <img src={like_icon} alt="" />
          </a>
          <a>
              <img src={avatar} alt="" />
          </a>
        </Footer>
      </Section>
    </Main>
  );
}

export default Instagram;
