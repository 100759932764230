import React, { useContext, useState, useEffect } from 'react';

import { Group, Label, Input, Row, SaveButton, Select, Checkbox, ErrorMessage } from '../styles';
import { ConfigurationContext } from '../';
import {ControlProps} from '../types';
import MetadataFields from "./MetadataFields";

function ProductConfiguration() {
  const {
    setCustomControls,
    customControls,
    metadata,
    setMetadata,
    configuration,
    setConfiguration,
   }: any = useContext(ConfigurationContext);
  const [attributeName, setAttributeName] = useState('');
  const [renderer, setRenderer] = useState('');
  const [controlsWithDescription, setControlsWithDescription] = useState('');

  const handleAddControl = () => {
    setCustomControls((controls: ControlProps[]) => {
      const result = controls?.filter((control) => control.attributeName !== attributeName) || [];
      return [...result, {attributeName, renderer}];
    });
  }

  useEffect(() => {
    setAttributeName('');
    setRenderer('');
  }, [customControls]);

  useEffect(() => {
    if (controlsWithDescription) {
      const controls = controlsWithDescription.split(', ');
      setConfiguration({
        ...configuration,
        controlsWithDescription: controls
      });
    }
  }, [controlsWithDescription]);
  
  useEffect(() => {
    let savedData = localStorage.getItem('config');
    if (savedData) {
      const saved_data = JSON.parse(savedData);
      if (saved_data.controlsWithDescription) setControlsWithDescription(saved_data.controlsWithDescription.join(', '));
    }
  }, []);

  const handleDelete = (index: number) => {
    const controls = [...customControls];
    controls.splice(index, 1);
    setCustomControls(controls);
  };

  return (
    <>
      <Group>
        <Label>Сlient ID</Label>
        <Input
          id="clientId"
          name="clientId"
          value={configuration.clientId}
          onChange={(e) => setConfiguration({...configuration, clientId: e.target.value})} />
      </Group>
      <Group>
        <Label>Product ID</Label>
        <Input
          id="productId"
          name="productId"
          value={configuration.productId}
          onChange={(e) => setConfiguration({...configuration, productId: e.target.value})} />
      </Group>
      <Group>
        <Label>Widget ID</Label>
        <Input
          id="widgetId"
          name="widgetId"
          value={configuration.widgetId}
          onChange={(e) => setConfiguration({...configuration, widgetId: e.target.value})} />
      </Group>
      <Group>
        <Label>Program ID</Label>
        <Input
          id="programId"
          name="programId"
          value={configuration.programId}
          onChange={(e) => setConfiguration({...configuration, programId: e.target.value})} />
      </Group>
      <h3>Result Rule Names</h3>
      <Group>
        <Label>UserQualified</Label>
        <Input
          id="userQualified"
          name="userQualified"
          value={configuration.resultRuleNames?.UserQualified}
          onChange={(e) => setConfiguration({...configuration, resultRuleNames: {...configuration?.resultRuleNames, UserQualified: e.target.value}})} />
      </Group>
      <Group>
        <Label>UserNotQualified</Label>
        <Input
          id="userNotQualified"
          name="userNotQualified"
          value={configuration.resultRuleNames?.UserNotQualified}
          onChange={(e) => setConfiguration({...configuration, resultRuleNames: {...configuration?.resultRuleNames, UserNotQualified: e.target.value}})} />
      </Group>
      <Group>
        <Label>NoMatchingData</Label>
        <Input
          id="noMatchingData"
          name="noMatchingData"
          value={configuration.resultRuleNames?.NoMatchingData}
          onChange={(e) => setConfiguration({...configuration, resultRuleNames: {...configuration?.resultRuleNames, NoMatchingData: e.target.value}})} />
      </Group>
      <br />
      <Group>
        <Checkbox>
          <Label htmlFor="allowSetDataIfUnableToVerify">
           Allow User to set data when no data is found for a customer
            <Input
              id='allowSetDataIfUnableToVerify'
              name='allowSetDataIfUnableToVerify'
              type="checkbox"
              value={configuration.allowSetDataIfUnableToVerify}
              onChange={() => setConfiguration({...configuration, allowSetDataIfUnableToVerify: !configuration.allowSetDataIfUnableToVerify})} />
          </Label>
        </Checkbox>
      </Group>
      <h3>Metadata</h3>
      <Group>
        <MetadataFields metadata={metadata} setMetadata={setMetadata}/>
      </Group>
      <br />
      <h3>Custom Controls</h3>
      <Row>
        <Group>
          <Input
            id="attributeName"
            name="attributeName"
            value={attributeName}
            onChange={(e) => setAttributeName(e.target.value)}
          />
        </Group>
        <Group>
          <Select
            id="renderer"
            name="renderer"
            value={renderer}
            onChange={(e) => setRenderer(e.target.value)}
          >
            <option value="">--Please choose option --</option>
            <option value="BooleanControl">BooleanControl</option>
            <option value="BooleanToggleControl">BooleanToggleControl</option>
            <option value="ArrayControl">ArrayControl</option>
            <option value="CoverageArrayControl">CoverageArrayControl</option>
            <option value="RadioGroupControl">RadioGroupControl</option>
            <option value="AutocompleteControl">AutocompleteControl</option>
            <option value="VehicleYearControl">VehicleYearControl</option>
            <option value="VehicleFuelControl">VehicleFuelControl</option>
            <option value="VehicleMakeControl">VehicleMakeControl</option>
            <option value="VehicleModelControl">VehicleModelControl</option>
            <option value="VehicleTrimControl">VehicleTrimControl</option>
            <option value="BooleanIconControl">BooleanIconControl</option>
            <option value="RadioIconsControl">RadioIconsControl</option>
            <option value="ArrayOfStringsControl">ArrayOfStringsControl</option>
          </Select>
        </Group>
      </Row>
	    { !renderer && attributeName && <ErrorMessage>Please select custom control option</ErrorMessage>}
      <SaveButton onClick={handleAddControl} disabled={!renderer}>Add Control</SaveButton>

      {customControls?.map((item: ControlProps, i: number) => (
        <p key={item.attributeName}>{item.attributeName}: {item.renderer} <button onClick={() => handleDelete(i)}>delete</button></p>
      ))}


      <br />
      <h3>Controls With Description</h3>
      <p>*specify the name of the attributes separated by commas</p>
      <Row>
        <Group>
          <Input
            id="controlsWithDescription"
            name="controlsWithDescription"
            value={controlsWithDescription}
            onChange={(e) => setControlsWithDescription(e.target.value)}
          />
        </Group>
      </Row>
    </>
  );
}

export default ProductConfiguration;
