import styled from "styled-components";
import charts from '../../../assets/charts.svg';
import play from '../../../assets/play-icon.svg';
import map from '../../../assets/map.png';

export const Container = styled.div<{isFirstPage: boolean}>`
  position: relative;
  min-height: ${props => props.isFirstPage ? '900px' : 'calc(100vh - 242px)'};
  width: 1205px;
  max-width: 100%;
  margin: 0 auto;
  padding: ${props => props.isFirstPage ? '80px 15px' : '60px 15px'};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${props => props.isFirstPage && `
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: url(${map}) no-repeat;
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: auto 100%;
    }
  `}
  
  @media (min-width: 1921px) {
    width: 1600px;
  }
  @media (max-width: 767px) {
    padding: 40px 15px;
    min-height: auto;
  }
`;
export const WidgetBlock = styled.div<{isFirstPage: boolean}>`
  max-width: 100%;
  margin: 0 auto;

  .ix-spinner::before {
    background: rgba(255, 255, 255, 0.5);
  }

  .ix-title.centered {
    text-align: center !important;
  }
  div.ix-content span {
    text-align: left;
    width: 100%;
  }
  .ix-imageHeader {
    height: 80px;
    &:before {
      opacity: 0;
    }
    span {
      color: #6266EA;
    }
  }
  .header {
    padding: 10px 0;
    box-sizing: border-box;
    height: 80px;
  }
  .control {
    margin-bottom: 20px;
  }
  .horizontal-layout {
    width: calc(100% + 32px);
    margin-left: -32px;
  }
  .horizontal-layout-2 {
    padding-left: 32px;
    box-sizing: border-box;
  }
  label {
    font-size: 15px;
  }

  ${props => props.isFirstPage ? `

  //------------------------------ 1st PAGE (WidgetBlock) -------------------------------------
  width: 775px;
  .ix-title {
    text-align: center;
  }
  .background {
    display: none;
  }
  //------------------------------ MEDIA QUERIES for 1st page
  @media (min-width: 1921px) {
    width: 100%;
  }
  @media (min-width: 768px) and (max-width: 1919px) {
    .ix-logo{
      display: block;
      max-height: 70px;
      max-width: 210px;
    }
    .ix-title-div {
      margin-top: 107px;
    }
    .ix-form-div {
      margin-top: 24px;
    }
    .ix-submit-button-div {
      margin-top: 40px;
    }
    .ix-title {
      text-align: center;
      font-size: 52px;
      line-height: 64px;
    }
  }
  @media (min-width: 481px) and (max-width: 767px) {
    .ix-title-div {
      margin-top: 24px;
    }
    .ix-form-div {
      margin-top: 24px;
    }
    .ix-submit-button-div {
      margin-top: 24px;
    }
    .ix-title {
      font-size: 32px;
      line-height: 48px;
    }
  }
  @media (max-width: 480px) {
  }
  //------------------------------

  ` : `
  
  //------------------------------ PAGES except 1st (WidgetBlock) -------------------------------------
  width: 1032px;
  //------------------------------ MEDIA QUERIES for pages except 1st
  @media (min-width: 1921px) {
    width: 100%;
  }
  @media (min-width: 768px) and (max-width: 1919px) {
    .ix-title-div {
      margin-top: 24px;
    }
    .ix-form-div {
      margin-top: 24px;
    }
    .ix-submit-button-div {
      margin-top: 24px;
    }
  }
  //------------------------------

  `};
`;

export const Text = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #2B2C41;
  margin: 0 0 20px;

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: initial;
  }
`;
export const Title = styled.h2`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 52px;
  text-align: center;
  color: #010101;
  margin: 0 0 40px;
  position: relative;
  span {
    color: #6266EA;
  }

  @media (max-width: 767px) {
    font-size: 36px;
    line-height: initial;
  }
`;
export const Button = styled.button`
  background: #6266EA;
  box-shadow: 10px 10px 40px rgba(111, 138, 231, 0.25);
  border-radius: 100px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 2px;
  color: #FFFFFF;
  margin: 0 auto 36px;
  padding: 16px 38px;
  display: flex;
  border: none;
  cursor: pointer;
  img {
    margin-left: 12px;
  }
`;
export const Link = styled.a`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 2px;
  color: #6266EA;
  text-decoration: none;
  display: inline-flex;
  img {
    margin-left: 12px;
  }

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: initial;
  }
`;
export const ItemTitle = styled.h4`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #010101;
  margin: 0 0 10px;
  
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: initial;
  }
`;
export const Description = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #2B2C41;
  margin: 0;

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 18px;
  }
`;
export const DarkContainer = styled.div`
  padding: 89px 100px;
  width: 1004px;
  max-width: 100%;
  background: #F2F2FC;
  border-radius: 20px;
  box-sizing: border-box;
  margin: 120px auto;
  text-align: center;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    background: url(${charts}) no-repeat;
    height: 302px;
    width: 773px;
    max-width: 100%;
    background-position: center;
    background-size: auto 100%;
    transform: translate(-50%, -50%);
  }
  .ix-title {
    margin-bottom: 20px;
  }

  @media (max-width: 767px) {
    padding: 60px 20px;
    margin: 60px auto;
    &:before {
      height: 151px;
      width: 100%;
    }
  }
`;
export const VideoContainer = styled.div`
  width: 1264px;
  max-width: 100%;
  margin: 120px auto 240px;
  @media (max-width: 767px) {
    margin: 60px auto 120px;
  }
`;
export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 60px;

  @media (max-width: 992px) {
    flex-direction: column;
    padding: 0 20px;
  }
`;
export const Item = styled.div`
  display: flex;
  margin-bottom: 70px;
  max-width: 486px;
  &:last-child {
    margin-bottom: 0;
  }
  img {
    margin-right: 20px;
    width: 100px;
  }
  @media (max-width: 992px) {
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 40px;
    }
    img {
      margin-right: 15px;
      width: 80px;
      height: 80px;
    }
  }
`;
export const VideoBlock = styled.div`
  position: relative;
  border-radius: 24px;
  overflow: hidden;
  margin-left: 32px;
  max-width: 616px;
  &:before {
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #6266EA;
    opacity: 0.75;
    position: absolute;
    z-index: 1;
  }
  video {
    transform: translateX(-50%);
    position: relative;
    left: 50%;
  }
  @media (max-width: 992px) {
    margin: auto;
    video {
      height: 360px;
    }
  }
`;
export const PlayButton = styled.button`
  background: url(${play}) no-repeat;
  width: 70px;
  height: 70px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  z-index: 1;
`;
export const List = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #383874;
  img {
    margin-right: 5px;
  }
  li {
    margin: 0 12px;
    display: flex;
  }
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;
export const WorkItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 992px) {
    align-items: center;
  }
`;
