import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage, Header, InputField, Label, Loading, SubmitButton, Wrapper } from './styles';
import { Checkbox, CircularProgress, FormControlLabel, Grid } from '@mui/material';
import logo from '../../assets/ryder/logo-ryder.svg';
import { useNavigate } from 'react-router-dom';

type FormInputs = {
	ownerName: string;
	businessName: string;
	phoneNumber: string;
	email: string;
	dot?: string;
	totalPowerUnits: string;
	comments: string;
	terms: boolean;
};
const FirstStep = () => {
	const navigate = useNavigate()
	const [isLoading, setIsLoading] = useState(false);
	const { register, setValue, clearErrors, control, formState: { errors }, handleSubmit } = useForm<FormInputs>();
	const onSubmit = (data: FormInputs) => {
		setIsLoading(true);
		setTimeout(() => {
			setIsLoading(false);
			navigate('step2')
		}, 2500)
	};
	const formatPhoneNumber = (value: string) => {
		if (!value) return value;
		const phoneNumber = value.replace(/[^\d]/g, '');
		const phoneNumberLength = phoneNumber.length;
		if (phoneNumberLength < 4) {
			return phoneNumber;
		}
		if (phoneNumberLength < 7) {
			return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`
		}
		return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
	}
	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.name as keyof FormInputs, formatPhoneNumber(e.target.value));
		clearErrors('phoneNumber');
	};
	const label = (
		<span style={{ fontSize: '12px', fontFamily: "Roboto Condensed,Arial,sans-serif"  }}>
			I have read and agree to the <a href="/terms" target="_blank" rel="noreferrer" style={{
			color: '#ce1126',
			fontWeight: 700,
			textDecoration: 'none'
		}}>Terms of Use</a> and <a href="/privacy" target="_blank" rel="noreferrer"
			                         style={{ color: '#ce1126', fontWeight: 700, textDecoration: 'none' }}>Privacy Policy</a>. Additionally, I authorize you to obtain a copy of any Motor Vehicle Report for rating /underwriting purposes and information from other sources, such as a credit report.*
			</span>
	)
	return (
		<div style={{ position: 'relative', height: '100%' }}>
			{isLoading && <Loading><CircularProgress sx={{color: '#ce1126'}}/></Loading>}
			<form onSubmit={handleSubmit(onSubmit)}>
				<Header>
					<div><img src={logo} alt="Ryder Logo" width={150}/></div>
					<div>Powered by REIN</div>
					<div>Let's get to know you better!</div>
				</Header>
				<Wrapper>
					<Grid container spacing={2}>
						<Grid item md={12} sm={12} xs={12}>
							<Label htmlFor="name">Owner Name</Label>
							<InputField id="ownerName" {...register("ownerName", {
								required: { value: true, message: "This field is required" },
							})} error={!!errors.ownerName}/>
							{errors.ownerName && <ErrorMessage>{errors.ownerName.message}</ErrorMessage>}
						</Grid>
						<Grid item md={12} sm={12} xs={12}>
							<Label htmlFor="businessName">Business Name or DBA</Label>
							<InputField id="businessName" {...register("businessName", {
								required: { value: true, message: "This field is required" },
							})} error={!!errors.businessName}/>
							{errors.businessName && <ErrorMessage>{errors.businessName.message}</ErrorMessage>}
						</Grid>
						<Grid item md={6} sm={12} xs={12}>
							<Label htmlFor="phoneNumber">Phone Number</Label>
							<InputField id="phoneNumber" {...register("phoneNumber", {
								pattern: {value: /^[0-9]{3}[-][0-9]{3}[-][0-9]{4}$/i, message: 'Invalid phone number'},
								required: { value: true, message: "This field is required" },
							})} error={!!errors.phoneNumber} onChange={(e) => onInputChange(e)}/>
							{errors.phoneNumber && <ErrorMessage>{errors.phoneNumber.message}</ErrorMessage>}
						</Grid>
						<Grid item md={6} sm={12} xs={12}>
							<Label htmlFor="email">Email</Label>
							<InputField id="email" {...register("email", {
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
									message: "Invalid email address"
								},
								required: { value: true, message: "This field is required" },
							})} error={!!errors.email}/>
							{errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}
						</Grid>
						<Grid item md={6} sm={12} xs={12}>
							<Label htmlFor="dot">DOT (optional)</Label>
							<InputField id="dot" {...register("dot")} />
						</Grid>
						<Grid item md={6} sm={12} xs={12}>
							<Label htmlFor="totalPowerUnits">Total Power Units</Label>
							<InputField id="totalPowerUnits" {...register("totalPowerUnits", {
								required: { value: true, message: "This field is required" },
							})} error={!!errors.totalPowerUnits}/>
							{errors.totalPowerUnits && <ErrorMessage>{errors.totalPowerUnits.message}</ErrorMessage>}
						</Grid>
						<Grid item md={12} sm={12} xs={12}>
							<Label htmlFor="comments">Additional Info/Comments</Label>
							<InputField id="comments" {...register("comments", {
								required: { value: true, message: "This field is required" },
							})} error={!!errors.comments}/>
							{errors.comments && <ErrorMessage>{errors.comments.message}</ErrorMessage>}
						</Grid>
						<Grid item md={12} sm={12} xs={12}>
							<Controller
								control={control}
								name="terms"
								rules={{	required: { value: true, message: "Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy" }}}
								render={({ field: { onChange, value }, fieldState: { error } }) => (
									<FormControlLabel
										sx={{
											alignItems: 'flex-start',
											margin: 0,
											padding: '5px',
											backgroundColor: errors.terms ? '#fff7f8' : 'transparent',
											lineHeight: 1,
											'& .MuiCheckbox-root': {
												padding: 0,
												marginTop: '3px'
											}
										}}
										control={
											<Checkbox
												sx={{
													color: '#ce1126', '&.Mui-checked': {
														color: '#ce1126',
													}
												}}
												checked={value}
												onChange={(e) => onChange(e.target.checked)}
											/>
										}
										label={label}
									/>
								)}
							/>
							{errors.terms && <ErrorMessage>{errors.terms.message}</ErrorMessage>}
						</Grid>
						<Grid item md={12} sm={12} xs={12}>
							<SubmitButton type="submit">Submit</SubmitButton>
						</Grid>
					</Grid>
				</Wrapper>
			</form>
		</div>
	)
}

export default FirstStep
