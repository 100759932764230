import React, { useContext, useState } from 'react'
import { Group, Input, Label, RemoveButton, SaveButton } from '../../styles';
import {
	Accordion,
	AccordionItem,
	AccordionItemButton,
	AccordionItemHeading,
	AccordionItemPanel
} from 'react-accessible-accordion';
import { ConfigurationContext } from '../..';
import { ErrorMessages, ErrorMessagesFields } from '../../types';
import ValueFields from './ValueFields';
import { StyledDiv } from './styles';


const ErrorsConfig = () => {
	const {
		errorMessages,
		setErrorMessages,
	}: any = useContext(ConfigurationContext);
	const [errorFieldName, setErrorFieldName] = useState('');
	const handleAddStep = () => {
		if(!errorFieldName) return
		setErrorMessages((prevMessages: ErrorMessages) => ({
			...prevMessages,
			[errorFieldName]: {
				custom: '',
				required: ''
			},
		}));
	};
	const handleChange = (item: string, value: ErrorMessagesFields) => {
		setErrorMessages((prevMessages: ErrorMessages) => ({
			...prevMessages,
			[item]: value
		}));
	}
	const removeItem = (item: string) => {
		setErrorMessages((prevMessages: ErrorMessages) => {
			return Object.keys(prevMessages).reduce<ErrorMessages>((accum, errorName) => {
				if(item !== errorName) {
					accum[errorName] = prevMessages[errorName]
				}
				return accum
			}, {})
		});
	}
	return (
		<>
			<h3>Error Messages Configuration</h3>
			<Group>
				<Label>Attribute Path</Label>
				<Input
					id="errorMessageField_Name"
					name="errorMessageField_Name"
					value={errorFieldName}
					onChange={(e) => setErrorFieldName(e.target.value)}
				/>
				<SaveButton onClick={handleAddStep}>Add Error Config</SaveButton>
			</Group>
			<Accordion allowZeroExpanded={true}>
				{errorMessages && Object.keys(errorMessages).map(item => {
					return <AccordionItem key={item} className="accordionPaddingZero">
						<AccordionItemHeading>
							<AccordionItemButton style={{display: 'flex', alignItems: 'baseline'}}>
								<StyledDiv>
									<span>{item}</span>
									<RemoveButton onClick={() => removeItem(item)}>Remove</RemoveButton>
								</StyledDiv>
							</AccordionItemButton>
						</AccordionItemHeading>
						<AccordionItemPanel className="accordionContent">
							<ValueFields value={errorMessages[item]} onChange={(value) => handleChange(item, value)}/>
						</AccordionItemPanel>
					</AccordionItem>
				})}
			</Accordion>
		</>
	)
}

export default ErrorsConfig
