import React, { useEffect, useState } from 'react';
import { Routes, Route } from "react-router-dom";
import FirstTemplate from './components/FirstTemplate';
import SecondTemplate from './components/SecondTemplate';
import ThirdTemplate from './components/ThirdTemplate';
import Weather from './components/Weather';
import Liberty from './components/Liberty';
import FullSizeWidget from './components/FullSizeWidget';
import SmallSizeWidget from './components/SmallSizeWidget';
import ConfigurationPage from './components/ConfigurationPage';
import Ryder from './components/Ryder';
import Instagram from './components/Instagram';
import VerticalSoftware from './components/VerticalSoftware';
import { WIDGET_PATH } from './config';
import PortalWidget from './components/Portal';
import RyderWidget from './components/RyderWidget';
import FirstStep from './components/RyderWidget/FirstStep';
import SecondStep from './components/RyderWidget/SecondStep';
import ThirdStep from './components/RyderWidget/ThirdStep';

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const script: any = document.createElement('script');
    script.src = WIDGET_PATH;
    script.async = true;

    script.onload = () => {
      console.log('Script loaded successfuly');
      setLoading(false);
    };

    script.onerror = () => {
      console.log('Error occurred while loading script');
    };

    document.body.appendChild(script);
  }, []);

  return loading ? <div>Loading...</div> : (
    <Routes>
      <Route index element={<FirstTemplate />} />
      <Route path="template2" element={<SecondTemplate />} />
      <Route path="template3" element={<ThirdTemplate />} />
      <Route path="weather" element={<Weather />} />
      <Route path="liberty" element={<Liberty />} />
      <Route path="portal" element={<PortalWidget />} />
      <Route path="fullSizeWidget" element={<FullSizeWidget />} />
      <Route path="smallSizeWidget" element={<SmallSizeWidget />} />
      <Route path="configuration" element={<ConfigurationPage />} />
      <Route path="ryder" element={<Ryder />} />
	    <Route path="ryder-widget" element={<RyderWidget />} >
		    <Route index element={<FirstStep />} />
		    <Route path="step2" element={<SecondStep />} />
		    <Route path="step3" element={<ThirdStep />} />
	    </Route>
      <Route path="instagram" element={<Instagram />} />
      <Route path="instagramLiberty" element={<Instagram liberty={true} />} />
      <Route path="verticalSoftware" element={<VerticalSoftware />} />
    </Routes>
  );
}

export default App;
