import React from 'react'
import { Grid, Link, Typography } from '@mui/material';
import { Header, Wrapper } from './styles';
import logo from '../../assets/ryder/logo-ryder-white.png';

const ThirdStep = () => {
	return (
		<div>
			<Header>
				<div><img src={logo} alt="Ryder Logo"/></div>
				<Typography variant={"h3"} align={"center"} fontSize={32} sx={{ mb: 0 }} fontFamily="Roboto Condensed">Thanks for choosing
					Ryder!</Typography>
			</Header>
			<Wrapper>
				<Grid container justifyContent="center" spacing={1}>
					<Grid item md={12}>
						<Typography variant={"subtitle2"} fontWeight={400} fontFamily="Roboto Condensed">
							You can officially cross renters insurance and phone care off your checklist.
							And we’ll automatically remove your policy - so that’s even less to worry about.
							We sent a receipt with details on your coverage selections and what you paid today
							to <Link href={"Testmail@test.com"} fontWeight={700}>{"ryder@ryder.com"}</Link>.
							Didn’t receive it? <Link href="#" color="#ce1126" fontWeight={700} underline="hover">
							Click here to resend the email
						</Link>.
							You’ll also see your documents in your inbox within 24 to 48 hours.
						</Typography>
					</Grid>
				</Grid>
			</Wrapper>
		</div>
	)
}

export default ThirdStep
