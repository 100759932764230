import React from 'react'
import { Group, Input, Label } from '../../styles'
import { ErrorMessagesFields } from '../../types';
interface Props {
	value: {
		custom?: string,
		required?: string
	},
	onChange: (value: ErrorMessagesFields) => void
}
const ValueFields = ({value, onChange}: Props) => {
	return (
		<>
			<Group>
				<Label>Required</Label>
				<Input
					id="errorMessageField_Required"
					name="errorMessageField_Required"
					value={value.required}
					onChange={(e) => onChange({custom: value.custom, required: e.target.value})}
				/>
			</Group>
			<Group>
				<Label>Custom</Label>
				<Input
					id="errorMessageField_Custom"
					name="errorMessageField_Custom"
					value={value.custom}
					onChange={(e) => onChange({required: value.required, custom: e.target.value})}
				/>
			</Group>
		</>
	)
}

export default ValueFields
