import React, {useEffect, useState} from 'react';
import { Container } from './styles';
import { fullSizeConfig } from './config';

function FullSizeWidget() {
  const [config, setConfig] = useState<any>({});
  useEffect(() => {
    let savedData = localStorage.getItem('config');
    if (savedData) {
      setConfig(JSON.parse(savedData));
    } else {
      setConfig(fullSizeConfig);
    }
  }, []);

  useEffect(() => {
    if(config.productId) {
      window.IXWidget('ix-fullSize-widget', {
        ...config,
      });
    }
  }, [config]);

  return (
    <Container>
      <div id="ix-fullSize-widget"></div>
    </Container>
  );
}

export default FullSizeWidget;
