import React, { useEffect, useState } from "react";
import logo from "../../assets/logo.svg";
import { Main, Section, Container, WidgetDiv } from "./styles";
import Footer from "./Footer";

import { CLIENT_ID, PRODUCT_ID_CONFIANZA } from "../../config";

declare global {
  interface Window {
    IXWidget: any;
  }
}

function FirstTemplate() {
  const [isFirstPage, setIsFirstPage] = useState<boolean>(true);
  const next = () => {
    setIsFirstPage(false);
  };
  const prev = () => {
    setIsFirstPage(true);
  };

  useEffect(() => {
    window.addEventListener("movedToFirstScreen", prev);
    window.addEventListener("onFirstScreenSubmit", next);
    return () => {
      window.removeEventListener("movedToFirstScreen", prev);
      window.removeEventListener("onFirstScreenSubmit", next);
    };
  }, []);

  useEffect(() => {
    window.IXWidget("ix-widget", {
      clientId: CLIENT_ID,
      productId: PRODUCT_ID_CONFIANZA,
      isMicroSite: true,
      theme: "default",
      labelPlacement: "top",
      logo: {
        src: logo,
      },
      FirstStage: {
        description: {
          visible: false,
        },
        button: {
          content: "Start my quote",
        },
        styles: {
          components: {
            container: {
              background: "transparent",
            },
            headings: {
              color: "#010101",
            },
          },
        },
      },
      loader: {
        fullscreen: true,
        blurred: true,
        color: '#ffffff',
      },
      styles: {
        components: {
          container: {
            background: "transparent",
          },
          headings: {
            color: "#010101",
          },
          paragraphs: {
            fontSize: '16px',
          },
          input: {
            fontSize: '20px',
          }
        },
      },
    });
  }, []);

  return (
    <Main>
      <Section isFirstPage={isFirstPage}>
        <Container isFirstPage={isFirstPage}>
          <WidgetDiv isFirstPage={isFirstPage} id="ix-widget" />
        </Container>
      </Section>
      <Footer />
    </Main>
  );
}

export default FirstTemplate;
