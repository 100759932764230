import React, {useEffect, useState} from 'react';
import { Container } from './styles';
import { fullSizeConfig } from './config';

function PortalWidget() {
  const [config, setConfig] = useState<any>({});
  useEffect(() => {
    let savedData = localStorage.getItem('config');
    if (savedData) {
      setConfig(JSON.parse(savedData));
    } else {
      setConfig(fullSizeConfig);
    }
  }, []);

  useEffect(() => {
    if(config.productId) {
      window.IXWidget('ix-fullSize-widget', {
          widgetId: 'e624665b-ee41-43a8-0d42-08db62880a63',
          clientId: '877e3053-0bb4-447f-a6b0-16823cbb3400',
          productId: 'dacee33e-86a6-4285-b869-a165ce622eab',
      })
    }
  }, [config]);

  return (
    <Container>
      <div id="ix-fullSize-widget"></div>
    </Container>
  );
}

export default PortalWidget;
