import styled from "styled-components";
import homepage from '../../assets/ryder/homepage_custom_graphic_hero.jpeg';
import homepage_mobile from '../../assets/ryder/citystreet_mobile.jpg';
import search from '../../assets/ryder/search.svg';

export const Main = styled.main`
    font-family: "Roboto Condensed",Arial,sans-serif;
    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
            color: #ce1225;
        }
    }

    #ryder-widget {
        min-height: 640px;
        .ix-title {
            font-size: 22px !important;
            font-weight: 900 !important;
            padding-bottom: 0 !important;
            margin: 0 !important;
        }
        .ix-logo {
            display: none;
        }
        .p3 {
            font-size: 14px;
            line-height: 22px;
            margin: 0;
        }
        .MuiTypography-p3 {
            font-size: 14px;
            line-height: 22px;
            margin: 0;
        }
        .ix-container {
            padding: 0;
            border-radius: 0;
        }
        .ix-spinner{
            border-radius: 0;
            &:before {
                border-radius: 0;
            }
        }
        .ix-submit-button-div {
            margin-top: 10px;
            &>div {
                justify-content: left;
            }
        }
        .MuiButton-root {
            padding: 0 15px !important;
            border-radius: 4px;
            font-size: 16px !important;
            font-family: "Roboto Condensed",Arial,sans-serift !important;
            font-weight: 700;
            width: auto;
            line-height: 21px !important;
        }
        .ix-form-div {
            margin-top: 10px;
            input, select {
                text-transform: uppercase;
                font-weight: 700;
                font-size: 15px !important;
                line-height: 24px !important;
            }
            .horizontal-layout-item {
                margin-bottom: 5px;
            }
        }
        .ix-spinner {
            p {
                color: #fff;
            }
        }
        .header {
            span {
                color: rgb(206, 18, 37);
            }
            .MuiIconButton-root {
                left: 0;
            }
        }
        .ix-imageHeader {
            height: auto;
            margin: 0;
            padding: 0;
            overflow: visible;
            &:before, span {
                display: none;
            }
            .background {
                top: 0;
                left: 0;
                transform: none;
                position: relative;
            }
        }
        .ix-heroImage{
            height: auto;
            margin: 0;
            overflow: initial;
            img {
                position: initial;
                transform: initial;
                height: auto;
            }
        }
    }
`;
export const Header = styled.header`
    background: #ffffff;
    height: auto;
    box-shadow: 0 0 1.0625rem 0 rgba(0,0,0,.1);
    position: sticky;
    top: 0;
    z-index: 100;
`;
export const HeaderContainer = styled.div`
    max-width: 100%;
    padding-left: 5%;
    padding-right: 5%;
`;
export const Container = styled.div`
    width: 1170px;
    margin: auto;
    max-width: 100%
`;

export const Contacts = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 15px 0 10px;
    border-bottom: 1px solid #d2d2d2;
    a {
        font-family: "Roboto Condensed",Arial,sans-serif;
        font-weight: 400;
        font-size: 16px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: .6px;
        color: #595959;
        text-decoration: none;
    }
    .col-8 {
        width: 66.66666667%;
    }
    .col-4 {
        width: 33.33333333%;
        display: flex;
        justify-content: space-between;
    }
    @media (max-width: 768px) {
        display: none;
    }
`;
export const Nav = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    div{
        display: flex;
        align-items: center;
    }
    ul {
        margin: auto;
        padding: 15px 0;
        list-style: none;
        display: flex;
        align-items: center;
    }
    a {
        color: #6b717b;
        font-family: "Roboto Condensed",Arial,sans-serif;
        font-weight: 600;
        font-size: 16px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: .6px;
        margin-right: 1rem;
        margin-left: 1rem;
        text-decoration: none;
        display: block;
    }

    @media (max-width: 768px) {
        ul {
            display: none;
        }
    }
`;
export const TitleBlock = styled.section`
    background-image: url(${homepage});
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    margin-bottom: 70px;

    @media (max-width: 992px) {
        background-size: 125%;
    }
    @media (max-width: 768px) {
        background-image: url(${homepage_mobile});
        background-size: contain;
    }
`;
export const Title = styled.h1`
    font-size: 56px;
    margin-bottom: 1.5rem;
    margin-top: 4rem;
    font-family: Roboto,Arial,sans-serif;
    font-weight: 900;
    text-align: center;
    line-height: 1.04;
    letter-spacing: .01875rem;
    color: #fff;
    padding: 0 1em;
    position: relative;
    z-index: 15;
    text-shadow: 0 0.125rem 2.75rem rgb(0 0 0 / 45%);
    @media (max-width: 768px) {
        font-size: 42px;
        margin-top: 8.75rem;
    }
`;
export const SearchContaner = styled.div`
    max-width: 100%;
    padding: 2em 6.8em 3.65em;
    text-align: center;
    margin: 74px auto 44px;
    border-radius: 0.25rem;
    background-color: #fff;
    width: 69%;
    box-shadow: 1px 2px 20px 8px rgb(0 0 0 / 10%);

    h3 {
        text-align: center;
        font-size: 21px;
        line-height: 1.13;
        letter-spacing: .01875rem;
        text-align: center;
        color: #000;
        font-family: "Roboto Condensed",Arial,sans-serif;
        font-weight: 700;
    }
    form{
        border-radius: 0.25rem;
        border: solid 0.0625rem #949494;
        background-color: #fff;
        display: flex;
        margin-top: 2.2em;
    }
    input {
        font-size: 13px;
        line-height: 1.13;
        letter-spacing: .01875rem;
        font-family: "Roboto Condensed",Arial,sans-serif;
        font-weight: 700;
        border: none;
        width: 100%;
        padding: 17px 2%;
        border-radius: 0.25rem;
    }

    @media (max-width: 768px) {
        padding-left: 15px;
        padding-right: 15px;
        h3 span {
            display: none;
        }
    }
`;
export const SubmitButton = styled.button`
    float: right;
    width: 16.56rem;
    height: 4.125rem;
    background-color: #ce1225;
    border-radius: 0 4px 4px 0;
    border: solid 1px #ce1225;
    font-size: 1.25rem;
    line-height: 1.5;
    letter-spacing: .0375rem;
    text-align: center;
    color: #fff;
    font-family: "Roboto Condensed",Arial,sans-serif;
    font-weight: 700;
    white-space: nowrap;
    display: inline-block;
    cursor: pointer;

    @media (max-width: 992px) {
        font-size: 0;
        width: auto;
        padding: 5px 20px;
        &:before {
            display: block;
            content: url(${search});
        }
    }
`;
export const Subtitle = styled.h2`
    text-align: center;
    border-bottom: none;
    font-family: Roboto,Arial,sans-serif;
    font-weight: 900;
    font-size: 32px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: .3px;
    color: #000;
    padding-bottom: 20px;
    margin: 0;
`;
export const TruckTypes = styled.ul`
    margin: 0 auto 100px;
    list-style: none;
    display: flex;
    padding: 0;
    justify-content: center;
    align-items: baseline;
    li {
        padding-left: 18.5px;
        padding-right: 18.5px;
    }
    p {
        font-family: "Roboto Condensed",Arial,sans-serif;
        font-weight: 700;
        font-size: 18px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.11;
        letter-spacing: .72px;
        text-align: center;
        color: #000;
        padding-left: 0;
        position: unset;
        margin: 15px 0 0;
    }
    img {
        max-width: 100%;
    }
    @media (max-width: 992px) {
        flex-wrap: wrap;
        li {
            width: 50%;
            box-sizing: border-box;
            text-align: center;
            padding-bottom: 2em;
        }
    }
`;
export const Promotions = styled.div`
    display: flex;
    margin-bottom: 60px;
    .promotions_title {
        color: #6b717b;
        font-family: "Roboto Condensed",Arial,sans-serif;
        font-size: 22px;
        font-weight: 900;
        padding-bottom: 0;
        padding-top: 15px;
        margin: 0
    }
    p {
        font-size: 14px;
        color: #6b717b;
        font-family: "Roboto Condensed",Arial,sans-serif;
        line-height: 22px;
        margin: 0
    }
    & > div {
        padding-left: 18px;
        padding-right: 18px;
        flex: 1
    }
    img {
        width: 100%
    }

    @media (max-width: 992px) {
        flex-wrap: wrap;
        & > div {
            width: 50%;
            box-sizing: border-box;
            padding-bottom: 2em;
            flex: auto;
        }
    }

    @media (max-width: 768px) {
        & > div {
            width: 100%;
        }
    }
`;
export const PromotionsLink = styled.a`
    font-family: Roboto,Arial,sans-serif;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #000;
    text-decoration: underline;
    font-size: 1em;
    display: block;
    margin: 0 auto 20px;
    width: fit-content;
`;
export const LearnMore = styled.button`
    color: #ffffff;
    font-weight: 700;
    text-decoration: none;
    font-size: 16px;
    font-family: "Roboto Condensed",Arial,sans-serift;
    background-color: #ce1225;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 4px;
    margin-top: 16px;
    text-transform: uppercase;
    border: none;
`;
export const BrandsTitle = styled.h3`
    border-bottom: none;
    font-family: Roboto,Arial,sans-serif;
    font-weight: 900;
    font-size: 32px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: .3px;
    color: #000;
    padding-bottom: 20px;
    text-align: center;
    margin: 0;
`;
export const Brands = styled.ul`
    display: flex;
    list-style: none;
    justify-content: center;
    align-items: baseline;
    margin-bottom: 60px;
    margin-top: 0;
    padding: 0;
    li {
        text-align: center;
        padding-left: 18.5px;
        padding-right: 18.5px;
        a {
            font-family: "Roboto Condensed",Arial,sans-serif;
            font-weight: 700;
            font-size: 18px;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.11;
            letter-spacing: .72px;
            text-align: center;
            color: #000;
            padding-left: 0;
            text-decoration: none;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        span {
            margin-top: 15px;
            display: block;
        }
    }

    @media (max-width: 992px) {
        flex-wrap: wrap;
        li {
            width: 50%;
            box-sizing: border-box;
            padding-bottom: 2em;
        }
    }
`;
export const Vehicles = styled.div`
    display: flex;
    padding-bottom: 100px;
    position: relative;
    @media (max-width: 992px) {
        margin: 0 70px;
    }

    @media (max-width: 768px) {
        &>div:first-child, &>div:nth-child(2) {
            display: none;
        }
    }
`;
export const Description = styled.p`
    font-family: "Roboto Condensed",Arial,sans-serif;
    font-weight: 400;
    margin: 0 auto 100px;
    text-align: center;
    line-height: 24px;
    font-size: 16px;
    letter-spacing: .06em;
    color: #6b717b;
`;
export const Vehicle = styled.div`
    padding: 0 20px;
    img {
        width: 100%;
        margin-bottom: 1em;
    }
    h4 {
        font-family: "Roboto Condensed",Arial,sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 1.25em;
        color: #000;
        padding-bottom: 0.8em;
        margin: 0
    }
    div {
        display: flex;
    }
    p {
        margin: 0;
    }
    .price {
        border-bottom: none;
        font-family: "Roboto Condensed",Arial,sans-serif;
        font-weight: 700;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.25;
        letter-spacing: .3px;
        color: #000;
        display: inline-block;
        margin-right: 1em;
        padding-bottom: 0.8em;
        &.old {
            text-decoration: line-through;
            font-family: "Roboto Condensed",Arial,sans-serif;
            font-weight: 400;
            color: #595959;
        }
    }
    .mileage {
        font-family: "Roboto Condensed",Arial,sans-serif;
        font-weight: 400;
        color: #595959;
        display: inline-block;
        margin-right: 1em;
        font-size: 18px;
        margin-right: 1em;
        padding-bottom: 0.8em;
        span {
            font-family: "Roboto Condensed",Arial,sans-serif;
            font-weight: 400;
            color: #595959;
        }
    }
    .year {
        font-family: "Roboto Condensed",Arial,sans-serif;
        font-weight: 400;
        color: #595959;
        display: inline-block;
        margin-right: 1em;
        font-size: 18px;
        span {
            font-family: "Roboto Condensed",Arial,sans-serif;
            font-weight: 400;
            color: #595959;
        }
    }
    .verified {
        color: #ce1225;
        letter-spacing: .6px;
        font-family: "Roboto Condensed",Arial,sans-serif;
        font-weight: 400;
        font-size: 16px;
        padding-bottom: 0.8em;
        margin: 0;
    }
`;
export const VehiclesNav = styled.div`
    position: absolute;
    top: 130px;
    left: -50px;
    right: -50px;
    display: flex;
    justify-content: space-between;
    svg {
        cursor: pointer;
    }
`;
export const AvatarBlock = styled.div`
    width: 48px;
    height: 48px;
    position: fixed;
    bottom: 20px;
    left: 20px;
    background: #f5f5f5;
    box-shadow: 0 1px 2px #0000003D;
    border-radius: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursore: pointer;
    .st0 {
        fill:#6C6C70;
    }
    svg {
        width: 40px;
    }
`;
export const HelpButton = styled.div`
    bottom: 60px;
    right: 60px;
    position: fixed;
    z-index: 1;
    button {
        background-color: #ce1126;
        border: none;
        color: #ffffff;
        font-family: "Roboto Condensed",Arial,sans-serif;
        font-size: 14px;
        display: flex;
        box-shadow: 0 0 12px rgba(0,0,0,.25);
        border-radius: 20px;
        line-height: 35px;
        align-items: center;
        padding: 0px 18px;
        cursore: pointer;
    }
    img {
        width: 24px;
        margin-right: 14px;
    }

    @media (max-width: 768px) {
        display: none;
    }
`;
export const Menu = styled.button`
    display: none;
    padding: 0;
    background: #ffffff;
    border: none;
    svg {
        width: 25px;
    }
    @media (max-width: 768px) {
        display: block;
    }
`;



