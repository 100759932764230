import styled from "styled-components";

export const Footer = styled.footer`
  display: flex;
  flex-direction: row;
  padding: 0 200px;
  width: 100%;
  height: 92px;
  background: #FFFFFF;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 992px) {
    flex-direction: column;
    padding: 40px 30px;
    height: auto;
  }
`;
export const Copyright = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #010101;
  max-width: 465px;
  margin: 0;
  @media (max-width: 992px) {
    margin: 20px auto;
  }
`;
export const List = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  &.agreements li{
    margin: 0 8px;
    position: relative;
    &:first-child:before{
      content: '|';
      position: absolute;
      color: #010101;
      right: -9px;
      top: 0;
    }
  }
  &.social-links li{
    margin: 0 15px;
  }
`;
export const Link = styled.a`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #010101;
  text-decoration: none;
`;
