import styled from "styled-components";

export const Container = styled.div`
  padding: 50px 20px;
  display: flex;
`;

export const Form = styled.div`
  width: 50%;
  padding: 0 30px;
  box-sizing: border-box;
  h4 {
    text-transform: capitalize;
  }
  .accordion__button {
    box-sizing: border-box;
  }
`;

export const Group = styled.div`
  margin-bottom: 15px;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #010101;
`;

export const Input = styled.input`
  width: 100%;
  border: 1px solid #383874;
  border-radius: 6px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #383874;
  padding: 8px 14px;
  box-sizing: border-box;
`;

export const Select = styled.select`
  width: 100%;
  border: 1px solid #383874;
  border-radius: 6px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #383874;
  padding: 8px 14px;
  box-sizing: border-box;
  background: #ffffff;
`;

export const SaveButton = styled.button`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 1px;
  color: #FFFFFF;
  background: #6266EA;
  border-radius: 100px;
  padding: 12px 30px;
  border: 1px solid #6266EA;
  cursor: pointer;
  margin-right: 20px;
  margin-top: 20px;
  opacity: 1;
  &:hover {
    opacity: 0.7;
  };
  &:disabled {
    opacity: 0.5;
  }
`;

export const RemoveButton = styled.button`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 1px;
  color: #FFFFFF;
  background: #C03535FF;
  border-radius: 100px;
  padding: 6px 15px;
  border: 1px solid #C03535FF;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

export const DefaultButton = styled.button`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 1px;
  color: #6266EA;
  background: #FFFFFF;
  border: 1px solid #383874;
  border-radius: 100px;
  padding: 12px 30px;
  cursor: pointer;
  margin-top: 20px;
  &:hover {
    opacity: 0.7;
  }
`;

export const Checkbox = styled.div`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 15px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  label {
    cursor: pointer;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: #eee;
    }
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &:has(> input:checked) {
      &:before {
        background-color: #2196F3;
      }
    }
    &:has(> input:checked) {
      &:after {
        display: block;
      }
    }
  }
`;

export const Row = styled.div`
  display: flex;
  margin: 0 -5px;
  &>div{
    width: 50%;
    padding: 0 5px;
  }
`;

export const ErrorMessage = styled.div`
  color: #db0b0b
`;
