import { CLIENT_ID, PRODUCT_ID_CONFIANZA } from '../../config';

export const fullSizeConfig = {
  clientId: CLIENT_ID,
  productId: PRODUCT_ID_CONFIANZA,
  firstStage: {
    title: {
      content: 'Lock in your quote in the blink of an eye',
      visible: true
    },
    description: {
      content: 'Be sure your car is safe! Take a look at your quote just in few clicks.',
      visible: true
    },
  },
  labelPlacement: 'top',
  isMicroSite: true,
  fullSize: true,
  heroImage: {
    position: 'contain',
  },
  styles: {
    components: {
      input: {
        fontSize: '20px',
      },
    },
  },
};