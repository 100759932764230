import styled from "styled-components";
import template3_bg from '../../assets/template3_bg.svg';

export const Main = styled.main`
  display: 'flex';
  z-index: 1;
  position: relative;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  justify-content: space-between;
`;
export const Section = styled.section`
  z-index: 2;
  position: relative;
  min-height: calc(100vh - 92px);
  overflow: hidden;
  padding: 72px 200px;
  box-sizing: border-box;
  &:before {
    content: '';
    position: absolute;
    bottom: 25px;
    left: 0;
    background: #383874;
    border-radius: 0 0 100px 100px;
    width: 100%;
    height: 100%;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    background: url(${template3_bg}) no-repeat;
    height: 500px;
    width: 100%;
    background-position: center;
    background-size: auto 100%;
  }

  @media (max-width: 1500px) {
    padding: 72px 100px;
  }
  @media (max-width: 1200px) {
    padding: 72px 40px;
  }
  @media (min-width: 993px) {
    & > h1:first-child {
      display: none;
    }
  }
  @media (max-width: 992px) {
    padding: 40px 20px;
    min-height: calc(100vh - 215px);
    &:before {
      bottom: 0;
      border-radius: 0 0 54px 54px;
    }
  }
`;
export const Container = styled.div`
  z-index: 3;
  position: relative;
  box-sizing: border-box;
  display: flex;

  @media (max-width: 992px) {
    flex-direction: column-reverse;
  }
`;
export const WidgetDiv = styled.div<{isFirstPage: boolean}>`
  border-radius: 54px;
  margin: auto;
  box-sizing: border-box;

  .ix-imageHeader {
    &:before {
      opacity: 0;
    }
    span {
      color: #6266EA;
    }
  }
  .header {
    padding: 10px 0;
    box-sizing: border-box;
    height: 80px;
  }
  label {
    font-size: 15px;
  }
  input, select {
    min-height: 48px;
  }

  ${props => props.isFirstPage ? `

  //------------------------------ 1st PAGE (WidgetDiv) -------------------------------------
  width: 818px;
  max-width: 50%;
  padding: 34px;
  background: rgba(98, 102, 234, 0.25);
  
  .background {
    display: none;
  }

  .ix-container {
    box-shadow: 0px 0px 10px #6266ea;
    border-radius: 40px;
  }
  .ix-submit-button-div button {
    background: #FCA22B;
    box-shadow: 10px 10px 40px rgba(252, 162, 43, 0.25);
    font-weight: 500;
  }
  //------------------------------ MEDIA QUERIES for 1st page
  @media (max-width: 1919px) {
    .ix-submit-button-div button {
      width: 200px;
      font-size: 20px;
      line-height: 24px;
    }
    .ix-logo {
      max-height: 70px;
      max-width: 210px;
    }
    .ix-imageHeader {
      height: 80px;
    }
  }
  @media (min-width: 991px) {
    width: 100%;
    .ix-title-div {
      margin-top: 24px;
    }
    .ix-form-div {
      margin-top: 24px;
    }
    .ix-submit-button-div {
      margin-top: 24px;
    }
    .ix-container {
      padding: 50px;
      box-shadow: 0px 0px 10px #6266ea;
      border-radius: 40px;
    }
  }
  @media (max-width: 992px) {
    max-width: 100%;
    padding: 0;
  }
  @media (min-width: 481px) and (max-width: 767px) {
  }
  @media (max-width: 480px) {
  }
  //------------------------------

  ` : `

  //------------------------------ PAGES except 1st (WidgetDiv) -------------------------------------
  width: 1007px;
  max-width: 100%;
  background: #ffffff;

  //------------------------------ MEDIA QUERIES for pages except 1st
  @media (min-width: 1921px) {
    width: 1600px;
  }
  @media (max-width: 1919px) {
    .ix-title {
      inline-size: 450px;
    }
    .ix-title-div {
      margin-top: 24px;
    }
    .ix-form-div {
      margin-top: 24px;
    }
    .ix-submit-button-div {
      margin-top: 24px;
    }
  }
  @media (min-width: 768px) {
    padding: 34px 114px;
  }
  @media (min-width: 481px) and (max-width: 767px) {
  }
  @media (max-width: 480px) {
  }
  //------------------------------

  `};
`;

export const DescriptionDiv = styled.div`
  max-width: 50%;
  padding-right: 83px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 992px) {
    max-width: 100%;
    padding: 0;
    h1 {
      display: none;
    }
  }
`;
export const List = styled.ul`
  list-style: none;
  display: flex;
  justify-content: start;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  padding: 0;
  img {
    margin-right: 5px;
  }
  li {
    margin: 0 12px;
    display: flex;
  }

  @media (max-width: 992px) {
    flex-direction: column;
  }
`;
export const Title = styled.h1`
  position: relative;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 64px;
  color: #FFFFFF;
  margin-bottom: 24px;
  margin-top: 0;

  @media (max-width: 992px) {
    font-size: 36px;
    line-height: initial;
  }
`;
export const Description = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 44px;
  color: #FFFFFF;
  margin-bottom: 48px;

  @media (max-width: 992px) {
    font-size: 18px;
    line-height: initial;
    margin-bottom: 24px;
  }
`;
