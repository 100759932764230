import React from 'react';

import {Group, Input, Label} from '../styles';
import ColorPicker from "../components/ColorPicker/ColorPicker";
import {Title, MainTitle} from "../StagesConfiguration/styles";

interface ProgressBarStylesProps {
  setComponents: (data: any) => void
  components: any
}

function ProgressBarStyles({setComponents, components}: Readonly<ProgressBarStylesProps>) {
  return (
    <div>
      <MainTitle>Progress Bar</MainTitle>
      <Title>Text Styles</Title>
      <div>
        <Group>
          <Label>Color</Label>
          <ColorPicker color={components?.progressBar?.text?.color || '#ffffff'}
                      onChange={(color) => setComponents({
                        ...components,
                        progressBar: {...components?.progressBar, text: {...components?.progressBar?.text, color: color.hex}}
                      })}/>
        </Group>
        <Group>
          <Label>Font Family</Label>
          <Input
            id="progressBar_text_fontFamily"
            name="progressBar_text_fontFamily"
            value={components?.progressBar?.text?.fontFamily}
            onChange={(e) => setComponents({
              ...components,
              progressBar: {...components?.progressBar, text: {...components?.progressBar?.text, fontFamily: e.target.value}}
            })}/>
        </Group>
        <Group>
          <Label>Font Size</Label>
          <Input
            id="progressBar_text_fontSize"
            name="progressBar_text_fontSize"
            value={components?.progressBar?.text?.fontSize}
            onChange={(e) => setComponents({
              ...components,
              progressBar: {...components?.progressBar, text: {...components?.progressBar?.text, fontSize: e.target.value}}
            })}/>
        </Group>
        <Group>
          <Label>Line Height</Label>
          <Input
            id="progressBar_text_lineHeight"
            name="progressBar_text_lineHeight"
            value={components?.progressBar?.text?.lineHeight}
            onChange={(e) => setComponents({
              ...components,
              progressBar: {...components?.progressBar, text: {...components?.progressBar?.text, lineHeight: e.target.value}}
            })}/>
        </Group>
        <Group>
          <Label>Font Weight</Label>
          <Input
            id="progressBar_text_fontWeight"
            name="progressBar_text_fontWeight"
            value={components?.progressBar?.text?.fontWigth}
            onChange={(e) => setComponents({
              ...components,
              progressBar: {...components?.progressBar, text: {...components?.progressBar?.text, fontWigth: e.target.value}}
            })}/>
        </Group>
      </div>
      <Title>Active Text Styles</Title>
      <div>
        <Group>
          <Label>Color</Label>
          <ColorPicker color={components?.progressBar?.activeText?.color || '#ffffff'}
                      onChange={(color) => setComponents({
                        ...components,
                        progressBar: {...components?.progressBar, activeText: {...components?.progressBar?.activeText, color: color.hex}}
                      })}/>
        </Group>
        <Group>
          <Label>Font Family</Label>
          <Input
            id="progressBar_activeText_fontFamily"
            name="progressBar_activeText_fontFamily"
            value={components?.progressBar?.activeText?.fontFamily}
            onChange={(e) => setComponents({
              ...components,
              progressBar: {...components?.progressBar, activeText: {...components?.progressBar?.activeText, fontFamily: e.target.value}}
            })}/>
        </Group>
        <Group>
          <Label>Font Size</Label>
          <Input
            id="progressBar_activeText_fontSize"
            name="progressBar_activeText_fontSize"
            value={components?.progressBar?.activeText?.fontSize}
            onChange={(e) => setComponents({
              ...components,
              progressBar: {...components?.progressBar, activeText: {...components?.progressBar?.activeText, fontSize: e.target.value}}
            })}/>
        </Group>
        <Group>
          <Label>Line Height</Label>
          <Input
            id="progressBar_activeText_lineHeight"
            name="progressBar_activeText_lineHeight"
            value={components?.progressBar?.activeText?.lineHeight}
            onChange={(e) => setComponents({
              ...components,
              progressBar: {...components?.progressBar, activeText: {...components?.progressBar?.activeText, lineHeight: e.target.value}}
            })}/>
        </Group>
        <Group>
          <Label>Font Weight</Label>
          <Input
            id="progressBar_activeText_fontWeight"
            name="progressBar_activeText_fontWeight"
            value={components?.progressBar?.activeText?.fontWigth}
            onChange={(e) => setComponents({
              ...components,
              progressBar: {...components?.progressBar, activeText: {...components?.progressBar?.activeText, fontWigth: e.target.value}}
            })}/>
        </Group>
      </div>
      <Title>Bar Styles</Title>
      <div>
        <Group>
          <Label>Background</Label>
          <ColorPicker color={components?.progressBar?.bar?.background || '#ffffff'}
                      onChange={(color) => setComponents({
                        ...components,
                        progressBar: {...components?.progressBar, bar: {...components?.progressBar?.bar, background: color.hex}}
                      })}/>
        </Group>
        <Group>
          <Label>Height</Label>
          <Input
            id="progressBar_bar_height"
            name="progressBar_bar_height"
            value={components?.progressBar?.bar?.height}
            onChange={(e) => setComponents({
              ...components,
              progressBar: {...components?.progressBar, bar: {...components?.progressBar?.bar, height: e.target.value}}
            })}/>
        </Group>
        <Group>
          <Label>Border Radius</Label>
          <Input
            id="progressBar_bar_borderRadius"
            name="progressBar_bar_borderRadius"
            value={components?.progressBar?.bar?.borderRadius}
            onChange={(e) => setComponents({
              ...components,
              progressBar: {...components?.progressBar, bar: {...components?.progressBar?.bar, borderRadius: e.target.value}}
            })}/>
        </Group>
        <Group>
          <Label>Opacity</Label>
          <Input
            id="progressBar_bar_opacity"
            name="progressBar_bar_opacity"
            value={components?.progressBar?.bar?.opacity}
            onChange={(e) => setComponents({
              ...components,
              progressBar: {...components?.progressBar, bar: {...components?.progressBar?.bar, opacity: e.target.value}}
            })}/>
        </Group>
      </div>
      <Title>Active Bar Styles</Title>
      <div>
        <Group>
          <Label>Background</Label>
          <ColorPicker color={components?.progressBar?.activeBar?.background || '#ffffff'}
                      onChange={(color) => setComponents({
                        ...components,
                        progressBar: {...components?.progressBar, activeBar: {...components?.progressBar?.activeBar, background: color.hex}}
                      })}/>
        </Group>
        <Group>
          <Label>Opacity</Label>
          <Input
            id="progressBar_activeBar_opacity"
            name="progressBar_activeBar_opacity"
            value={components?.progressBar?.activeBar?.opacity}
            onChange={(e) => setComponents({
              ...components,
              progressBar: {...components?.progressBar, activeBar: {...components?.progressBar?.activeBar, opacity: e.target.value}}
            })}/>
        </Group>
      </div>
    </div>
  );
}

export default ProgressBarStyles;
