import React from 'react'
import { Title } from '../StagesConfiguration/styles';
import { Group, Input, Label, Select } from '../styles';
import ColorPicker from '../components/ColorPicker/ColorPicker';

interface Props {
	setComponents: (data: any) => void
	components: any
}

const RadioGroup = (props: Props) => {
	const { components, setComponents } = props
	return (
		<>
			<Title>Radio group</Title>
			<Group>
				<Label>Type</Label>
				<Select
					name="orientation"
					id="orientation"
					value={components?.radioGroup?.type || ''}
					onChange={(event) => setComponents({
						...components,
						radioGroup: { ...components?.radioGroup, type: event.target.value }
					})}
				>
					<option value=""></option>
					<option value="radio">radio</option>
					<option value="button">button</option>
				</Select>
			</Group>
			<Group>
				<Label>Color</Label>
				<ColorPicker color={components?.radioGroup?.color || '#ffffff'}
				             onChange={(color) => setComponents({
					             ...components,
					             radioGroup: { ...components?.radioGroup, color: color.hex }
				             })}/>
			</Group>
			<Group>
				<Label>Background</Label>
				<ColorPicker color={components?.radioGroup?.background || '#ffffff'}
				             onChange={(color) => setComponents({
					             ...components,
					             radioGroup: { ...components?.radioGroup, background: color.hex }
				             })}/>
			</Group>
			<Group>
				<Label>fontFamily</Label>
				<Input
					id="radioGroup_fontFamily"
					name="radioGroup_fontFamily"
					value={components?.radioGroup?.fontFamily}
					onChange={(e) => setComponents({
						...components,
						radioGroup: { ...components?.radioGroup, fontFamily: e.target.value }
					})}/>
			</Group>
			<Group>
				<Label>fontSize</Label>
				<Input
					id="radioGroup_fontSize"
					name="radioGroup_fontSize"
					value={components?.radioGroup?.fontSize}
					onChange={(e) => setComponents({
						...components,
						radioGroup: { ...components?.radioGroup, fontSize: e.target.value }
					})}/>
			</Group>
			<Group>
				<Label>Border</Label>
				<Input
					id="radioGroup_border"
					name="radioGroup_border"
					value={components?.radioGroup?.border}
					onChange={(e) => setComponents({
						...components,
						radioGroup: { ...components?.radioGroup, border: e.target.value }
					})}/>
			</Group>
			<Group>
				<Label>Border Radius</Label>
				<Input
					id="radioGroup_border_radius"
					name="radioGroup_border_radius"
					value={components?.radioGroup?.border}
					onChange={(e) => setComponents({
						...components,
						radioGroup: { ...components?.radioGroup, borderRadius: e.target.value }
					})}/>
			</Group>
			<Group>
				<Label>Checked color</Label>
				<ColorPicker color={components?.radioGroup?.checkedStyles?.color || '#ffffff'}
				             onChange={(color) => setComponents({
					             ...components,
					             radioGroup: {
						             ...components?.radioGroup,
						             checkedStyles: {
							             ...components?.radioGroup.checkedStyles,
							             color: color.hex
						             }
					             }
				             })}/>
			</Group>
			<Group>
				<Label>Checked Background</Label>
				<ColorPicker color={components?.radioGroup?.checkedStyles?.background || '#ffffff'}
				             onChange={(color) => setComponents({
					             ...components,
					             radioGroup: {
						             ...components?.radioGroup,
						             checkedStyles: {
							             ...components?.radioGroup.checkedStyles,
							             background: color.hex
						             }
					             }
				             })}/>
			</Group>
			<Group>
				<Label>Checked border color</Label>
				<ColorPicker color={components?.radioGroup?.checkedStyles?.borderColor || '#ffffff'}
				             onChange={(color) => setComponents({
					             ...components,
					             radioGroup: {
						             ...components?.radioGroup,
						             checkedStyles: {
							             ...components?.radioGroup.checkedStyles,
							             borderColor: color.hex
						             }
					             }
				             })}/>
			</Group>
			<Group>
				<Label>Checked Mark Border Color</Label>
				<ColorPicker color={components?.radioGroup?.checkedStyles?.markBorder || '#ffffff'}
				             onChange={(color) => setComponents({
					             ...components,
					             radioGroup: {
						             ...components?.radioGroup,
						             checkedStyles: {
							             ...components?.radioGroup.checkedStyles,
							             markBorder: color.hex
						             }
					             }
				             })}/>
			</Group>
			<Group>
				<Label>Checked border</Label>
				<Input
					id="radioGroup_checked_border"
					name="radioGroup_checked_border"
					value={components?.radioGroup?.checkedStyles?.border}
					onChange={(e) => setComponents({
						...components,
						radioGroup: {
							...components?.radioGroup,
							checkedStyles: {
								...components?.radioGroup?.checkedStyles,
								border: e.target.value
							}
						}
					})}/>
			</Group>
		</>
	)
}

export default RadioGroup
