import styled from "styled-components";

export const FormFieldBlock = styled.div`
  display: flex;
  gap: 25px;
  margin-bottom: 15px;
`;

export const FieldWrapper = styled.div`
  display: flex;
  gap: 5px;
  width: 50%;
  align-items: center;
`;

export const ButtonBlock = styled.div`
  display: flex;
  flex: 1 0 10%;
`;
