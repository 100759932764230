/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react';

import tractor from '../../assets/ryder/tractor.png';
import trailer from '../../assets/ryder/trailer.png';
import truck from '../../assets/ryder/truck.png';
import van from '../../assets/ryder/van.png';
import womenintrucking from '../../assets/ryder/womenintrucking.webp';
import UVS from '../../assets/ryder/UVS.webp';
import FinancePromo from '../../assets/ryder/FinancePromo.jpg';

import freightliner from '../../assets/ryder/freightliner.png';
import ford from '../../assets/ryder/ford.png';
import general_motors from '../../assets/ryder/general_motors.png';
import isuzu_logo from '../../assets/ryder/isuzu_logo.png';
import navistar from '../../assets/ryder/international_navistar.png';
import hino from '../../assets/ryder/hino.png';

import FRTL_2063 from '../../assets/ryder/2015_FRTL_2063_FL-1.webp';
import FRTL_2064 from '../../assets/ryder/2015_FRTL_2064_FL-1.webp';
import OTTA_5 from '../../assets/ryder/2015_OTTA_5_FL-1.webp';

import logo from '../../assets/ryder/logo-ryder-white.png';
import hero_image from '../../assets/ryder/hero_image.png';
import avatar from '../../assets/ryder/avatar.png';

import { 
    Main, 
    Header, 
    Container, 
    Contacts, 
    Nav, 
    TitleBlock, 
    Title, 
    SearchContaner, 
    SubmitButton,
    Subtitle,
    TruckTypes,
    Promotions,
    LearnMore,
    Brands,
    Vehicles,
    Vehicle,
    PromotionsLink,
    BrandsTitle,
    Description,
    VehiclesNav,
    HeaderContainer,
    AvatarBlock,
    HelpButton,
    Menu
 } from './styles';

 import { CLIENT_ID, PRODUCT_ID_CONFIANZA} from '../../config';

function Ryder() {
  useEffect(() => {
    const clientId = CLIENT_ID;
    const productId = PRODUCT_ID_CONFIANZA;
    window.IXWidget('ryder-widget', {
      clientId,
      productId,
      theme: 'material',
      labelPlacement: 'inside',
      heroImage: {
        src: hero_image,
        position: 'top'
      },
      loader: {
        fullscreen: false,
        blurred: true,
        color: '#ffffff',
      },
      firstStage: {
        title: {
            content: '18% OFF ON INSURANCE'
        },
        description: {
            content: 'Comprehensive coverage options, Convenient and flexible payment options, Rapid quotes, Superior claims service through A+ rated carriers'
        },
        button: {
            content: 'SEE YOUR OFFERS',
        },
      },
      styles: {
        colors: {
            primary: '#ce1225',
            secondary: '#ce1225'
        },
        components: {
          headings: {
            color: '#6b717b',
            fontFamily: '"Roboto Condensed",Arial,sans-serif'
          },
          paragraphs: {
            color: '#6b717b',
            fontFamily: '"Roboto Condensed",Arial,sans-serif'
          },
          input: {
            background: '#F3F3F3',
          }
        }
      }
    });
  }, []);
  return (
    <Main>
        <Header>
            <HeaderContainer>
                <Contacts>
                    <div className="col-8">
                        <a href="">RYDER.COM</a>
                    </div>
                    <div className="col-4">
                        <a href="">1-855-643-7072</a>
                        <a href="">Locations</a>
                    </div>
                </Contacts>
                <Nav>
                    <Menu>
                        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                            <defs>
                                <clipPath id="clipPath20" clipPathUnits="userSpaceOnUse">
                                    <path id="path18" d="M 2,19 H 22 V 17 H 2 Z"></path>
                                </clipPath>
                                <clipPath id="clipPath30" clipPathUnits="userSpaceOnUse">
                                    <path id="path28" d="M 2,13 H 22 V 11 H 2 Z"></path>
                                </clipPath>
                                <clipPath id="clipPath40" clipPathUnits="userSpaceOnUse">
                                    <path id="path38" d="M 2,7 H 22 V 5 H 2 Z"></path>
                                </clipPath>
                            </defs>
                            <g transform="matrix(1.333333, 0, 0, -1.333333, 0, 32)" id="g10">
                                <g id="g12"></g>
                                <g id="g14">
                                    <g clipPath="url(#clipPath20)" id="g16">
                                        <path id="path22" d="M -3,12 H 27 V 24 H -3 Z"></path>
                                    </g>
                                </g>
                                <g id="g24">
                                    <g clipPath="url(#clipPath30)" id="g26">
                                        <path id="path32" d="M -3,6 H 27 V 18 H -3 Z"></path>
                                    </g>
                                </g>
                                <g id="g34">
                                    <g clipPath="url(#clipPath40)" id="g36">
                                        <path id="path42" d="M -3,0 H 27 V 12 H -3 Z"></path>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </Menu>
                    <div>
                        <img src={logo} className="logo" alt="" />
                        <ul>
                            <li>
                                <a href="">Used Vehicles</a>
                            </li>
                            <li>
                                <a href="">Brands</a>
                            </li>
                            <li>
                                <a href="">Vehicle Conditions</a>
                            </li>
                            <li>
                                <a href="">Ryder Benefits</a>
                            </li>
                            <li>
                                <a href="">Promotions</a>
                            </li>
                        </ul>
                    </div>
                    <svg className="header-search__toggle-icon icon" width="21" height="21" viewBox="0 0 21 21">
                        <g fill="none" fillRule="evenodd">
                            <path fill="#000" d="M13.582 12.537l7.386 6.066-.536.72-7.386-6.065z"></path>
                            <path stroke="#000" strokeLinejoin="round" d="M8 1a7 7 0 1 1 0 14A7 7 0 0 1 8 1z"></path>
                        </g>
                    </svg>
                </Nav>
            </HeaderContainer>
        </Header>
        <TitleBlock>
            <Title>RYDER USED TRUCKS FOR SALE</Title>
            <SearchContaner>
                <h3><span>Trustworthy Trucks. Ryder Reliability. </span> Find Your Vehicle Today.</h3>
                <form action="">
                    <input type="text" placeholder='Enter Make, Model, Type or Keyword' />
                    <SubmitButton>FIND VEHICLES</SubmitButton>
                </form>
            </SearchContaner>
        </TitleBlock>
        <section>
            <Container>
                <Subtitle>SHOP OUR INVENTORY BY TRUCK TYPE</Subtitle>
                <TruckTypes>
                    <li>
                        <img src={tractor} alt="" />
                        <p>SEMI TRUCKS</p>
                    </li>
                    <li>
                        <img src={truck} alt="" />
                        <p>TRUCKS</p>
                    </li>
                    <li>
                        <img src={trailer} alt="" />
                        <p>TRAILERS</p>
                    </li>
                    <li>
                        <img src={van} alt="" />
                        <p>VANS & CARS</p>
                    </li>
                </TruckTypes>
            </Container>
            <Container>
                <Subtitle>OUR PROMOTIONS</Subtitle>
                <PromotionsLink href="">SEE ALL PROMOTIONS </PromotionsLink>
                <Promotions>
                    <div>
                        <img src={FinancePromo} alt="" />
                        <h4 className="promotions_title">$0 DOWN; $49/MONTH FOR FIRST 90 DAYS</h4>
                        <p>For a limited time put $0 down and pay just $49 per month for the first three months on a Ryder used truck. Take advantage of this limited time deal from Ryder, because it will not last. *</p>
                        <LearnMore type='button'>Learn more</LearnMore>
                    </div>
                    <div>
                        <img src={womenintrucking} alt="" />
                        <h4 className="promotions_title">WOMEN IN TRUCKING SAVE 5% WITH RYDER</h4>
                        <p>As a sign of support for women-owned businesses and Women in Trucking, Ryder is offering 5% off the purchase price of a used vehicle for Women In Trucking Association members.**</p>
                        <LearnMore type='button'>Learn more</LearnMore>
                    </div>
                    <div>
                        <img src={UVS} alt="" />
                        <h4 className="promotions_title">USED TRUCK FINANCING AS LOW AS 0%</h4>
                        <p>Hurry in to your nearest Ryder Used Truck Center or browse our online inventory, and get financing rates as low as 0%.***</p>
                        <LearnMore type='button'>Learn more</LearnMore>
                    </div>
                    <div>
                        <div id="ryder-widget"></div>
                    </div>
                </Promotions>
            </Container>
            <Container>
                <BrandsTitle>Featured Brands</BrandsTitle>
                <Brands>
                    <li>
                        <a href="">
                            <img src={freightliner} alt="" />
                            <span>FREIGHTLINER</span>
                        </a>
                    </li>
                    <li>
                        <a href="">
                            <img src={ford} alt="" />
                            <span>FORD</span>
                        </a>
                    </li>
                    <li>
                        <a href="">
                            <img src={general_motors} alt="" />
                            <span>GENERAL MOTORS</span>
                        </a>
                    </li>
                    <li>
                        <a href="">
                            <img src={hino} alt="" />
                            <span>HINO</span>
                        </a>
                    </li>
                    <li>
                        <a href="">
                            <img src={isuzu_logo} alt="" />
                            <span>ISUZU</span>
                        </a>
                    </li>
                    <li>
                        <a href="">
                            <img src={navistar} alt="" />
                            <span>NAVISTAR INT.</span>
                        </a>
                    </li>
                </Brands>
            </Container>
            <Container>
                <Subtitle>FEATURED VEHICLES</Subtitle>
                <Description>Top Picks from our selection of over 9,000 vehicles!</Description>
                <Vehicles>
                    <Vehicle>
                        <img src={FRTL_2063} alt="" />
                        <p className='verified'>RYDER DOT VERIFIED™</p>
                        <h4>Freightliner CASCADIA 113</h4>
                        <div>
                            <p className='price'>$38,695 USD</p>
                            <p className='price old'>$43,698 USD</p>
                        </div>
                        <div>
                            <p className='mileage'>
                                Miles: <span>290,149</span>
                            </p>
                            <p className='year'>
                                Year: <span>2015</span>
                            </p>
                        </div>
                    </Vehicle>
                    <Vehicle>
                        <img src={OTTA_5} alt="" />
                        <p className='verified'>RYDER DOT VERIFIED™</p>
                        <h4>Ottawa C30</h4>
                        <div>
                            <p className='price'>$62,495 USD</p>
                            <p className='price old'>$85,723 USD</p>
                        </div>
                        <div>
                            <p className='mileage'>
                                Miles: <span>23,831</span>
                            </p>
                            <p className='year'>
                                Year: <span>2015</span>
                            </p>
                        </div>
                    </Vehicle>
                    <Vehicle>
                        <img src={FRTL_2064} alt="" />
                        <p className='verified'>RYDER DOT VERIFIED™</p>
                        <h4>Freightliner CASCADIA 125</h4>
                        <div>
                            <p className='price'>$45,395 USD</p>
                            <p className='price old'>$50,392 USD</p>
                        </div>
                        <div>
                            <p className='mileage'>
                                Miles: <span>351,288</span>
                            </p>
                            <p className='year'>
                                Year: <span>2015</span>
                            </p>
                        </div>
                    </Vehicle>
                    <VehiclesNav>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="35" viewBox="0 0 18 35"><path fill="none" fillRule="evenodd" stroke="#CE1225" strokeLinejoin="round" strokeWidth="2" d="M17.5.5l-17 17 17 17"/></svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="35" viewBox="0 0 18 35"><path fill="none" fillRule="evenodd" stroke="#CE1225" strokeLinejoin="round" strokeWidth="2" d="M 17.5 0.5 L 0.5 17.5 L 17.5 34.5" transform="matrix(-1, 0, 0, -1, 18, 35)"/></svg>
                    </VehiclesNav>
                </Vehicles>
            </Container>
        </section>
        <AvatarBlock>
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Livello_1" x="0px" y="0px" viewBox="0 0 34.136 32" >
                <path className="st0" d="M15.624,6.4c1.0985,0,1.989,0.8905,1.989,1.989s-0.8905,1.989-1.989,1.989s-1.989-0.8905-1.989-1.989l0,0  c-0.0044-1.0941,0.8789-1.9846,1.973-1.989C15.6133,6.4,15.6187,6.4,15.624,6.4z M22.585,12.035  c-1.463,0.2204-2.9405,0.3314-4.42,0.332c-0.221,0-0.442,0.331-0.442,0.552v4.53l2.432,7.851  c0.149,0.3964-0.0478,0.8391-0.442,0.994c-0.0884,0.0849-0.2104,0.1253-0.332,0.11c-0.2926,0.009-0.5587-0.1685-0.663-0.442  l-2.652-6.192c-0.11-0.442-0.663-0.442-0.884,0l-2.652,6.188c-0.1172,0.2629-0.3752,0.4349-0.663,0.442  c-0.11,0-0.221-0.11-0.331-0.11c-0.442-0.11-0.552-0.552-0.442-0.994l2.431-7.846v-4.531c0.0276-0.2737-0.1689-0.5191-0.442-0.552  c-1.437-0.111-2.983-0.111-4.42-0.332C8.252,11.9476,7.9688,11.57,8,11.151c0.0874-0.411,0.465-0.6942,0.884-0.663  c2.2396,0.2244,4.4892,0.3348,6.74,0.331c2.2508,0.0038,4.5004-0.1066,6.74-0.331c0.442,0,0.884,0.221,0.884,0.663  c0,0.442-0.22,0.884-0.662,0.884H22.585z M27.2286,25.9193c-0.2043,0.231-0.4006,0.4667-0.6216,0.6877  c-5.8581,5.8581-15.3559,5.8581-21.214,0c-2.8135-2.8133-4.394-6.6291-4.3938-10.6078C0.9996,7.7149,7.7158,0.9996,16,1  c3.9805-0.0113,7.8,1.5706,10.607,4.393c2.8505,2.8505,4.3024,6.563,4.3788,10.2984c0.3469,0.0877,0.6835,0.21,1.0112,0.3662  C31.9971,16.0381,32,16.0195,32,16c0-8.8365-7.1635-16-16-16S0,7.1635,0,16s7.1635,16,16,16c4.9128,0,9.3035-2.2188,12.2385-5.7038  C27.8917,26.2051,27.5557,26.0792,27.2286,25.9193z M32.818,17.8177c-1.7574-1.7574-4.6066-1.7574-6.364,0  c-1.7574,1.7573-1.7574,4.6066,0,6.364c1.7573,1.7573,4.6066,1.7573,6.364,0C34.5754,22.4243,34.5754,19.5751,32.818,17.8177z   M31.3026,21.9597c0.1953,0.1953,0.1953,0.5118,0,0.7072c-0.1953,0.1953-0.5118,0.1953-0.7071,0l-0.9595-0.9595l-0.9595,0.9595  c-0.1953,0.1953-0.5118,0.1953-0.7071,0s-0.1953-0.5118,0-0.7071l0.9595-0.9595l-0.9596-0.9595  c-0.1953-0.1953-0.1953-0.5118,0-0.7071s0.5118-0.1953,0.7072,0l0.9595,0.9595l0.9596-0.9596c0.1953-0.1953,0.5118-0.1953,0.7071,0  s0.1953,0.5118,0,0.7072l-0.9596,0.9595L31.3026,21.9597z"/>
            </svg>
        </AvatarBlock>
        <HelpButton>
            <button className="uiButton helpButtonEnabled" >
                <img src={avatar} alt="" />
                <span className="message">Ruby - Digital Assistant</span>
            </button>
        </HelpButton>
    </Main>
  );
}

export default Ryder;
