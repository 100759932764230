import { CLIENT_ID, PRODUCT_ID_CONFIANZA } from '../../config';

export const fullSizeConfig = {
  clientId: CLIENT_ID,
  productId: PRODUCT_ID_CONFIANZA,
  programId: '',
  resultRuleNames: {
    UserQualified: 'Qualified',
    UserNotQualified: 'Not Qualified',
    NoMatchingData: 'No Matching Data',
  },
  FirstStage: {
    stepCategories: {
      'Step1': [ 'PersonalInfo' ],
      'Step2': [ 'BaseInfo', 'FullName' ],
      'Step3': [ 'CoverageStartDate' ],
    },
  },
  ConfirmationStage: {
    stepCategories: {
      'Step4': [ 'CoveredAddress', 'Email' ],
      'Step5': [ 'Underwriting' ],
    },
  },
  Step1: {
    title: {
      content: 'Lock in your quote in the blink of an eye',
      visible: true
    },
    description: {
      content: 'Be sure your car is safe! Take a look at your quote just in few clicks.',
      visible: true
    },
  },
  labelPlacement: 'top',
  isMicroSite: true,
  fullSize: true,
  heroImage: {
    position: 'contain',
  },
  emailField: true,
  styles: {
    components: {
      input: {
        fontSize: '20px',
      },
    },
  },
  layout: {
    columns: 2,
  },
};
