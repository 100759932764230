import React, {useState} from 'react'
import {ChromePicker, ColorResult} from "react-color";
import {Cover, Picker, Popover, Swatch} from "./styles";

interface ColorPickerProps {
  color: string
  onChange: (color: ColorResult) => void
}

const ColorPicker = ({color, onChange}: ColorPickerProps) => {
  const [displayPicker, setDisplayPicker] = useState(false);
  const handlePicker = () => {
    setDisplayPicker(prev => !prev)
  }
  const handleClose = () => {
    setDisplayPicker(false)
  }
  return (
    <>
      <Picker onClick={handlePicker}>
        <Swatch background={color}/>
      </Picker>
      {
        displayPicker && <Popover>
					<Cover onClick={handleClose}/>
					<ChromePicker
						color={color || '#ffffff'}
						onChangeComplete={onChange}
					/>
				</Popover>
      }
    </>
  )
}

export default ColorPicker
