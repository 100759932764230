import React from 'react';

import {Group, Input, Label} from '../styles';
import ColorPicker from "../components/ColorPicker/ColorPicker";
import {HeaderContainer, MainTitle, SubTitle, Title} from "../StagesConfiguration/styles";
import ProgressBarStyles from "./ProgressBarStyles";
import RadioGroup from './RadioGroup';

interface StyleProps {
  setColors: (data: any) => void
  setComponents: (data: any) => void
  colors: any
  components: any
  id: string
}

function StylesConfiguration({setComponents, setColors, colors, components, id}: Readonly<StyleProps>) {
  return (
    <div>
      <HeaderContainer>
        <SubTitle>Styles</SubTitle>
      </HeaderContainer>
      <MainTitle>Colors</MainTitle>
      <Group>
        <Label>Primary</Label>
        <ColorPicker color={colors?.primary || '#ffffff'}
                     onChange={(color) => setColors({...colors, primary: color.hex})}/>
      </Group>
      <Group>
        <Label>Secondary</Label>
        <ColorPicker color={colors?.secondary || '#ffffff'}
                     onChange={(color) => setColors({...colors, secondary: color.hex})}/>
      </Group>
      <Group>
        <Label>Danger</Label>
        <ColorPicker color={colors?.danger || '#ffffff'}
                     onChange={(color) => setColors({...colors, danger: color.hex})}/>
      </Group>

      <MainTitle>Components</MainTitle>

      <Title>Button</Title>
      <Group>
        <Label>Background</Label>
        <ColorPicker color={components?.button?.background || '#ffffff'} onChange={(color) => setComponents({
          ...components,
          button: {...components?.button, background: color.hex}
        })}/>
      </Group>
      <Group>
        <Label>Padding</Label>
        <Input
          id="button_padding"
          name="button_padding"
          value={components?.button?.padding}
          onChange={(e) => setComponents({...components, button: {...components?.button, padding: e.target.value}})}/>
      </Group>
      <Group>
        <Label>Border Radius</Label>
        <Input
          id="button_borderRadius"
          name="button_borderRadius"
          value={components?.button?.borderRadius}
          onChange={(e) => setComponents({
            ...components,
            button: {...components?.button, borderRadius: e.target.value}
          })}/>
      </Group>
      <Group>
        <Label>Color</Label>
        <ColorPicker color={components?.button?.color || '#ffffff'} onChange={(color) => setComponents({
          ...components,
          button: {...components?.button, color: color.hex}
        })}/>
      </Group>
      <Group>
        <Label>Font Size</Label>
        <Input
          id="button_fontSize"
          name="button_fontSize"
          value={components?.button?.fontSize}
          onChange={(e) => setComponents({
            ...components,
            button: {...components?.button, fontSize: e.target.value}
          })}/>
      </Group>
      <Group>
        <Label>Font Family</Label>
        <Input
          id="button_fontFamily"
          name="button_fontFamily"
          value={components?.button?.fontFamily}
          onChange={(e) => setComponents({
            ...components,
            button: {...components?.button, fontFamily: e.target.value}
          })}/>
      </Group>

      <Title>Container</Title>
      <Group>
        <Label>Background</Label>
        <ColorPicker color={components?.container?.background || '#ffffff'}
                     onChange={(color) => setComponents({
                       ...components,
                       container: {...components?.container, background: color.hex}
                     })}/>
      </Group>

      <Title>Form</Title>
      <Group>
        <Label>Background</Label>
        <ColorPicker color={components?.form?.background || '#ffffff'}
                    onChange={(color) => setComponents({
                      ...components,
                      form: {...components?.form, background: color.hex}
                    })}/>
      </Group>
      <Group>
        <Label>Border Radius</Label>
        <Input
          id="button_borderRadius"
          name="button_borderRadius"
          value={components?.form?.borderRadius}
          onChange={(e) => setComponents({
            ...components,
            form: {...components?.form, borderRadius: e.target.value}
          })}/>
      </Group>
      <Group>
        <Label>Padding</Label>
        <Input
          id="input_padding"
          name="input_padding"
          value={components?.form?.padding}
          onChange={(e) => setComponents({...components, form: {...components?.form, padding: e.target.value}})}/>
      </Group>

      <Title>Input</Title>
      <Group>
        <Label>Background</Label>
        <ColorPicker color={components?.input?.background || '#ffffff'}
                     onChange={(color) => setComponents({
                       ...components,
                       input: {...components?.input, background: color.hex}
                     })}/>
      </Group>
      <Group>
        <Label>Color</Label>
        <ColorPicker color={components?.input?.color || '#ffffff'}
                     onChange={(color) => setComponents({
                       ...components,
                       input: {...components?.input, color: color.hex}
                     })}/>
      </Group>
      <Group>
        <Label>Padding</Label>
        <Input
          id="input_padding"
          name="input_padding"
          value={components?.input?.padding}
          onChange={(e) => setComponents({...components, input: {...components?.input, padding: e.target.value}})}/>
      </Group>
      <Group>
        <Label>fontSize</Label>
        <Input
          id="input_fontSize"
          name="input_fontSize"
          value={components?.input?.fontSize}
          onChange={(e) => setComponents({...components, input: {...components?.input, fontSize: e.target.value}})}/>
      </Group>
      <Group>
        <Label>borderRadius</Label>
        <Input
          id="input_borderRadius"
          name="input_borderRadius"
          value={components?.input?.borderRadius}
          onChange={(e) => setComponents({
            ...components,
            input: {...components?.input, borderRadius: e.target.value}
          })}/>
      </Group>
      <Group>
        <Label>Border</Label>
        <Input
          id="input_border"
          name="input_border"
          value={components?.input?.border}
          onChange={(e) => setComponents({
            ...components,
            input: {...components?.input, border: e.target.value}
          })}/>
      </Group>

      <Title>Headings</Title>
      <Group>
        <Label>Color</Label>
        <ColorPicker color={components?.headings?.color || '#ffffff'}
                     onChange={(color) => setComponents({
                       ...components,
                       headings: {...components?.headings, color: color.hex}
                     })}/>
      </Group>
      <Group>
        <Label>fontFamily</Label>
        <Input
          id="headings_fontFamily"
          name="headings_fontFamily"
          value={components?.headings?.fontFamily}
          onChange={(e) => setComponents({
            ...components,
            headings: {...components?.headings, fontFamily: e.target.value}
          })}/>
      </Group>

      <Title>Paragraphs</Title>
      <Group>
        <Label>Color</Label>
        <ColorPicker color={components?.paragraphs?.color || '#ffffff'}
                     onChange={(color) => setComponents({
                       ...components,
                       paragraphs: {...components?.paragraphs, color: color.hex}
                     })}/>
      </Group>
      <Group>
        <Label>fontFamily</Label>
        <Input
          id="paragraphs_fontFamily"
          name="paragraphs_fontFamily"
          value={components?.paragraphs?.fontFamily}
          onChange={(e) => setComponents({
            ...components,
            paragraphs: {...components?.paragraphs, fontFamily: e.target.value}
          })}/>
      </Group>
      <Group>
        <Label>fontSize</Label>
        <Input
          id="paragraphs_fontSize"
          name="paragraphs_fontSize"
          value={components?.paragraphs?.fontSize}
          onChange={(e) => setComponents({
            ...components,
            paragraphs: {...components?.paragraphs, fontSize: e.target.value}
          })}/>
      </Group>
      <Group>
        <Label>lineHeight</Label>
        <Input
          id="paragraphs_lineHeight"
          name="paragraphs_lineHeight"
          value={components?.paragraphs?.lineHeight}
          onChange={(e) => setComponents({
            ...components,
            paragraphs: {...components?.paragraphs, lineHeight: e.target.value}
          })}/>
      </Group>

      <Title>Label</Title>
      <Group>
        <Label>Color</Label>
        <ColorPicker color={components?.label?.color || '#ffffff'}
                     onChange={(color) => setComponents({
                       ...components,
                       label: {...components?.label, color: color.hex}
                     })}/>
      </Group>
      <Group>
        <Label>fontFamily</Label>
        <Input
          id="label_fontFamily"
          name="label_fontFamily"
          value={components?.label?.fontFamily}
          onChange={(e) => setComponents({
            ...components,
            label: {...components?.label, fontFamily: e.target.value}
          })}/>
      </Group>
      <Group>
        <Label>fontSize</Label>
        <Input
          id="label_fontSize"
          name="label_fontSize"
          value={components?.label?.fontSize}
          onChange={(e) => setComponents({
            ...components,
            label: {...components?.label, fontSize: e.target.value}
          })}/>
      </Group>
      <Group>
        <Label>lineHeight</Label>
        <Input
          id="label_lineHeight"
          name="label_lineHeight"
          value={components?.label?.lineHeight}
          onChange={(e) => setComponents({
            ...components,
            label: {...components?.label, lineHeight: e.target.value}
          })}/>
      </Group>

      <Title>Footer</Title>
      <Group>
        <Label>Color</Label>
        <ColorPicker color={components?.footer?.color || '#ffffff'}
                     onChange={(color) => setComponents({
                       ...components,
                       footer: {...components?.footer, color: color.hex}
                     })}/>
      </Group>
      <Group>
        <Label>fontFamily</Label>
        <Input
          id="footer_fontFamily"
          name="footer_fontFamily"
          value={components?.footer?.fontFamily}
          onChange={(e) => setComponents({
            ...components,
            footer: {...components?.footer, fontFamily: e.target.value}
          })}/>
      </Group>
      <Group>
        <Label>fontSize</Label>
        <Input
          id="footer_fontSize"
          name="footer_fontSize"
          value={components?.footer?.fontSize}
          onChange={(e) => setComponents({
            ...components,
            footer: {...components?.footer, fontSize: e.target.value}
          })}/>
      </Group>
      <Group>
        <Label>lineHeight</Label>
        <Input
          id="footer_lineHeight"
          name="footer_lineHeight"
          value={components?.footer?.lineHeight}
          onChange={(e) => setComponents({
            ...components,
            footer: {...components?.footer, lineHeight: e.target.value}
          })}/>
      </Group>
      <Group>
        <Label>textAlign</Label>
        <Input
          id="footer_textAlign"
          name="footer_textAlign"
          value={components?.footer?.textAlign}
          onChange={(e) => setComponents({
            ...components,
            footer: {...components?.footer, textAlign: e.target.value}
          })}/>
      </Group>
	    <RadioGroup  components={components} setComponents={setComponents}/>

      {id !== "contactInformation" && <ProgressBarStyles components={components} setComponents={setComponents} />}
    </div>
  );
}

export default StylesConfiguration;
