import styled from "styled-components";

export const Footer = styled.footer`
  background: #F2F2FC;
  display: flex;
  flex-direction: column;
  padding: 50px 20px;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
`;
export const Copyright = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #010101;
  max-width: 465px;
  margin: 32px 0;
  text-align: center;
`;
export const List = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  &.agreements li{
    margin: 0 8px;
    position: relative;
    &:first-child:before{
      content: '|';
      position: absolute;
      color: #010101;
      right: -9px;
      top: 0;
    }
  }
  &.social-links li{
    margin: 0 15px;
  }
`;
export const Link = styled.a`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #010101;
  text-decoration: none;
`;
