import React, {useContext} from 'react';
import {ConfigurationContext} from '../';
import {Divider, HeaderContainer, HeaderTitle} from "./styles";
import {
  renderTitleConfig
} from '../StagesConfiguration/renderUtils';

function RulesConfiguration() {
  const {
    userQualified,
    setUserQualified,
    userNotQualified,
    setUserNotQualified,
    noMatchingData,
    setNoMatchingData,
  }: any = useContext(ConfigurationContext);

  const rules: Array<any> = [
    {
      title: 'User Qualified',
      value: userQualified,
      handleChange: setUserQualified,
      id: 'UserQualified',
      props: ['title', 'button', 'description']
    },
    {
      title: 'User Not Qualified',
      value: userNotQualified,
      handleChange: setUserNotQualified,
      id: 'UserNotQualified',
      props: ['title', 'button', 'description']
    },
    {
      title: 'No Matching Data',
      value: noMatchingData,
      handleChange: setNoMatchingData,
      id: 'NoMatchingData',
      props: ['title', 'button', 'description']
    },
  ];

  return (
    <>
      {rules.map(item => (
        <div key={item.id}>
          <HeaderContainer>
            <HeaderTitle>{item.title}</HeaderTitle>
          </HeaderContainer>
          {item.props.map((component: string) => renderTitleConfig(item.value, item.handleChange, item.id, component))}
          <Divider/>
        </div>
      ))}
    </>
  );
}

export default RulesConfiguration;
