import React, { useContext } from 'react';
import './style.css'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

import ProductConfiguration from '../ProductConfiguration';
import ComponentsConfiguration from '../ComponentsConfiguration';
import StagesConfiguration from '../StagesConfiguration';
import StylesConfiguration from '../StylesConfiguration';
import RulesConfiguration from '../RulesConfiguration';

import { Form, SaveButton, DefaultButton } from '../styles';
import { ConfigurationContext } from '../';
import StepsComponent from "../StepsComponent";

function WidgetConfiguration() {
  const {
    handleSubmit,
    setToDefault,
    setComponents,
    setColors,
    colors,
    components,
    firstStage,
    setFirstStage,
    ctaStage,
    setCtaStage,
    confirmationStage,
    setConfirmationStage,
    resultsStage,
    setResultsStage,
    setCtaStageComponents,
    setCtaStageColors,
    setResultStageColors,
    setResultStageComponents,
    contactInformation,
    setContactInformation,
    setContactInformationColors,
    setContactInformationComponents
  }: any = useContext(ConfigurationContext);
  const accordionItems = [
    {
      title: 'Product Configuration',
      component: <ProductConfiguration />
    },
    {
      title: 'Widget Appearance Configuration',
      component: <ComponentsConfiguration />
    },
    {
      title: 'First Stage Configuration',
      component: <StagesConfiguration item={{
        value: firstStage,
        handleChange: setFirstStage,
        id: 'firstStage',
        props: ['stepCategories']
      }} />
    },
    {
      title: 'Confirmation Stage Configuration',
      component: <StagesConfiguration item={{
        value: confirmationStage,
        handleChange: setConfirmationStage,
        id: 'confirmationStage',
        props: ['stepCategories']
      }} />
    },
    {
      title: 'Rules Configuration',
      component: <RulesConfiguration />
    },
    {
      title: 'CTA Page Configuration',
      component: <StagesConfiguration item={{
        value: ctaStage,
        handleChange: setCtaStage,
        setComponents: setCtaStageComponents,
        setColors: setCtaStageColors,
        id: 'ctaStage',
        props: ['title', 'description', 'button', 'logo', 'heroImage', 'footer', 'styles']
      }} />
    },
    {
      title: 'Result Page Configuration',
      component: <StagesConfiguration item={{
        value: resultsStage,
        setComponents: setResultStageComponents,
        setColors: setResultStageColors,
        handleChange: setResultsStage,
        id: 'resultsStage',
        props: ['title', 'description', 'button', 'logo', 'heroImage', 'footer', 'template', 'showIcon', 'border', 'templateAttributes', 'attributes', 'styles']
      }} />
    },
    {
      title: 'Contact Information Page Configuration',
      component: <StagesConfiguration item={{
        value: contactInformation,
        setComponents: setContactInformationComponents,
        setColors: setContactInformationColors,
        handleChange: setContactInformation,
        id: 'contactInformation',
        props: ['title', 'description', 'button', 'logo', 'heroImage', 'footer', 'styles']
      }} />
    },
    {
      title: 'Styles Configuration',
      component: <StylesConfiguration components={components} colors={colors} setComponents={setComponents} setColors={setColors} id="common-styles" />
    },
  ];

  return (
    <Form>
      <h2>IX-Widget Configuration</h2>
      <Accordion allowZeroExpanded={true}>
        {accordionItems.map(item => {
          return <AccordionItem key={item.title} className="accordionPaddingZero">
              <AccordionItemHeading>
                <AccordionItemButton>
                  {item.title}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className="accordionContent">
                {item.component}
              </AccordionItemPanel>
          </AccordionItem>
        })}
      </Accordion>
      <StepsComponent />
      <SaveButton onClick={handleSubmit}>Save</SaveButton>
      <DefaultButton onClick={setToDefault}>Set to default</DefaultButton>
    </Form>
  );
}

export default WidgetConfiguration;
