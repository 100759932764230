import React, {useState} from 'react';
import { Container, Row,  Col, Table, Head, Body, Link } from './styles';
import Modal from './Modal';
import logo from '../../assets/LibertyMutual/logo.svg';

function VerticalSoftware() {
    const [open, setOpen] = useState(false);
    const [price, setPrice] = useState('$0.00');
    const handleOpen = () => setOpen(true);
    const handleClose = (new_price: string | undefined) => {
        setOpen(false);
        if (new_price) {
            setPrice(new_price)
        }
    };
    return (
        <>
            <Container>
                <Table>
                    <Head>
                        <Col className='dark col-8'>
                            <p>Employer Provided Benefits</p>
                        </Col>
                        <Col className='primary col-4'>
                            <p>Employer Provided Benefits <span>Starting 01/01/2023</span></p>
                        </Col>
                    </Head>
                    <Body>
                        <Row>
                            <Col className='row col-8'>
                                <Col className='col-4'>
                                    <p className='title'>Employee Basic Life</p>
                                </Col>
                                <Col className='light col-8'>
                                    <div>
                                        <p className='title'>Employee Basic Life</p>
                                        <p>1X Annual Salary $100,000</p>
                                    </div>
                                    <div>
                                        <p className='price'>$0.00</p>
                                    </div>
                                </Col>
                            </Col>
                            <Col className='col-4'>
                                <div>
                                    <p className='title'>Employee Basic Life</p>
                                    <p>1X Annual Satory $100,000</p>
                                </div>
                                <div>
                                    <p className='price'>$0.00</p>
                                    <Link>View/Change</Link>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='row col-8'>
                                <Col className='col-4'>
                                    <p className='title'>Basic AD&D</p>
                                </Col>
                                <Col className='light col-8'>
                                    <div>
                                        <p className='title'>Employee Basic AD&D</p>
                                        <p>1X Annual Salary $100,000</p>
                                    </div>
                                    <p className='price'>$0.00</p>
                                </Col>
                            </Col>
                            <Col className='col-4'>
                                <div>
                                    <p className='title'>Employee Basic AD&D</p>
                                    <p>1X Annual Satory $100,000</p>
                                </div>
                                <div>
                                    <p className='price'>$0.00</p>
                                    <Link>View/Change</Link>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='row col-8'>
                                <Col className='col-4'>
                                    <p className='title'>Employee Assistance Plan</p>
                                </Col>
                                <Col className='light col-8'>
                                    <div>
                                        <p className='title'>LifeWorks Mental Health Program</p>
                                        <p>You're Covered. <br />Access this benefit by contacting LifeWorks</p>
                                    </div>
                                    <p className='price'>$0.00</p>
                                </Col>
                            </Col>
                            <Col className='col-4'>
                                <div>
                                    <p className='title'>LifeWorks Mental Health Program</p>
                                    <p>You're Covered. <br />Access this benefit by contacting LifeWorks</p>
                                </div>
                                <div>
                                    <p className='price'>$0.00</p>
                                    <Link>More Information</Link>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='row col-8'>
                                <Col className='col-4'>
                                    <p className='title'>Auto/Home Insurance</p>
                                </Col>
                                <Col className='light col-8'>
                                    <div>
                                        <p className='title'>Auto/Home Insurance</p>
                                        <p>Access this benefit enrollment information <br/>provided by Farmers Insurance</p>
                                    </div>
                                    <p className='price'>$0.00</p>
                                </Col>
                            </Col>
                            <Col className='col-4'>
                                <div>
                                    <p className='title'>Auto Insurance</p>
                                    <p>Access this benefit enrollment information <br/>provided by Farmers Insurance</p>
                                </div>
                                <div style={{display: 'flex', padding: '0 5px'}}>
                                    <img src={logo} alt="" style={{maxWidth: '100%'}} />
                                </div>
                                <div>
                                    <p className='price'>{price}</p>
                                    <Link onClick={handleOpen}>See your offer</Link>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='row col-8'>
                                <Col className='col-4'>
                                    <p className='title'>Pet Insurance</p>
                                </Col>
                                <Col className='light col-8'>
                                    <div>
                                        <p className='title'>Pet Insurance</p>
                                        <p>Access this benefit enrollment information <br/>provided by Nationwide</p>
                                    </div>
                                    <p className='price'>$0.00</p>
                                </Col>
                            </Col>
                            <Col className='col-4'>
                                <div>
                                    <p className='title'>Pet Insurance</p>
                                    <p>Access this benefit enrollment information <br/>provided by Nationwide</p>
                                </div>
                                <div>
                                    <p className='price'>$0.00</p>
                                    <Link>More Information</Link>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='row col-8'>
                                <Col className='col-4'>
                                    <p className='title'>Payactiv (Earned Wage Access)</p>
                                </Col>
                                <Col className='light col-8'>
                                    <div>
                                        <p className='title'>Payactiv (Earned Wage Access)</p>
                                        <p>Opt Out I decline to participate</p>
                                    </div>
                                    <p className='price'>$0.00</p>
                                </Col>
                            </Col>
                            <Col className='col-4'>
                                <div>
                                    <p className='title'>Payactiv (Earned Wage Access)</p>
                                    <p>Opt Out I decline to participate</p>
                                </div>
                                <div>
                                    <p className='price'>$0.00</p>
                                    <Link>View/Change</Link>
                                </div>
                            </Col>
                        </Row>
                    </Body>
                </Table>
            </Container>
            {open && <Modal handleClose={handleClose} />}
        </>
    );
}

export default VerticalSoftware;
