/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import {
  Container,
  Text,
  Title,
  Button,
  Link,
  ItemTitle,
  Description,
  DarkContainer,
  VideoContainer,
  Row,
  VideoBlock,
  Item,
  PlayButton,
  List,
  WidgetBlock,
  WorkItems,
} from './styles';
import arrow from '../../../assets/icon-arrow.svg';
import instant from '../../../assets/Instant.svg';
import score from '../../../assets/score.svg';
import fees from '../../../assets/fees.svg';
import question from '../../../assets/question.gif';
import message from '../../../assets/message.gif';
import shield from '../../../assets/shield.gif';
import work from '../../../assets/Work.mp4';
import logo from "../../../assets/Logo-horizontal.svg";
import { CLIENT_ID, PRODUCT_ID_CONFIANZA } from '../../../config';

declare global {
  interface Window {
    IXWidget: any;
  }
}

function Dashboard() {
  const [isFirstPage, setIsFirstPage] = useState<boolean>(true);
  const next = () => {
    setIsFirstPage(false);
  };
  const prev = () => {
    setIsFirstPage(true);
  };

  useEffect(() => {
    window.addEventListener("movedToFirstScreen", prev);
    window.addEventListener("onFirstScreenSubmit", next);
    return () => {
      window.removeEventListener("movedToFirstScreen", prev);
      window.removeEventListener("onFirstScreenSubmit", next);
    };
  }, []);

  useEffect(() => {
    window.IXWidget('ix-widget', {
      clientId: CLIENT_ID,
      productId: PRODUCT_ID_CONFIANZA,
      isMicroSite: true,
      theme: 'default',
      labelPlacement: 'top',
      logo: {
        src: logo,
      },
      firstStage: {
        description: {
          visible: false,
        },
        title: {
          content: 'Your perfect insurance match in minutes'
        },
        button: {
          content: 'Start my quote'
        },
      },
      loader: {
        fullscreen: true,
        blurred: true,
        color: '#6266ea',
      },
      styles: {
        components: {
          form: {
            background: 'transparent'
          },
          container: {
            background: 'transparent'
          },
          paragraphs: {
            fontSize: '16px',
          },
          input: {
            fontSize: '20px',
          }
        }
      }
    });
  }, []);

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div>
      <Container isFirstPage={isFirstPage}>
        <WidgetBlock isFirstPage={isFirstPage}>
          <div id="ix-widget"></div>
        </WidgetBlock>
        {isFirstPage && <List>
          <li>
            <img src={instant} alt="" />
            <span>Instant & personalised</span>
          </li>
          <li>
            <img src={score} alt="" />
            <span>No impact on credit score</span>
          </li>
          <li>
            <img src={fees} alt="" />
            <span>No hidden fees</span>
          </li>
        </List>}
      </Container>
      {isFirstPage && <>
      <VideoContainer>
        <Title>How it <span>works</span></Title>
        <Text>If you’re looking for a catch, you won’t find one. It really is that simple.</Text>
        <Row>
          <WorkItems>
            <Item>
              <img src={question} alt="" />
              <div>
                <ItemTitle>Answer and ready to go!</ItemTitle>
                <Description>I ask you enough questions to understand your situation — and no more.</Description>
              </div>
            </Item>
            <Item>
              <img src={message} alt="" />
              <div>
                <ItemTitle>The best prices on the spot</ItemTitle>
                <Description>I negotiate with insurance companies on the spot to get you the best prices.</Description>
              </div>
            </Item>
            <Item>
              <img src={shield} alt="" />
              <div>
                <ItemTitle>Trusted and save</ItemTitle>
                <Description>I show you instant quotes from trusted brands. Money (and time) in the bank!</Description>
              </div>
            </Item>
          </WorkItems>
          <div>
            <VideoBlock>
              <video height='580' autoPlay muted loop>
                <source src={work} type='video/mp4' />
                Sorry, your browser doesn't support embedded videos. fdgf
              </video>
              <PlayButton />
            </VideoBlock>
          </div>
        </Row>
      </VideoContainer>

      <DarkContainer>
        <Title>Find your perfect insurance policy</Title>
        <Text>Compare the top insurance brands at once for free</Text>
        <Button onClick={handleClick}>Start my quote</Button>
        <Link href="#">Retrieve My Quote <img src={arrow} alt="" /></Link>
      </DarkContainer>
      </>}
    </div>
  );
}

export default Dashboard;
