/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, createContext, useMemo} from 'react';
import {useNavigate} from "react-router-dom";

import {fullSizeConfig as default_config} from '../FullSizeWidget/config';

import CodeSnippet from './CodeSnippet';
import WidgetConfiguration from './WidgetConfiguration';

import {Container} from './styles';
import { ControlProps, ErrorMessages } from './types';

export const ConfigurationContext: any = createContext(null);

function ConfigurationPage() {
  const navigate = useNavigate();

  //main config
  const [configuration, setConfiguration]: any = useState(default_config);

  // product config
  const [metadata, setMetadata] = useState([{key: "", id: ''}]);
  const [customControls, setCustomControls] = useState<ControlProps[]>([]);

  // steps config
  const [firstStage, setFirstStage]: any = useState(default_config.FirstStage);
  const [confirmationStage, setConfirmationStage]: any = useState(default_config.ConfirmationStage);
  // pages config
  const [ctaStage, setCtaStage]: any = useState({});
  const [ctaStageComponents, setCtaStageComponents] = useState({});
  const [ctaStageColors, setCtaStageColors] = useState({});
  const [resultsStage, setResultsStage]: any = useState({});
  const [resultStageColors, setResultStageColors] = useState({});
  const [resultStageComponents, setResultStageComponents] = useState({});
  const [contactInformation, setContactInformation]: any = useState({});
  const [contactInformationColors, setContactInformationColors] = useState({});
  const [contactInformationComponents, setContactInformationComponents] = useState({});

  const [steps, setSteps] = useState<Object>();
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>({});

  const [userQualified, setUserQualified]: any = useState();
  const [userNotQualified, setUserNotQualified]: any = useState();
  const [noMatchingData, setNoMatchingData]: any = useState();

  // styles config
  const [colors, setColors]: any = useState();
  const [components, setComponents]: any = useState();

  const metadataFormatted = useMemo(() => {
    return metadata.reduce<Record<string, string>>((accum, item) => {
      if(item.key && !accum.hasOwnProperty(item.key) ) {
        accum[item.key] = item.id
      }
      return accum
    }, {})
  }, [metadata]);

  useEffect(() => {
    setConfiguration({...configuration, UserQualified: userQualified});
  }, [userQualified]);
  useEffect(() => {
    setConfiguration({...configuration, errorMessages: errorMessages});
  }, [errorMessages]);
  useEffect(() => {
    setConfiguration({...configuration, UserNotQualified: userNotQualified});
  }, [userNotQualified]);
  useEffect(() => {
    setConfiguration({...configuration, NoMatchingData: noMatchingData});
  }, [noMatchingData]);
  useEffect(() => {
    setConfiguration({...configuration, customControls});
  }, [customControls]);
  useEffect(() => {
    setConfiguration({...configuration, metadata: metadataFormatted});
  }, [metadata]);
  useEffect(() => {
    setConfiguration({...configuration, FirstStage: firstStage});
  }, [firstStage]);
  useEffect(() => {
    setConfiguration({...configuration, ConfirmationStage: confirmationStage});
  }, [confirmationStage]);
  useEffect(() => {
    setConfiguration({...configuration, styles: {...configuration?.styles, colors}});
  }, [colors]);
  useEffect(() => {
    setConfiguration({...configuration, styles: {...configuration?.styles, components}});
  }, [components]);
  useEffect(() => {
    setConfiguration({...configuration, ...steps});
  }, [steps]);
  useEffect(() => {
    setConfiguration({...configuration, CtaStage: {...configuration?.CtaStage, ...ctaStage}});
  }, [ctaStage]);
  useEffect(() => {
    setConfiguration({...configuration, CtaStage: {...configuration?.CtaStage, styles: {...configuration?.CtaStage?.styles, colors: ctaStageColors}}});
  }, [ctaStageColors]);
  useEffect(() => {
    setConfiguration({...configuration, CtaStage: {...configuration?.CtaStage, styles: {...configuration?.CtaStage?.styles, components: ctaStageComponents}}});
  }, [ctaStageComponents]);

  useEffect(() => {
    setConfiguration({...configuration, ResultStage: {...configuration?.ResultStage, ...resultsStage}});
  }, [resultsStage]);
  useEffect(() => {
    setConfiguration({...configuration, ResultStage: {...configuration?.ResultStage, styles: {...configuration?.ResultStage?.styles, colors: resultStageColors}}});
  }, [resultStageColors]);
  useEffect(() => {
    setConfiguration({...configuration, ResultStage: {...configuration?.ResultStage, styles: {...configuration?.ResultStage?.styles, components: resultStageComponents}}});
  }, [resultStageComponents]);
  useEffect(() => {
    setConfiguration({...configuration, ContactInformation: {...configuration?.ContactInformation, ...contactInformation}});
  }, [contactInformation]);
  useEffect(() => {
    setConfiguration({...configuration, ContactInformation: {...configuration?.ContactInformation, styles: {...configuration?.ContactInformation?.styles, colors: contactInformationColors}}});
  }, [contactInformationColors]);
  useEffect(() => {
    setConfiguration({...configuration, ContactInformation: {...configuration?.ContactInformation, styles: {...configuration?.ContactInformation?.styles, components: contactInformationComponents}}});
  }, [contactInformationComponents]);

  const handleSubmit = () => {
    localStorage.setItem('config', JSON.stringify(configuration));
    navigate('/fullSizeWidget');
  }

  const setToDefault = () => {
    localStorage.removeItem('config');
    initializeState(default_config);
  };

  const initializeState = (data: any) => {
    // steps config
    setFirstStage(data.FirstStage);
    setConfirmationStage(data.ConfirmationStage);
    // pages config
    setCtaStage(data.CtaStage);
    setCtaStageColors(data.CtaStage?.styles?.colors);
    setCtaStageComponents(data.CtaStage?.styles?.components);
    setResultsStage(data.ResultStage);
    setResultStageColors(data.ResultStage?.styles?.colors);
    setResultStageComponents(data.ResultStage?.styles?.components);
    setContactInformation(data.ContactInformation);
    setContactInformationColors(data.ContactInformation?.styles?.colors);
    setContactInformationComponents(data.ContactInformation?.styles?.components);
    setUserQualified(data.UserQualified);
    setUserNotQualified(data.UserNotQualified);
    setNoMatchingData(data.NoMatchingData);
		setErrorMessages(data.errorMessages)
    //styles config
    setColors(data.styles?.colors);
    setComponents(data.styles?.components);
    setCustomControls(data.customControls);

    setConfiguration(data);
  };

  const initializeSteps = (data: any, stepCategories: any) => {
    if (stepCategories) {
      const categoriesKeys = Object.keys(stepCategories);
      let pages: any = {};
      categoriesKeys.forEach(key => {
        if (data[key]) {
          pages[key] = data[key];
        }
      });
      return pages;
    }
  };

  useEffect(() => {
    let savedData = localStorage.getItem('config');
    if (savedData) {
      const saved_data = JSON.parse(savedData);
      initializeState(saved_data);
      const FirstStageCategories = saved_data?.FirstStage?.stepCategories;
      const FirstStage_pages = initializeSteps(saved_data, FirstStageCategories);
      const ConfirmationStageCategories = saved_data?.ConfirmationStage?.stepCategories;
      const ConfirmationStage_pages = initializeSteps(saved_data, ConfirmationStageCategories);
      setSteps({...FirstStage_pages, ...ConfirmationStage_pages});
    }
  }, []);

  const configurationValue = useMemo(() => ({
    handleSubmit,
    metadata,
    setMetadata,
    firstStage: {...firstStage},
    setFirstStage,
    ctaStage: {...ctaStage, styles: {components: {...ctaStageComponents}, colors: {...ctaStageColors}}},
    setCtaStage,
    confirmationStage: {...confirmationStage},
    setConfirmationStage,
    resultsStage: {
      ...resultsStage,
      styles: {components: {...resultStageComponents}, colors: {...resultStageColors}}
    },
    setResultsStage,

    contactInformation: {
      ...contactInformation,
      styles: {components: {...contactInformationComponents}, colors: {...contactInformationColors}}
    },
    setContactInformation,
    setContactInformationColors,
    setContactInformationComponents,
    colors,
    setColors,
    components,
    setComponents,
    setToDefault,
    setCtaStageComponents,
    setCtaStageColors,
    setResultStageColors,
    setResultStageComponents,
    userQualified,
    setUserQualified,
    userNotQualified,
    setUserNotQualified,
    noMatchingData,
    setNoMatchingData,
    configuration,
    setConfiguration,
    customControls,
    setCustomControls,
    steps,
    setSteps,
    errorMessages,
    setErrorMessages
  }), [
    metadata,
    firstStage,
    ctaStage,
    ctaStageComponents,
    ctaStageColors,
    confirmationStage,
    resultsStage,
    resultStageComponents,
    resultStageColors,
    contactInformation,
    contactInformationComponents,
    contactInformationColors,
    setContactInformation,
    colors,
    setColors,
    components,
    setComponents,
    userQualified,
    setUserQualified,
    userNotQualified,
    setUserNotQualified,
    noMatchingData,
    setNoMatchingData,
    configuration,
    setConfiguration,
    customControls,
    steps,
    errorMessages,
  ]);

  return (
    <ConfigurationContext.Provider
      value={configurationValue}
    >
      <Container>
        <WidgetConfiguration/>
        <CodeSnippet/>
      </Container>
    </ConfigurationContext.Provider>
  );
}

export default ConfigurationPage;
