import React, { useContext, useState } from 'react';

import { Group, Label, Input, SaveButton } from '../styles';
import { ConfigurationContext } from '../';

function RadioIconsControlConfig() {
  const {
    configuration,
    setConfiguration,
  }: any = useContext(ConfigurationContext);

  const [controlValue, setControlValue] = useState('');
  const [icon, setIcon] = useState('');
  const [activeIcon, setActiveIcon] = useState('');
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');

  const handleAddValue = () => {
    setConfiguration({
      ...configuration,
      RadioIconsControl: {
        ...configuration?.RadioIconsControl,
        valuesProps: {
          ...configuration?.RadioIconsControl?.valuesProps,
          [controlValue]: {
            icon,
            activeIcon,
            width,
            height,
          }
        }
      }
    });
    setControlValue('');
    setIcon('');
    setActiveIcon('');
    setWidth('');
    setHeight('');
  };

  const handleDelete = (value: string) => {
    const valuesProps = {...configuration?.RadioIconsControl?.valuesProps};
    delete valuesProps[value];
    setConfiguration({
      ...configuration,
      RadioIconsControl: {
        valuesProps
      }
    });
  };

  return (
    <>
      <h3>RadioIconsControl Config</h3>
      
      <Group>
        <Label>Value</Label>
        <Input
          id="radioIconsControl_value"
          name="radioIconsControl_value"
          value={controlValue}
          onChange={(e) => setControlValue(e.target.value)} />
      </Group>
      <Group>
        <Label>Icon</Label>
        <Input
          id="radioIconsControl_icon"
          name="radioIconsControl_icon"
          value={icon}
          onChange={(e) => setIcon(e.target.value)} />
      </Group>
      <Group>
        <Label>Active Icon</Label>
        <Input
          id="radioIconsControl_activeIcon"
          name="radioIconsControl_activeIcon"
          value={activeIcon}
          onChange={(e) => setActiveIcon(e.target.value)} />
      </Group>
      <Group>
        <Label>Icon Width</Label>
        <Input
          id="radioIconsControl_iconWidth"
          name="radioIconsControl_iconWidth"
          value={width}
          onChange={(e) => setWidth(e.target.value)} />
      </Group>
      <Group>
        <Label>Icon Height</Label>
        <Input
          id="radioIconsControl_iconHeigth"
          name="radioIconsControl_iconHeigth"
          value={height}
          onChange={(e) => setHeight(e.target.value)} />
      </Group>
      <SaveButton onClick={handleAddValue}>Add Value</SaveButton>

      {configuration?.RadioIconsControl?.valuesProps && Object.keys(configuration?.RadioIconsControl?.valuesProps)?.map((item: string) => {
        return <div key={item}>
            <p>{item}: <button onClick={() => handleDelete(item)}>delete</button></p>
            <p>Icon: {configuration?.RadioIconsControl?.valuesProps[item].icon}</p>
            <p>ActiveIcon: {configuration?.RadioIconsControl?.valuesProps[item].activeIcon}</p>
            <p>Width: {configuration?.RadioIconsControl?.valuesProps[item].width}</p>
            <p>Height: {configuration?.RadioIconsControl?.valuesProps[item].height}</p>
        </div>
      })}
    </>
  );
}

export default RadioIconsControlConfig;
